

import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Order from "../v6/ColiswebApi__V6_Order.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";

var baseUrl = "/hubs";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startOfWeek",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.startOfWeek)
                  ],
                  [
                    "endOfWeek",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.endOfWeek)
                  ]
                ]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startOfWeek = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startOfWeek"), null));
  if (startOfWeek.TAG === "Ok") {
    var endOfWeek = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endOfWeek"), null));
    if (endOfWeek.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startOfWeek: startOfWeek._0,
                endOfWeek: endOfWeek._0
              }
            };
    }
    var e = endOfWeek._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endOfWeek" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startOfWeek._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startOfWeek" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          params_encode(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = params_decode(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function counters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryCount",
                    false,
                    Spice.intToJson(v.deliveryCount)
                  ]]));
}

function counters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryCount"), null));
  if (deliveryCount.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryCount: deliveryCount._0
            }
          };
  }
  var e = deliveryCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryCount" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode(v) {
  return Spice.dictToJson(counters_encode, v);
}

function response_decode(v) {
  return Spice.dictFromJson(counters_decode, v);
}

function exec(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(params_encode(param[1])), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, undefined, baseUrl + "/" + param[0] + "/routes/deliveriesCount");
}

var Config = {
  params_encode: params_encode,
  params_decode: params_decode,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  counters_encode: counters_encode,
  counters_decode: counters_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var GetDeliveriesCount = {
  Config: Config,
  $$Request: $$Request
};

function params_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "day",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.day)
                  ]]));
}

function params_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var day = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "day"), null));
  if (day.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              day: day._0
            }
          };
  }
  var e = day._0;
  return {
          TAG: "Error",
          _0: {
            path: ".day" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function argument_encode$1(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          params_encode$1(v[1])
        ];
}

function argument_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = params_decode$1(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function nullableDimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heightCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function nullableDimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
        if (volumeM3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lengthCm: lengthCm._0,
                    widthCm: widthCm._0,
                    heightCm: heightCm._0,
                    volumeM3: volumeM3._0
                  }
                };
        }
        var e = volumeM3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".volumeM3" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function packetBarcode_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ]]));
}

function packetBarcode_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcode: barcode._0
            }
          };
  }
  var e = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function packaging_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "weightKg",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.weightKg)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "packetsBarcode",
                    false,
                    Spice.arrayToJson(packetBarcode_encode, v.packetsBarcode)
                  ]
                ]));
}

function packaging_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var weightKg = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
  if (weightKg.TAG === "Ok") {
    var packetsCount = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
    if (packetsCount.TAG === "Ok") {
      var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var packetsBarcode = Spice.arrayFromJson(packetBarcode_decode, Belt_Option.getWithDefault(Js_dict.get(v, "packetsBarcode"), null));
        if (packetsBarcode.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    weightKg: weightKg._0,
                    packetsCount: packetsCount._0,
                    description: description._0,
                    packetsBarcode: packetsBarcode._0
                  }
                };
        }
        var e = packetsBarcode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".packetsBarcode" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = packetsCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".packetsCount" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = weightKg._0;
  return {
          TAG: "Error",
          _0: {
            path: ".weightKg" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function heftingOptions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isSidewalk",
                    false,
                    Spice.boolToJson(v.isSidewalk)
                  ],
                  [
                    "isInRoom",
                    false,
                    Spice.boolToJson(v.isInRoom)
                  ],
                  [
                    "isTrailed",
                    false,
                    Spice.boolToJson(v.isTrailed)
                  ]
                ]));
}

function heftingOptions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isSidewalk = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSidewalk"), null));
  if (isSidewalk.TAG === "Ok") {
    var isInRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isInRoom"), null));
    if (isInRoom.TAG === "Ok") {
      var isTrailed = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTrailed"), null));
      if (isTrailed.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  isSidewalk: isSidewalk._0,
                  isInRoom: isInRoom._0,
                  isTrailed: isTrailed._0
                }
              };
      }
      var e = isTrailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isTrailed" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isInRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isInRoom" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isSidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isSidewalk" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function signature_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function signature_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                url: url._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function signatureTransition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "mobileStateUpdatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileStateUpdatedAt)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "isSuccessful",
                    false,
                    Spice.boolToJson(v.isSuccessful)
                  ]
                ]));
}

function signatureTransition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var mobileStateUpdatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileStateUpdatedAt"), null));
    if (mobileStateUpdatedAt.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
        if (comment.TAG === "Ok") {
          var isSuccessful = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSuccessful"), null));
          if (isSuccessful.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      createdAt: createdAt._0,
                      mobileStateUpdatedAt: mobileStateUpdatedAt._0,
                      problem: problem._0,
                      comment: comment._0,
                      isSuccessful: isSuccessful._0
                    }
                  };
          }
          var e = isSuccessful._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isSuccessful" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = comment._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comment" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = mobileStateUpdatedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileStateUpdatedAt" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDefault"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skills_encode(v) {
  return Spice.arrayToJson(skill_encode, v);
}

function skills_decode(v) {
  return Spice.arrayFromJson(skill_decode, v);
}

function timeSlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function timeSlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "ref4",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref4)
                  ],
                  [
                    "routeId",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.routeId)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.shipping)
                  ],
                  [
                    "owner",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_encode, v.owner)
                  ],
                  [
                    "packaging",
                    false,
                    Spice.optionToJson(packaging_encode, v.packaging)
                  ],
                  [
                    "heftingOptions",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_encode, v.heftingOptions)
                  ],
                  [
                    "pickupSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.pickupSignature)
                  ],
                  [
                    "shippingSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.shippingSignature)
                  ],
                  [
                    "returnSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.returnSignature)
                  ],
                  [
                    "lastPickupTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastPickupTransition)
                  ],
                  [
                    "lastShippingTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastShippingTransition)
                  ],
                  [
                    "lastReturnTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastReturnTransition)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode(v.timeSlot)
                  ],
                  [
                    "externalDeliveryCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.externalDeliveryCode)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var ref1 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
      if (ref2.TAG === "Ok") {
        var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
        if (ref3.TAG === "Ok") {
          var ref4 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref4"), null));
          if (ref4.TAG === "Ok") {
            var routeId = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routeId"), null));
            if (routeId.TAG === "Ok") {
              var pickup = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
              if (pickup.TAG === "Ok") {
                var shipping = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                if (shipping.TAG === "Ok") {
                  var owner = Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
                  if (owner.TAG === "Ok") {
                    var packaging = Spice.optionFromJson(packaging_decode, Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                    if (packaging.TAG === "Ok") {
                      var heftingOptions = Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                      if (heftingOptions.TAG === "Ok") {
                        var pickupSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupSignature"), null));
                        if (pickupSignature.TAG === "Ok") {
                          var shippingSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingSignature"), null));
                          if (shippingSignature.TAG === "Ok") {
                            var returnSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "returnSignature"), null));
                            if (returnSignature.TAG === "Ok") {
                              var lastPickupTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastPickupTransition"), null));
                              if (lastPickupTransition.TAG === "Ok") {
                                var lastShippingTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastShippingTransition"), null));
                                if (lastShippingTransition.TAG === "Ok") {
                                  var lastReturnTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastReturnTransition"), null));
                                  if (lastReturnTransition.TAG === "Ok") {
                                    var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                                    if (skills.TAG === "Ok") {
                                      var timeSlot = timeSlot_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                                      if (timeSlot.TAG === "Ok") {
                                        var externalDeliveryCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "externalDeliveryCode"), null));
                                        if (externalDeliveryCode.TAG === "Ok") {
                                          var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                                          if (clientId.TAG === "Ok") {
                                            return {
                                                    TAG: "Ok",
                                                    _0: {
                                                      id: id._0,
                                                      ref1: ref1._0,
                                                      ref2: ref2._0,
                                                      ref3: ref3._0,
                                                      ref4: ref4._0,
                                                      routeId: routeId._0,
                                                      pickup: pickup._0,
                                                      shipping: shipping._0,
                                                      owner: owner._0,
                                                      packaging: packaging._0,
                                                      heftingOptions: heftingOptions._0,
                                                      pickupSignature: pickupSignature._0,
                                                      shippingSignature: shippingSignature._0,
                                                      returnSignature: returnSignature._0,
                                                      lastPickupTransition: lastPickupTransition._0,
                                                      lastShippingTransition: lastShippingTransition._0,
                                                      lastReturnTransition: lastReturnTransition._0,
                                                      skills: skills._0,
                                                      timeSlot: timeSlot._0,
                                                      externalDeliveryCode: externalDeliveryCode._0,
                                                      clientId: clientId._0
                                                    }
                                                  };
                                          }
                                          var e = clientId._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".clientId" + e.path,
                                                    message: e.message,
                                                    value: e.value
                                                  }
                                                };
                                        }
                                        var e$1 = externalDeliveryCode._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".externalDeliveryCode" + e$1.path,
                                                  message: e$1.message,
                                                  value: e$1.value
                                                }
                                              };
                                      }
                                      var e$2 = timeSlot._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".timeSlot" + e$2.path,
                                                message: e$2.message,
                                                value: e$2.value
                                              }
                                            };
                                    }
                                    var e$3 = skills._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".skills" + e$3.path,
                                              message: e$3.message,
                                              value: e$3.value
                                            }
                                          };
                                  }
                                  var e$4 = lastReturnTransition._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".lastReturnTransition" + e$4.path,
                                            message: e$4.message,
                                            value: e$4.value
                                          }
                                        };
                                }
                                var e$5 = lastShippingTransition._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".lastShippingTransition" + e$5.path,
                                          message: e$5.message,
                                          value: e$5.value
                                        }
                                      };
                              }
                              var e$6 = lastPickupTransition._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".lastPickupTransition" + e$6.path,
                                        message: e$6.message,
                                        value: e$6.value
                                      }
                                    };
                            }
                            var e$7 = returnSignature._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".returnSignature" + e$7.path,
                                      message: e$7.message,
                                      value: e$7.value
                                    }
                                  };
                          }
                          var e$8 = shippingSignature._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".shippingSignature" + e$8.path,
                                    message: e$8.message,
                                    value: e$8.value
                                  }
                                };
                        }
                        var e$9 = pickupSignature._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".pickupSignature" + e$9.path,
                                  message: e$9.message,
                                  value: e$9.value
                                }
                              };
                      }
                      var e$10 = heftingOptions._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".heftingOptions" + e$10.path,
                                message: e$10.message,
                                value: e$10.value
                              }
                            };
                    }
                    var e$11 = packaging._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".packaging" + e$11.path,
                              message: e$11.message,
                              value: e$11.value
                            }
                          };
                  }
                  var e$12 = owner._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".owner" + e$12.path,
                            message: e$12.message,
                            value: e$12.value
                          }
                        };
                }
                var e$13 = shipping._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".shipping" + e$13.path,
                          message: e$13.message,
                          value: e$13.value
                        }
                      };
              }
              var e$14 = pickup._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".pickup" + e$14.path,
                        message: e$14.message,
                        value: e$14.value
                      }
                    };
            }
            var e$15 = routeId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".routeId" + e$15.path,
                      message: e$15.message,
                      value: e$15.value
                    }
                  };
          }
          var e$16 = ref4._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref4" + e$16.path,
                    message: e$16.message,
                    value: e$16.value
                  }
                };
        }
        var e$17 = ref3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ref3" + e$17.path,
                  message: e$17.message,
                  value: e$17.value
                }
              };
      }
      var e$18 = ref2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref2" + e$18.path,
                message: e$18.message,
                value: e$18.value
              }
            };
    }
    var e$19 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$19.path,
              message: e$19.message,
              value: e$19.value
            }
          };
  }
  var e$20 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$20.path,
            message: e$20.message,
            value: e$20.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode, v.deliveries)
                  ]]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveries: deliveries._0
            }
          };
  }
  var e = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$1(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(params_encode$1(param[1])), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, undefined, baseUrl + "/" + param[0] + "/routes/deliveries");
}

var Config$1 = {
  params_encode: params_encode$1,
  params_decode: params_decode$1,
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  nullableDimensions_encode: nullableDimensions_encode,
  nullableDimensions_decode: nullableDimensions_decode,
  packaging_encode: packaging_encode,
  packaging_decode: packaging_decode,
  packetBarcode_encode: packetBarcode_encode,
  packetBarcode_decode: packetBarcode_decode,
  heftingOptions_encode: heftingOptions_encode,
  heftingOptions_decode: heftingOptions_decode,
  signature_encode: signature_encode,
  signature_decode: signature_decode,
  signatureTransition_encode: signatureTransition_encode,
  signatureTransition_decode: signatureTransition_decode,
  skills_encode: skills_encode,
  skills_decode: skills_decode,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  timeSlot_encode: timeSlot_encode,
  timeSlot_decode: timeSlot_decode,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var GetDeliveriesInformations = {
  Config: Config$1,
  $$Request: $$Request$1
};

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          Spice.arrayToJson(Identifiers.DeliveryId.t_encode, v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(Identifiers.DeliveryId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function packet_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Spice.optionFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcode: barcode._0
            }
          };
  }
  var e = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function packet_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcode",
                    false,
                    Spice.optionToJson(Identifiers.Barcode.t_encode, v.barcode)
                  ]]));
}

function delivery_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.stringToJson(v.ref1)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "customerName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.customerName)
                  ],
                  [
                    "parcelsStatus",
                    false,
                    Spice.optionToJson(ColiswebApi__V6_Order.Shared.StateV5.t_encode, v.parcelsStatus)
                  ],
                  [
                    "shippingPostalCode",
                    false,
                    Spice.stringToJson(v.shippingPostalCode)
                  ],
                  [
                    "packets",
                    false,
                    Spice.arrayToJson(packet_encode, v.packets)
                  ],
                  [
                    "orderId",
                    false,
                    Spice.optionToJson(Identifiers.OrderId.t_encode, v.orderId)
                  ]
                ]));
}

function delivery_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    var ref1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var customerName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerName"), null));
        if (customerName.TAG === "Ok") {
          var parcelsStatus = Spice.optionFromJson(ColiswebApi__V6_Order.Shared.StateV5.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "parcelsStatus"), null));
          if (parcelsStatus.TAG === "Ok") {
            var shippingPostalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "shippingPostalCode"), null));
            if (shippingPostalCode.TAG === "Ok") {
              var packets = Spice.arrayFromJson(packet_decode, Belt_Option.getWithDefault(Js_dict.get(v, "packets"), null));
              if (packets.TAG === "Ok") {
                var orderId = Spice.optionFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
                if (orderId.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            deliveryId: deliveryId._0,
                            ref1: ref1._0,
                            clientId: clientId._0,
                            customerName: customerName._0,
                            parcelsStatus: parcelsStatus._0,
                            shippingPostalCode: shippingPostalCode._0,
                            packets: packets._0,
                            orderId: orderId._0
                          }
                        };
                }
                var e = orderId._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".orderId" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = packets._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".packets" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = shippingPostalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".shippingPostalCode" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = parcelsStatus._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".parcelsStatus" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = customerName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".customerName" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode$1, v.deliveries)
                  ]]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveries: deliveries._0
            }
          };
  }
  var e = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$2(param) {
  return Axios.WithResult.post(undefined, {
              deliveryIds: Belt_Array.map(param[1], Identifiers.DeliveryId.t_encode)
            }, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$2, undefined, baseUrl + "/" + param[0] + "/deliveries/packets");
}

var Config$2 = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  delivery_encode: delivery_encode$1,
  delivery_decode: delivery_decode$1,
  packet_encode: packet_encode,
  packet_decode: packet_decode,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var GetDeliveriesPackets = {
  Config: Config$2,
  $$Request: $$Request$2
};

function params_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ]]));
}

function params_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              date: date._0
            }
          };
  }
  var e = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function delivery_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "shippingPostalCode",
                    false,
                    Spice.stringToJson(v.shippingPostalCode)
                  ],
                  [
                    "customerName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.customerName)
                  ],
                  [
                    "deliverySlot",
                    false,
                    Toolkit__Decoders.DatetimeTimeSlot.t_encode(v.deliverySlot)
                  ],
                  [
                    "orderId",
                    false,
                    Spice.optionToJson(Identifiers.OrderId.t_encode, v.orderId)
                  ]
                ]));
}

function delivery_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var shippingPostalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "shippingPostalCode"), null));
      if (shippingPostalCode.TAG === "Ok") {
        var customerName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerName"), null));
        if (customerName.TAG === "Ok") {
          var deliverySlot = Toolkit__Decoders.DatetimeTimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliverySlot"), null));
          if (deliverySlot.TAG === "Ok") {
            var orderId = Spice.optionFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
            if (orderId.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        deliveryId: deliveryId._0,
                        ref1: ref1._0,
                        shippingPostalCode: shippingPostalCode._0,
                        customerName: customerName._0,
                        deliverySlot: deliverySlot._0,
                        orderId: orderId._0
                      }
                    };
            }
            var e = orderId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".orderId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = deliverySlot._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".deliverySlot" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = customerName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".customerName" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = shippingPostalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".shippingPostalCode" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode$2, v.deliveries)
                  ]]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveries: deliveries._0
            }
          };
  }
  var e = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$3(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(params_encode$2(param[1])), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$3, undefined, baseUrl + "/" + param[0] + "/spot/deliveries");
}

var Config$3 = {
  params_encode: params_encode$2,
  params_decode: params_decode$2,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  delivery_encode: delivery_encode$2,
  delivery_decode: delivery_decode$2,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var GetSpotDeliveries = {
  Config: Config$3,
  $$Request: $$Request$3
};

export {
  baseUrl ,
  config ,
  GetDeliveriesCount ,
  GetDeliveriesInformations ,
  GetDeliveriesPackets ,
  GetSpotDeliveries ,
}
/* Request Not a pure module */
