

import * as Msw from "msw";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../../src/Identifiers.bs.js";
import * as MockOverlay from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/mock/MockOverlay.bs.js";
import * as Browser from "msw/browser";
import * as Caml_splice_call from "../../../../node_modules/rescript/lib/es6/caml_splice_call.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Select from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Select.bs.js";
import * as ColiswebApi__Fetcher from "../../src/ColiswebApi__Fetcher.bs.js";
import * as ColiswebApi__V5_Hubs from "../../src/v5/ColiswebApi__V5_Hubs.bs.js";
import * as Toolkit__Ui_Checkbox from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Checkbox.bs.js";
import * as ColiswebApi__V5_Store from "../../src/v5/ColiswebApi__V5_Store.bs.js";
import * as ColiswebApi__V5_Client from "../../src/v5/ColiswebApi__V5_Client.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";
import * as ApiComponents__DeliverySlip from "../../src/components/delivery-slip/ApiComponents__DeliverySlip.bs.js";
import * as ColiswebApi__V5_Transporter from "../../src/v5/ColiswebApi__V5_Transporter.bs.js";

var handlers = [
  Msw.http.get("https://api.testing.colisweb.com/api/v5/clients/:clientId/stores/:storeId/deliveries/:deliveryId", (function (param) {
          var params = param.params;
          var response_id = params["deliveryId"];
          var response_ref1 = Identifiers.DeliveryReference.make("ref1");
          var response_ref2 = Identifiers.DeliveryReference.make("ref2");
          var response_ref3 = Identifiers.DeliveryReference.make("ref3");
          var response_ref4 = Identifiers.DeliveryReference.make("ref4");
          var response_salesConsultantName = "salesConsultantName";
          var response_heftingOptions = {
            isSidewalk: false,
            isInRoom: true,
            isTrailed: false
          };
          var response_createdAt = new Date();
          var response_distanceKm = 25;
          var response_skills = [{
              id: "skill",
              tag: "skill-tag",
              description: "skill description",
              isDefault: false
            }];
          var response_timeSlot = {
            start: new Date(),
            end: new Date()
          };
          var response_pickup = {
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            phone1: "phone1",
            phone2: "phone2",
            coords: undefined,
            lift: "with_lift",
            locationType: "store",
            storeId: params["storeId"],
            isDeleted: false,
            hubSlugId: undefined
          };
          var response_shipping = {
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            phone1: "phone1",
            phone2: "phone2",
            coords: undefined,
            lift: "with_lift",
            locationType: "consumer",
            storeId: undefined,
            isDeleted: false,
            hubSlugId: undefined
          };
          var response_priceWithoutTaxEur = 2;
          var response_priceWithTaxEur = 3;
          var response_customerPriceWithoutTaxEur = 5;
          var response_customerPriceWithTaxEur = 5;
          var response_packaging = {
            dimensions: {
              lengthCm: 10,
              widthCm: 10,
              heightCm: 10,
              volumeM3: 10
            },
            weightKg: 10,
            packetsCount: 42,
            description: Belt_Array.makeBy(70, (function (param) {
                      return "packaging description";
                    })).join(", "),
            heaviestWeightKg: 10,
            largestVolumeCm3: 11,
            longestPacketLength: 12,
            biggestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            longestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            volumeCm3: 42,
            packetsBarcode: [{
                barcode: Identifiers.Barcode.make("barcode")
              }]
          };
          var response_pickupSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_shippingSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_returnSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_lastPickupTransition = {
            createdAt: new Date(),
            mobileStateUpdatedAt: Caml_option.some(new Date()),
            problem: "too_heavy",
            comment: "signature comment",
            isSuccessful: true
          };
          var response_deliveryStateTransitions = [];
          var response_owner = {
            store: undefined,
            client: {
              name: "Client owner name"
            },
            storeId: "5"
          };
          var response_customerPricesFactor = 10;
          var response_externalDeliveryCode = "externalDeliveryCode";
          var response_clientId = params["clientId"];
          var response = {
            id: response_id,
            uuid: "uuid",
            route: undefined,
            routePlan: undefined,
            ref1: response_ref1,
            ref2: response_ref2,
            ref3: response_ref3,
            ref4: response_ref4,
            salesConsultantName: response_salesConsultantName,
            heftingOptions: response_heftingOptions,
            statusOriginator: "idle",
            statusProvider: "pickedUp",
            createdAt: response_createdAt,
            distanceKm: response_distanceKm,
            skills: response_skills,
            timeSlot: response_timeSlot,
            pickup: response_pickup,
            shipping: response_shipping,
            customerSatisfaction: undefined,
            priceWithoutTaxEur: response_priceWithoutTaxEur,
            priceWithTaxEur: response_priceWithTaxEur,
            customerPriceWithoutTaxEur: response_customerPriceWithoutTaxEur,
            customerPriceWithTaxEur: response_customerPriceWithTaxEur,
            customerPriceWithoutTaxEurComposition: undefined,
            packaging: response_packaging,
            pickupSignature: response_pickupSignature,
            shippingSignature: response_shippingSignature,
            returnSignature: response_returnSignature,
            lastPickupTransition: response_lastPickupTransition,
            lastShippingTransition: undefined,
            lastReturnTransition: undefined,
            canUpdateSlot: false,
            canUpdatePickupAddress: false,
            canUpdateShippingAddress: false,
            deliveryStateTransitions: response_deliveryStateTransitions,
            isArchived: false,
            owner: response_owner,
            customerPricesFactor: response_customerPricesFactor,
            canReschedule: false,
            canCancelDelivery: false,
            rescheduleDeliveryId: undefined,
            externalDeliveryCode: response_externalDeliveryCode,
            clientId: response_clientId
          };
          return $$Promise.map(Msw.delay(300), (function (param) {
                        return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Store.Delivery.GetDelivery.Config.response_encode, response));
                      }));
        })),
  Msw.http.get("https://api.testing.colisweb.com/api/v5/clients/:clientId/deliveries/:deliveryId", (function (param) {
          var params = param.params;
          var response_id = params["deliveryId"];
          var response_ref1 = Identifiers.DeliveryReference.make("ref1");
          var response_ref2 = Identifiers.DeliveryReference.make("ref2");
          var response_ref3 = Identifiers.DeliveryReference.make("ref3");
          var response_ref4 = Identifiers.DeliveryReference.make("ref4");
          var response_salesConsultantName = "salesConsultantName";
          var response_heftingOptions = {
            isSidewalk: false,
            isInRoom: true,
            isTrailed: false
          };
          var response_createdAt = new Date();
          var response_distanceKm = 25;
          var response_skills = [{
              id: "skill",
              tag: "skill-tag",
              description: "skill description",
              isDefault: false
            }];
          var response_timeSlot = {
            start: new Date(),
            end: new Date()
          };
          var response_pickup = {
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            phone1: "phone1",
            phone2: "phone2",
            coords: undefined,
            lift: "with_lift",
            locationType: "store",
            storeId: params["storeId"],
            isDeleted: false,
            hubSlugId: undefined
          };
          var response_shipping = {
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            phone1: "phone1",
            phone2: "phone2",
            coords: undefined,
            lift: "with_lift",
            locationType: "consumer",
            storeId: undefined,
            isDeleted: false,
            hubSlugId: undefined
          };
          var response_priceWithoutTaxEur = 2;
          var response_priceWithTaxEur = 3;
          var response_customerPriceWithoutTaxEur = 5;
          var response_customerPriceWithTaxEur = 5;
          var response_packaging = {
            dimensions: {
              lengthCm: 10,
              widthCm: 10,
              heightCm: 10,
              volumeM3: 10
            },
            weightKg: 10,
            packetsCount: 42,
            description: "packaging description",
            heaviestWeightKg: 10,
            largestVolumeCm3: 11,
            longestPacketLength: 12,
            biggestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            longestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            volumeCm3: 42,
            packetsBarcode: [{
                barcode: Identifiers.Barcode.make("barcode")
              }]
          };
          var response_pickupSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_shippingSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_returnSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_lastPickupTransition = {
            createdAt: new Date(),
            mobileStateUpdatedAt: Caml_option.some(new Date()),
            problem: "too_heavy",
            comment: "signature comment",
            isSuccessful: true
          };
          var response_deliveryStateTransitions = [];
          var response_owner = {
            store: {
              name: "Store owner name",
              address: {
                line1: "store owner address line 1",
                line2: "store owner address line 2",
                postalCode: "owner CP",
                city: "owner store city",
                country: "owner country"
              }
            },
            client: {
              name: "Client owner name"
            },
            storeId: undefined
          };
          var response_customerPricesFactor = 10;
          var response_externalDeliveryCode = "externalDeliveryCode";
          var response_clientId = params["clientId"];
          var response = {
            id: response_id,
            uuid: "uuid",
            route: undefined,
            routePlan: undefined,
            ref1: response_ref1,
            ref2: response_ref2,
            ref3: response_ref3,
            ref4: response_ref4,
            salesConsultantName: response_salesConsultantName,
            heftingOptions: response_heftingOptions,
            statusOriginator: "idle",
            statusProvider: "pickedUp",
            createdAt: response_createdAt,
            distanceKm: response_distanceKm,
            skills: response_skills,
            timeSlot: response_timeSlot,
            pickup: response_pickup,
            shipping: response_shipping,
            customerSatisfaction: undefined,
            priceWithoutTaxEur: response_priceWithoutTaxEur,
            priceWithTaxEur: response_priceWithTaxEur,
            customerPriceWithoutTaxEur: response_customerPriceWithoutTaxEur,
            customerPriceWithTaxEur: response_customerPriceWithTaxEur,
            customerPriceWithoutTaxEurComposition: undefined,
            packaging: response_packaging,
            pickupSignature: response_pickupSignature,
            shippingSignature: response_shippingSignature,
            returnSignature: response_returnSignature,
            lastPickupTransition: response_lastPickupTransition,
            lastShippingTransition: undefined,
            lastReturnTransition: undefined,
            canUpdateSlot: false,
            canUpdatePickupAddress: false,
            canUpdateShippingAddress: false,
            deliveryStateTransitions: response_deliveryStateTransitions,
            isArchived: false,
            owner: response_owner,
            customerPricesFactor: response_customerPricesFactor,
            canReschedule: false,
            canCancelDelivery: false,
            rescheduleDeliveryId: undefined,
            externalDeliveryCode: response_externalDeliveryCode,
            clientId: response_clientId
          };
          return $$Promise.map(Msw.delay(300), (function (param) {
                        return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Client.Delivery.GetDelivery.Config.response_encode, response));
                      }));
        })),
  Msw.http.get("https://api.testing.colisweb.com/api/v5/hubs/:hubId/routes/deliveries", (function (param) {
          var response = {
            deliveries: [{
                id: "hub-delivery-id",
                ref1: Identifiers.DeliveryReference.make("ref1"),
                ref2: Identifiers.DeliveryReference.make("ref2"),
                ref3: Identifiers.DeliveryReference.make("ref3"),
                ref4: Identifiers.DeliveryReference.make("ref4"),
                routeId: undefined,
                pickup: {
                  name: "name",
                  firstName: "firstName",
                  lastName: "lastName",
                  postalCode: "postalCode",
                  city: "city",
                  country: "country",
                  line1: "line1",
                  line2: "line2",
                  comment: "comment",
                  floor: 2,
                  phone1: "phone1",
                  phone2: "phone2",
                  coords: undefined,
                  lift: "with_lift",
                  locationType: "store",
                  storeId: "storeId",
                  isDeleted: false,
                  hubSlugId: undefined
                },
                shipping: {
                  name: "name",
                  firstName: "firstName",
                  lastName: "lastName",
                  postalCode: "postalCode",
                  city: "city",
                  country: "country",
                  line1: "line1",
                  line2: "line2",
                  comment: "comment",
                  floor: 2,
                  phone1: "phone1",
                  phone2: "phone2",
                  coords: undefined,
                  lift: "with_lift",
                  locationType: "consumer",
                  storeId: undefined,
                  isDeleted: false,
                  hubSlugId: undefined
                },
                owner: {
                  store: {
                    name: "Store owner name",
                    address: {
                      line1: "store owner address line 1",
                      line2: "store owner address line 2",
                      postalCode: "owner CP",
                      city: "owner store city",
                      country: "owner country"
                    }
                  },
                  client: {
                    name: "Client owner name"
                  },
                  storeId: undefined
                },
                packaging: {
                  weightKg: "10",
                  packetsCount: 42,
                  description: "packaging description",
                  packetsBarcode: [{
                      barcode: Identifiers.Barcode.make("barcode")
                    }]
                },
                heftingOptions: {
                  isSidewalk: false,
                  isInRoom: true,
                  isTrailed: false
                },
                pickupSignature: {
                  url: "https://placehold.co/600x400",
                  name: "signature name"
                },
                shippingSignature: {
                  url: "https://placehold.co/600x400",
                  name: "signature name"
                },
                returnSignature: {
                  url: "https://placehold.co/600x400",
                  name: "signature name"
                },
                lastPickupTransition: {
                  createdAt: new Date(),
                  mobileStateUpdatedAt: Caml_option.some(new Date()),
                  problem: "too_heavy",
                  comment: "signature comment",
                  isSuccessful: true
                },
                lastShippingTransition: undefined,
                lastReturnTransition: undefined,
                skills: [{
                    id: "skill",
                    tag: "skill-tag",
                    description: "skill description",
                    isDefault: false
                  }],
                timeSlot: {
                  start: new Date(),
                  end: new Date()
                },
                externalDeliveryCode: "externalDeliveryCode",
                clientId: param.params["hubId"] === "hub-id-ikea" ? "721" : "5"
              }]
          };
          return $$Promise.map(Msw.delay(300), (function (param) {
                        return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Hubs.GetDeliveriesInformations.Config.response_encode, response));
                      }));
        })),
  Msw.http.get("https://api.testing.colisweb.com/api/v5/transporters/:transporterId/deliveries/:deliveryId", (function (param) {
          var response_ref1 = Identifiers.DeliveryReference.make("ref1");
          var response_ref2 = Identifiers.DeliveryReference.make("ref2");
          var response_ref3 = Identifiers.DeliveryReference.make("ref3");
          var response_ref4 = Identifiers.DeliveryReference.make("ref4");
          var response_heftingOptions = {
            isSidewalk: false,
            isInRoom: true,
            isTrailed: false
          };
          var response_createdAt = new Date();
          var response_distanceKm = 10;
          var response_skills = [{
              id: "skill",
              tag: "skill-tag",
              description: "skill description",
              isDefault: false
            }];
          var response_timeSlot = {
            start: new Date(),
            end_: new Date()
          };
          var response_pickup = {
            coords: undefined,
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            lift: "with_lift",
            phone1: "phone1",
            phone2: "phone2",
            locationType: "store",
            storeId: "storeId",
            hubSlugId: undefined
          };
          var response_shipping = {
            coords: undefined,
            name: "name",
            firstName: "firstName",
            lastName: "lastName",
            postalCode: "postalCode",
            city: "city",
            country: "country",
            line1: "line1",
            line2: "line2",
            comment: "comment",
            floor: 2,
            lift: "with_lift",
            phone1: "phone1",
            phone2: "phone2",
            locationType: "consumer",
            storeId: undefined,
            hubSlugId: undefined
          };
          var response_carrier = {
            id: "1",
            carrierNumber: 3
          };
          var response_priceWithoutTaxEur = {
            value: 10,
            composition: []
          };
          var response_packaging = {
            dimensions: {
              lengthCm: 10,
              widthCm: 10,
              heightCm: 10,
              volumeM3: 10
            },
            weightKg: 10,
            packetsCount: 42,
            description: "packaging description",
            heaviestWeightKg: 10,
            largestVolumeCm3: 11,
            longestPacketLength: 12,
            biggestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            longestPacketDimensions: {
              lengthCm: 5,
              widthCm: 10,
              heightCm: 10
            },
            volumeCm3: 42
          };
          var response_pickupSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_shippingSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_returnSignature = {
            url: "https://placehold.co/600x400",
            name: "signature name"
          };
          var response_lastPickupTransition = {
            createdAt: new Date(),
            mobileStateUpdatedAt: Caml_option.some(new Date()),
            problem: "too_heavy",
            comment: "signature comment",
            isSuccessful: true
          };
          var response_deliveryStateTransitions = [];
          var response_availableRefinedSlots = [];
          var response = {
            id: "hub-delivery-id",
            route: undefined,
            routePlan: undefined,
            ref1: response_ref1,
            ref2: response_ref2,
            ref3: response_ref3,
            ref4: response_ref4,
            heftingOptions: response_heftingOptions,
            statusProvider: "course_accepted",
            createdAt: response_createdAt,
            distanceKm: response_distanceKm,
            skills: response_skills,
            timeSlot: response_timeSlot,
            pickup: response_pickup,
            shipping: response_shipping,
            carrier: response_carrier,
            priceWithoutTaxEur: response_priceWithoutTaxEur,
            packaging: response_packaging,
            pickupSignature: response_pickupSignature,
            shippingSignature: response_shippingSignature,
            returnSignature: response_returnSignature,
            lastPickupTransition: response_lastPickupTransition,
            lastShippingTransition: undefined,
            lastReturnTransition: undefined,
            deliveryStateTransitions: response_deliveryStateTransitions,
            isArchived: false,
            clientId: "721",
            storeId: undefined,
            numberOfHandlers: 2,
            lightweight: true,
            availableRefinedSlots: response_availableRefinedSlots
          };
          return $$Promise.map(Msw.delay(300), (function (param) {
                        return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Transporter.GetDelivery.Config.response_encode, response));
                      }));
        }))
];

var worker = Caml_splice_call.spliceApply(Browser.setupWorker, [handlers]);

function Playground_DeliverySlip$Store(props) {
  var exampleIds = props.simulateIkeaClient ? [
      "721",
      "5",
      "77298"
    ] : [
      "5",
      "5",
      "77298"
    ];
  var match = Curry._2(ColiswebApi__Fetcher.V5.Store.StoreDeliveryFetcher.use, undefined, exampleIds);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__DeliverySlip.Store.make, {
                    delivery: {
                      TAG: "Store",
                      _0: match[0]
                    }
                  }),
              className: "bg-white"
            });
}

var Store = {
  make: Playground_DeliverySlip$Store
};

function Playground_DeliverySlip$Client(props) {
  var exampleIds = props.simulateIkeaClient ? [
      "721",
      "77298"
    ] : [
      "5",
      "77298"
    ];
  var match = Curry._2(ColiswebApi__Fetcher.V5.Client.ClientDeliveryFetcher.use, undefined, exampleIds);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__DeliverySlip.Store.make, {
                    delivery: {
                      TAG: "Client",
                      _0: match[0]
                    }
                  }),
              className: "bg-white"
            });
}

var Client = {
  make: Playground_DeliverySlip$Client
};

function Playground_DeliverySlip$Hub(props) {
  var data = props.simulateIkeaClient ? [
      "hub-id-ikea",
      {
        day: new Date()
      }
    ] : [
      "hub-id",
      {
        day: new Date()
      }
    ];
  var match = Curry._2(ColiswebApi__Fetcher.V5.Hubs.GetDeliveriesInformations.use, undefined, data);
  return JsxRuntime.jsx("div", {
              children: Belt_Array.map(match[0].deliveries, (function (delivery) {
                      return JsxRuntime.jsx(ApiComponents__DeliverySlip.Store.make, {
                                  delivery: {
                                    TAG: "Hub",
                                    _0: delivery
                                  }
                                }, delivery.id);
                    })),
              className: "bg-white"
            });
}

var Hub = {
  make: Playground_DeliverySlip$Hub
};

function Playground_DeliverySlip$Transporter(props) {
  var match = Curry._2(ColiswebApi__Fetcher.V5.Transporter.TransporterDeliveryFetcher.use, undefined, [
        "1",
        "1"
      ]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__DeliverySlip.Transporter.make, {
                    transporterId: "1",
                    delivery: match[0]
                  }),
              className: "bg-white"
            });
}

var Transporter = {
  make: Playground_DeliverySlip$Transporter
};

function Playground_DeliverySlip(props) {
  var match = React.useState(function (param) {
        return "Store";
      });
  var setState = match[1];
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setSimulateIkea = match$1[1];
  var simulateIkeaClient = match$1[0];
  var tmp;
  switch (match[0]) {
    case "Store" :
        tmp = JsxRuntime.jsx(Playground_DeliverySlip$Store, {
              simulateIkeaClient: simulateIkeaClient
            });
        break;
    case "Hub" :
        tmp = JsxRuntime.jsx(Playground_DeliverySlip$Hub, {
              simulateIkeaClient: simulateIkeaClient
            });
        break;
    case "Client" :
        tmp = JsxRuntime.jsx(Playground_DeliverySlip$Client, {
              simulateIkeaClient: simulateIkeaClient
            });
        break;
    case "Transporter" :
        tmp = JsxRuntime.jsx(Playground_DeliverySlip$Transporter, {});
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(MockOverlay.make, {
                    worker: worker,
                    workerOptions: import.meta.env.PROD ? ({
                          serviceWorker: {
                            url: "/mockServiceWorker.js"
                          }
                        }) : ({}),
                    className: "!absolute -top-20",
                    children: [
                      JsxRuntime.jsxs("header", {
                            children: [
                              JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                                    options: [
                                      {
                                        label: "Magasin",
                                        value: "store"
                                      },
                                      {
                                        label: "Hub",
                                        value: "hub"
                                      },
                                      {
                                        label: "Transporteur",
                                        value: "transporter"
                                      },
                                      {
                                        label: "Client",
                                        value: "client"
                                      }
                                    ],
                                    onChange: (function (str) {
                                        Curry._1(setState, (function (param) {
                                                switch (str) {
                                                  case "client" :
                                                      return "Client";
                                                  case "hub" :
                                                      return "Hub";
                                                  case "store" :
                                                      return "Store";
                                                  case "transporter" :
                                                      return "Transporter";
                                                  default:
                                                    return "Store";
                                                }
                                              }));
                                      })
                                  }),
                              JsxRuntime.jsx(Toolkit__Ui_Checkbox.make, {
                                    value: "",
                                    children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_b64c0530",
                                              defaultMessage: "Simuler le client Ikea"
                                            })),
                                    onChange: (function (checked, param) {
                                        Curry._1(setSimulateIkea, (function (param) {
                                                return checked;
                                              }));
                                      })
                                  })
                            ],
                            className: "bg-gray-200 border-b p-2 rounded mb-2 print:hidden flex flex-row gap-4 items-center"
                          }),
                      JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                            fallbackRender: (function (param) {
                                return JsxRuntime.jsx("p", {
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_46b387f1",
                                                  defaultMessage: "Veuillez activer le mock et recharger la page"
                                                }),
                                            className: "text-lg font-semibold text-danger-600"
                                          });
                              }),
                            children: JsxRuntime.jsx(React.Suspense, {
                                  children: Caml_option.some(tmp)
                                })
                          })
                    ]
                  }),
              className: "relative"
            });
}

var make = Playground_DeliverySlip;

export {
  handlers ,
  worker ,
  Store ,
  Client ,
  Hub ,
  Transporter ,
  make ,
}
/* handlers Not a pure module */
