

import * as Cx from "../../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as JsxRuntime from "react/jsx-runtime";
import Security_codeSvgreact from "@apiRoot/src/components/delivery-options/assets/security_code.svg?react";

var SecurityCode = {};

function ApiComponents__DeliveryOptionTag(props) {
  var option = props.option;
  var tmp;
  switch (option) {
    case "InRoom" :
        tmp = JsxRuntime.jsx(Md.MdHome, {
              size: 16
            });
        break;
    case "Trailed" :
        tmp = JsxRuntime.jsx(Fa.FaWarehouse, {
              size: 16
            });
        break;
    case "Sidewalk" :
        tmp = JsxRuntime.jsx(Fa.FaTruck, {
              size: 16
            });
        break;
    case "SecurityCode" :
        tmp = JsxRuntime.jsx(Security_codeSvgreact, {
              className: "w-4"
            });
        break;
    
  }
  var tmp$1;
  tmp$1 = option === "SecurityCode" ? "bg-primary-700 border-primary-700" : "bg-neutral-600 border-neutral-600";
  var tmp$2;
  switch (option) {
    case "InRoom" :
        tmp$2 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
              id: "_df7fbeb2",
              defaultMessage: "Pièce du choix"
            });
        break;
    case "Trailed" :
        tmp$2 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
              id: "_ca6c7faf",
              defaultMessage: "Tractée"
            });
        break;
    case "Sidewalk" :
        tmp$2 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
              id: "_27614b8b",
              defaultMessage: "Pied de camion"
            });
        break;
    case "SecurityCode" :
        tmp$2 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
              id: "_3b1a85d2",
              defaultMessage: "Livraison avec code de validation"
            });
        break;
    
  }
  var tmp$3;
  tmp$3 = option === "SecurityCode" ? "text-primary-700 border-primary-700" : "text-neutral-600 border-neutral-600";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("span", {
                      children: tmp,
                      className: Cx.cx([
                            "border  p-1 rounded-l text-white",
                            tmp$1
                          ])
                    }),
                JsxRuntime.jsx("p", {
                      children: tmp$2,
                      className: Cx.cx([
                            "border border-neutral-600 px-2 align-middle uppercase text-sm leading-6 font-semibold rounded-r ",
                            tmp$3
                          ])
                    })
              ],
              className: Cx.cx(["inline-flex flex-row items-stretch rounded "])
            });
}

var make = ApiComponents__DeliveryOptionTag;

export {
  SecurityCode ,
  make ,
}
/* react-intl Not a pure module */
