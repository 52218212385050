

import * as Cx from "../../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import Jsbarcode from "jsbarcode";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../../Identifiers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Primitives from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives.bs.js";
import * as ColiswebApi__Fetcher from "../../ColiswebApi__Fetcher.bs.js";
import * as ColiswebApi__Locales from "../../ColiswebApi__Locales.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";

var ikeaIds = [
  "104",
  "721"
];

function join(separatorOpt, parts) {
  var separator = separatorOpt !== undefined ? separatorOpt : ", ";
  return Belt_Array.map(Belt_Array.keep(parts, (function (str) {
                      return str !== "";
                    })), (function (a) {
                  return a.trim();
                })).join(separator);
}

function formatTimeSlot(start, end) {
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_5ee9378f",
                        defaultMessage: "{date} entre {start} et {end}",
                        values: {
                          date: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                value: start,
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              }),
                          start: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                value: start,
                                hour: "2-digit",
                                minute: "2-digit"
                              }),
                          end: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                value: end,
                                hour: "2-digit",
                                minute: "2-digit"
                              })
                        }
                      }))
            });
}

function convertDelivery(delivery) {
  switch (delivery.TAG) {
    case "Store" :
        var storeDelivery = delivery._0;
        return {
                id: storeDelivery.id,
                ref1: storeDelivery.ref1,
                ref2: storeDelivery.ref2,
                ref3: storeDelivery.ref3,
                ref4: storeDelivery.ref4,
                routeId: storeDelivery.route,
                pickup: storeDelivery.pickup,
                shipping: storeDelivery.shipping,
                owner: storeDelivery.owner,
                packaging: Belt_Option.map(storeDelivery.packaging, (function (packaging) {
                        return {
                                weightKg: Belt_Option.map(packaging.weightKg, (function (prim) {
                                        return prim.toString();
                                      })),
                                packetsCount: packaging.packetsCount,
                                description: packaging.description,
                                packetsBarcode: packaging.packetsBarcode
                              };
                      })),
                heftingOptions: storeDelivery.heftingOptions,
                pickupSignature: storeDelivery.pickupSignature,
                shippingSignature: storeDelivery.shippingSignature,
                returnSignature: storeDelivery.returnSignature,
                lastPickupTransition: storeDelivery.lastPickupTransition,
                lastShippingTransition: storeDelivery.lastShippingTransition,
                lastReturnTransition: storeDelivery.lastReturnTransition,
                skills: storeDelivery.skills,
                timeSlot: storeDelivery.timeSlot,
                externalDeliveryCode: storeDelivery.externalDeliveryCode,
                clientId: storeDelivery.clientId
              };
    case "Client" :
        var clientDelivery = delivery._0;
        return {
                id: clientDelivery.id,
                ref1: clientDelivery.ref1,
                ref2: clientDelivery.ref2,
                ref3: clientDelivery.ref3,
                ref4: clientDelivery.ref4,
                routeId: clientDelivery.route,
                pickup: clientDelivery.pickup,
                shipping: clientDelivery.shipping,
                owner: clientDelivery.owner,
                packaging: Belt_Option.map(clientDelivery.packaging, (function (packaging) {
                        return {
                                weightKg: Belt_Option.map(packaging.weightKg, (function (prim) {
                                        return prim.toString();
                                      })),
                                packetsCount: packaging.packetsCount,
                                description: packaging.description,
                                packetsBarcode: packaging.packetsBarcode
                              };
                      })),
                heftingOptions: clientDelivery.heftingOptions,
                pickupSignature: clientDelivery.pickupSignature,
                shippingSignature: clientDelivery.shippingSignature,
                returnSignature: clientDelivery.returnSignature,
                lastPickupTransition: clientDelivery.lastPickupTransition,
                lastShippingTransition: clientDelivery.lastShippingTransition,
                lastReturnTransition: clientDelivery.lastReturnTransition,
                skills: clientDelivery.skills,
                timeSlot: clientDelivery.timeSlot,
                externalDeliveryCode: clientDelivery.externalDeliveryCode,
                clientId: clientDelivery.clientId
              };
    case "StoreDeliveryList" :
        var storeDelivery$1 = delivery._0;
        return {
                id: storeDelivery$1.id,
                ref1: storeDelivery$1.ref1,
                ref2: storeDelivery$1.ref2,
                ref3: storeDelivery$1.ref3,
                ref4: storeDelivery$1.ref4,
                routeId: undefined,
                pickup: storeDelivery$1.pickup,
                shipping: storeDelivery$1.shipping,
                owner: storeDelivery$1.owner,
                packaging: Belt_Option.map(storeDelivery$1.packaging, (function (param) {
                        return {
                                weightKg: Belt_Option.map(param.weightKg, (function (prim) {
                                        return prim.toString();
                                      })),
                                packetsCount: param.packetsCount,
                                description: param.description,
                                packetsBarcode: param.packetsBarcode
                              };
                      })),
                heftingOptions: storeDelivery$1.heftingOptions,
                pickupSignature: storeDelivery$1.pickupSignature,
                shippingSignature: storeDelivery$1.shippingSignature,
                returnSignature: storeDelivery$1.returnSignature,
                lastPickupTransition: storeDelivery$1.lastPickupTransition,
                lastShippingTransition: storeDelivery$1.lastShippingTransition,
                lastReturnTransition: storeDelivery$1.lastReturnTransition,
                skills: Belt_Array.map(storeDelivery$1.skills, (function (skill) {
                        return {
                                id: skill,
                                tag: skill,
                                description: skill,
                                isDefault: false
                              };
                      })),
                timeSlot: storeDelivery$1.timeSlot,
                externalDeliveryCode: storeDelivery$1.externalDeliveryCode,
                clientId: storeDelivery$1.clientId
              };
    case "Hub" :
        return delivery._0;
    
  }
}

function ApiComponents__DeliverySlip$BarcodeDisplay(props) {
  var data = props.data;
  React.useEffect((function (param) {
          Jsbarcode("#barcode-" + data, data, {
                format: "CODE128",
                height: 70,
                fontSize: 0
              });
        }), [data]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("canvas", {
                    className: "bg-gray-300 h-full",
                    id: "barcode-" + data
                  }),
              className: "h-[70px] relative top-2"
            });
}

function ApiComponents__DeliverySlip$Container(props) {
  var __className = props.className;
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: children,
              className: Cx.cx([
                    "text-sm print:min-h-screen",
                    className
                  ]),
              style: {
                fontFamily: "Arial, sans-serif",
                pageBreakAfter: "always"
              }
            });
}

function ApiComponents__DeliverySlip$Row(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: "flex flex-wrap flex-row",
              style: props.style
            });
}

function ApiComponents__DeliverySlip$Col(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: "max-w-full w-full relative basis-0 grow mx-2 mb-3"
            });
}

function ApiComponents__DeliverySlip$Table(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: "table border-collapse w-full border-2 border-gray-900"
            });
}

function ApiComponents__DeliverySlip$TableRow(props) {
  var __children = props.children;
  var __height = props.height;
  var height = __height !== undefined ? __height : "auto";
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: "table-row",
              style: {
                height: height
              }
            });
}

function ApiComponents__DeliverySlip$TableCell(props) {
  var __children = props.children;
  var __className = props.className;
  var __width = props.width;
  var width = __width !== undefined ? __width : "auto";
  var className = __className !== undefined ? __className : "";
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: Cx.cx([
                    "table-cell border border-gray-900 box-border py-0.5 px-2",
                    className
                  ]),
              style: {
                width: width
              }
            });
}

function ApiComponents__DeliverySlip$Label(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("span", {
              children: children,
              className: "text-xs leading-5 font-bold italic"
            });
}

function ApiComponents__DeliverySlip$Value(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("span", {
              children: children,
              className: "text-xs leading-5 italic"
            });
}

function ApiComponents__DeliverySlip$Heading(props) {
  var __children = props.children;
  var __textAlign = props.textAlign;
  var textAlign = __textAlign !== undefined ? __textAlign : "left";
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx("div", {
              children: children,
              className: "text-sm leading-4 font-bold uppercase",
              style: {
                textAlign: textAlign
              }
            });
}

function ApiComponents__DeliverySlip$StoreOwner(props) {
  var match = Curry._2(ColiswebApi__Fetcher.V5.Store.StoreFetcher.use, undefined, [
        props.clientId,
        props.storeId
      ]);
  var storeOwner = match[0];
  var name = storeOwner.name;
  var address = storeOwner.address;
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_062e1d2b",
                                                    defaultMessage: "Donneur d'ordre"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                children: [
                                  name,
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_f48a4e5b",
                                                  defaultMessage: "Adresse: "
                                                }))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(address.line1)
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  Belt_Option.mapWithDefault(Belt_Option.flatMap(address.line2, Toolkit__Primitives.$$Option.fromString), null, (function (line2) {
                                          return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                      children: [
                                                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                                              children: Caml_option.some(line2)
                                                            }),
                                                        JsxRuntime.jsx("br", {})
                                                      ]
                                                    });
                                        })),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(join(undefined, [
                                                  address.postalCode,
                                                  address.city,
                                                  address.country
                                                ]))
                                      })
                                ]
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$References(props) {
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableRow, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                              width: "160",
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_22484079",
                                                  defaultMessage: "Id de livraison"
                                                }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(props.id)
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableRow, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                              width: "160",
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_747aa9ac",
                                                  defaultMessage: "Date de livraison"
                                                }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(formatTimeSlot(props.timeSlotStart, props.timeSlotEnd))
                                      }))
                            })
                      ]
                    }),
                Belt_Array.mapWithIndex(props.references, (function (i, reference) {
                        return JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableRow, {
                                    children: [
                                      JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                            width: "160",
                                            children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                                      children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                id: "_8010bf7f",
                                                                defaultMessage: "Ref {count}",
                                                                values: {
                                                                  count: i + 1 | 0
                                                                }
                                                              }))
                                                    }))
                                          }),
                                      JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                            children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                                      children: Caml_option.some(Belt_Option.mapWithDefault(reference, "", Identifiers.DeliveryReference.display))
                                                    }))
                                          })
                                    ]
                                  }, Belt_Option.mapWithDefault(reference, "ref" + String(i), Identifiers.DeliveryReference.toString));
                      }))
              ]
            });
}

function ApiComponents__DeliverySlip$Location(props) {
  var name2 = props.name2;
  var name1 = props.name1;
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(props.variant === "destination" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_6bad2e84",
                                                      defaultMessage: "Point de livraison"
                                                    }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_9bbd8ab1",
                                                      defaultMessage: "Point de retrait"
                                                    }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                children: [
                                  name1 !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            name1,
                                            JsxRuntime.jsx("br", {})
                                          ]
                                        }) : null,
                                  name2 !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            name2,
                                            JsxRuntime.jsx("br", {})
                                          ]
                                        }) : null,
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_f48a4e5b",
                                                  defaultMessage: "Adresse: "
                                                }))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(props.line1)
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  Belt_Option.mapWithDefault(props.line2, null, (function (line2) {
                                          if (line2 === "") {
                                            return null;
                                          } else {
                                            return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                        children: [
                                                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                                                children: Caml_option.some(line2)
                                                              }),
                                                          JsxRuntime.jsx("br", {})
                                                        ]
                                                      });
                                          }
                                        })),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(join(undefined, [
                                                  props.postalCode,
                                                  props.city,
                                                  Belt_Option.getWithDefault(props.country, "")
                                                ]))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_c9863cb4",
                                                  defaultMessage: "Téléphone: "
                                                }))
                                      }),
                                  JsxRuntime.jsxs(ApiComponents__DeliverySlip$Value, {
                                        children: [
                                          Belt_Option.mapWithDefault(props.phone1, null, (function (phone) {
                                                  return phone;
                                                })),
                                          Belt_Option.mapWithDefault(props.phone2, null, (function (phone) {
                                                  if (phone === "") {
                                                    return null;
                                                  } else {
                                                    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                                                                children: Caml_option.some(" / " + phone)
                                                              });
                                                  }
                                                }))
                                        ]
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  Belt_Option.mapWithDefault(props.comment, null, (function (comment) {
                                          if (comment === "") {
                                            return null;
                                          } else {
                                            return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                        children: [
                                                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                                                children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                          id: "_827bca5e",
                                                                          defaultMessage: "Informations complémentaires: "
                                                                        }))
                                                              }),
                                                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                                                children: Caml_option.some(comment)
                                                              })
                                                        ]
                                                      });
                                          }
                                        }))
                                ]
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Status(props) {
  var variant = props.variant;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("span", {
                      children: Belt_Option.mapWithDefault(props.transition, null, (function (transition) {
                              return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_1dd3809f",
                                          defaultMessage: "{location}: {status} le {date} à {time}",
                                          values: {
                                            location: variant === "return" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_7727eb74",
                                                    defaultMessage: "Retour"
                                                  }) : (
                                                variant === "destination" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                        id: "_12007e1d",
                                                        defaultMessage: "Destination"
                                                      }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                        id: "_b36eb691",
                                                        defaultMessage: "Expéditeur"
                                                      })
                                              ),
                                            status: transition.isSuccessful ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_f3ca514e",
                                                    defaultMessage: "Succès"
                                                  }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_f9ae6e72",
                                                    defaultMessage: "Échec"
                                                  }),
                                            date: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                  value: transition.createdAt,
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                }),
                                            time: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                  value: transition.createdAt,
                                                  hour: "2-digit",
                                                  minute: "2-digit"
                                                })
                                          }
                                        });
                            })),
                      className: "text-[13px] leading-5 font-bold"
                    }),
                JsxRuntime.jsx("br", {}),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                              height: "20",
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                        children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                                  children: Caml_option.some(variant === "return" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                              id: "_b9def984",
                                                              defaultMessage: "Signature point de retour"
                                                            }) : (
                                                          variant === "destination" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "_cb4dcf35",
                                                                  defaultMessage: "Signature destinataire"
                                                                }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "_5d321b7f",
                                                                  defaultMessage: "Signature expéditeur"
                                                                })
                                                        ))
                                                }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                        className: "h-[110px]",
                                        children: Caml_option.some(Belt_Option.mapWithDefault(props.signature, null, (function (param) {
                                                    return JsxRuntime.jsxs(React.Fragment, {
                                                                children: [
                                                                  param.name,
                                                                  JsxRuntime.jsx("img", {
                                                                        className: "max-w-full h-[80px]",
                                                                        src: param.url
                                                                      })
                                                                ]
                                                              });
                                                  })))
                                      }))
                            })
                      ]
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Problem(props) {
  var variant = props.variant;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(variant === "return" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_9ffe9e80",
                                                      defaultMessage: "Problème(s) retour"
                                                    }) : (
                                                  variant === "destination" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                          id: "_22032611",
                                                          defaultMessage: "Problème(s) destination"
                                                        }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                          id: "_d2b9ea95",
                                                          defaultMessage: "Problème(s) expéditeur"
                                                        })
                                                ))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                className: "h-[80px]",
                                children: Caml_option.some(Belt_Option.mapWithDefault(props.problem, null, (function (problem) {
                                            return Curry._2(ColiswebApi__Locales.V5.Store.TransitionProblem.humanize, intl, problem);
                                          })))
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Packaging(props) {
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_3f3d10f9",
                                                    defaultMessage: "Colis"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                children: [
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_377e22eb",
                                                  defaultMessage: "Description des produits: "
                                                }))
                                      }),
                                  JsxRuntime.jsx("pre", {
                                        children: Belt_Option.getWithDefault(props.description, ""),
                                        className: "text-xs leading-5 italic whitespace-pre-wrap"
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_5980e483",
                                                  defaultMessage: "Nombre de colis: "
                                                }))
                                      }),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(Belt_Option.mapWithDefault(props.packetsCount, null, (function (quantity) {
                                                    return quantity;
                                                  })))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_38b683e3",
                                                  defaultMessage: "Poids total: "
                                                }))
                                      }),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(Belt_Option.mapWithDefault(props.weightKg, null, (function (weight) {
                                                    return weight.toString() + " kg";
                                                  })))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  Belt_Option.mapWithDefault(props.barcodesToShow, null, (function (barcodesToShow) {
                                          return JsxRuntime.jsx("div", {
                                                      children: Belt_Array.map(barcodesToShow, (function (barcode) {
                                                              return JsxRuntime.jsx(ApiComponents__DeliverySlip$BarcodeDisplay, {
                                                                          data: Identifiers.Barcode.toString(barcode)
                                                                        });
                                                            })),
                                                      className: "flex flex-col items-start gap-2 mb-2"
                                                    });
                                        }))
                                ]
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Options(props) {
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_e5dd6f1f",
                                                    defaultMessage: "Option(s) de livraison"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                          children: Caml_option.some(join("; ", props.options))
                                        }))
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$ClientOwner(props) {
  var match = Curry._2(ColiswebApi__Fetcher.V5.Client.ClientFetcher.use, undefined, props.clientId);
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_062e1d2b",
                                                    defaultMessage: "Donneur d'ordre"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(match[0].name)
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Question(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: props.label,
                      className: "font-semibold"
                    }),
                JsxRuntime.jsx("div", {
                      className: "border border-black w-4 h-4"
                    }),
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_24ace383",
                            defaultMessage: "Oui"
                          }),
                      className: "font-semibold"
                    }),
                JsxRuntime.jsx("div", {
                      className: "border border-black w-4 h-4"
                    }),
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_6679e65c",
                            defaultMessage: "Non"
                          }),
                      className: "font-semibold"
                    })
              ],
              className: "flex flex-row items-center gap-2 text-xs"
            });
}

function ApiComponents__DeliverySlip$DeliveryOwner(props) {
  var owner = props.owner;
  var match = owner.store;
  var clientId = props.clientId;
  if (match !== undefined) {
    var match$1 = match.address;
    return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
                children: [
                  JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                        height: "20",
                        children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                  children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_062e1d2b",
                                                      defaultMessage: "Donneur d'ordre"
                                                    }))
                                          }))
                                }))
                      }),
                  JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                        children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                  children: [
                                    match.name,
                                    JsxRuntime.jsx("br", {}),
                                    JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_f48a4e5b",
                                                    defaultMessage: "Adresse: "
                                                  }))
                                        }),
                                    JsxRuntime.jsx("br", {}),
                                    JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                          children: Caml_option.some(match$1.line1)
                                        }),
                                    JsxRuntime.jsx("br", {}),
                                    Belt_Option.mapWithDefault(Belt_Option.flatMap(match$1.line2, Toolkit__Primitives.$$Option.fromString), null, (function (line2) {
                                            return JsxRuntime.jsxs(React.Fragment, {
                                                        children: [
                                                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                                                children: Caml_option.some(line2)
                                                              }),
                                                          JsxRuntime.jsx("br", {})
                                                        ]
                                                      });
                                          })),
                                    JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                          children: Caml_option.some(join(undefined, [
                                                    match$1.postalCode,
                                                    match$1.city,
                                                    match$1.country
                                                  ]))
                                        })
                                  ]
                                }))
                      })
                ]
              });
  }
  var match$2 = owner.client;
  var storeId = owner.storeId;
  if (storeId !== undefined) {
    return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                fallbackRender: (function (param) {
                    return JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_32105d93",
                                      defaultMessage: "Impossible de récupérer les informations magasin."
                                    }),
                                className: "text-danger-500"
                              });
                  }),
                children: JsxRuntime.jsx(ApiComponents__DeliverySlip$StoreOwner, {
                      clientId: clientId,
                      storeId: storeId
                    })
              });
  } else if (match$2 !== undefined) {
    return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
                children: [
                  JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                        height: "20",
                        children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                  children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_062e1d2b",
                                                      defaultMessage: "Donneur d'ordre"
                                                    }))
                                          }))
                                }))
                      }),
                  JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                        children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                  children: Caml_option.some(match$2.name)
                                }))
                      })
                ]
              });
  } else {
    return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                fallbackRender: (function (param) {
                    return JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_7086e5f8",
                                      defaultMessage: "Impossible de récupérer les informations client."
                                    }),
                                className: "text-danger-500"
                              });
                  }),
                children: JsxRuntime.jsx(ApiComponents__DeliverySlip$ClientOwner, {
                      clientId: clientId
                    })
              });
  }
}

function ApiComponents__DeliverySlip$Store(props) {
  var intl = ReactIntl.useIntl();
  var delivery = convertDelivery(props.delivery);
  var match = delivery.heftingOptions;
  var options = Belt_Array.concat(match.isInRoom ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.inRoom)] : (
          match.isSidewalk ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.sidewalk)] : (
              match.isTrailed ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.trailed)] : []
            )
        ), Belt_Array.map(delivery.skills, (function (skill) {
              return skill.description;
            })));
  var isIkeaClient = Belt_Array.some(ikeaIds, (function (id) {
          return id === delivery.clientId;
        }));
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Container, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          textAlign: "center",
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_8c8381fa",
                                                    defaultMessage: "Bordereau de livraison Magasin Colisweb"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$DeliveryOwner, {
                                        owner: delivery.owner,
                                        clientId: delivery.clientId
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$References, {
                                        id: delivery.id,
                                        timeSlotStart: delivery.timeSlot.start,
                                        timeSlotEnd: delivery.timeSlot.end,
                                        references: [
                                          delivery.ref1,
                                          delivery.ref2,
                                          delivery.ref3,
                                          delivery.ref4
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(Belt_Option.mapWithDefault(delivery.pickup, null, (function (pickup) {
                                          return JsxRuntime.jsx(ApiComponents__DeliverySlip$Location, {
                                                      variant: "origin",
                                                      name1: pickup.name,
                                                      name2: Toolkit__Primitives.$$Option.fromString(join(" ", [
                                                                Belt_Option.getWithDefault(pickup.firstName, ""),
                                                                Belt_Option.getWithDefault(pickup.lastName, "")
                                                              ])),
                                                      line1: pickup.line1,
                                                      line2: pickup.line2,
                                                      postalCode: pickup.postalCode,
                                                      city: pickup.city,
                                                      country: pickup.country,
                                                      phone1: pickup.phone1,
                                                      phone2: pickup.phone2,
                                                      comment: pickup.comment
                                                    });
                                        })))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(Belt_Option.mapWithDefault(delivery.shipping, null, (function (shipping) {
                                          return JsxRuntime.jsx(ApiComponents__DeliverySlip$Location, {
                                                      variant: "destination",
                                                      name1: shipping.name,
                                                      name2: Toolkit__Primitives.$$Option.fromString(join(" ", [
                                                                Belt_Option.getWithDefault(shipping.firstName, ""),
                                                                Belt_Option.getWithDefault(shipping.lastName, "")
                                                              ])),
                                                      line1: shipping.line1,
                                                      line2: shipping.line2,
                                                      postalCode: shipping.postalCode,
                                                      city: shipping.city,
                                                      country: shipping.country,
                                                      phone1: shipping.phone1,
                                                      phone2: shipping.phone2,
                                                      comment: shipping.comment
                                                    });
                                        })))
                            })
                      ]
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(options.length !== 0 ? JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                  children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Options, {
                                            options: options
                                          }))
                                }) : null)
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                children: Caml_option.some(Belt_Option.mapWithDefault(delivery.packaging, null, (function (packaging) {
                                            return JsxRuntime.jsx(ApiComponents__DeliverySlip$Packaging, {
                                                        description: packaging.description,
                                                        packetsCount: packaging.packetsCount,
                                                        weightKg: Belt_Option.map(packaging.weightKg, (function (prim) {
                                                                return Number(prim);
                                                              })),
                                                        barcodesToShow: isIkeaClient && delivery.externalDeliveryCode === "RET" ? Belt_Array.map(packaging.packetsBarcode, (function (param) {
                                                                  return param.barcode;
                                                                })) : undefined
                                                      });
                                          })))
                              }))
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "origin",
                                        problem: Belt_Option.flatMap(delivery.lastPickupTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "destination",
                                        problem: Belt_Option.flatMap(delivery.lastShippingTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "return",
                                        problem: Belt_Option.flatMap(delivery.lastReturnTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            })
                      ],
                      style: {
                        breakInside: "avoid"
                      }
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "origin",
                                        signature: delivery.pickupSignature,
                                        transition: delivery.lastPickupTransition
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "destination",
                                        signature: delivery.shippingSignature,
                                        transition: delivery.lastShippingTransition
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "return",
                                        signature: delivery.returnSignature,
                                        transition: delivery.lastReturnTransition
                                      }))
                            })
                      ],
                      style: {
                        breakInside: "avoid"
                      }
                    }),
                isIkeaClient ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_581432c9",
                                      defaultMessage: "Le livreur m'a laissé le temps de compter mes colis"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_9e326d03",
                                      defaultMessage: "J’atteste avoir pu contrôler ma commande et le nombre de colis qui la compose"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_aaae4234",
                                      defaultMessage: "J’atteste avoir été livré(e) dans la pièce de mon choix"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_b0540508",
                                      defaultMessage: "ai reçu ___ colis"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_cf03da67",
                                      defaultMessage: "Je souhaite que les électroménagers soient déballés"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_f0998dbe",
                                      defaultMessage: "Les électroménagers ont été déballés par le livreur en ma présence"
                                    })
                              }),
                          JsxRuntime.jsx(ApiComponents__DeliverySlip$Question, {
                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_0628ab52",
                                      defaultMessage: "Mes colis ont-ils été livrés en bon état :"
                                    })
                              })
                        ],
                        className: "flex flex-col gap-1"
                      }) : null
              ],
              className: "delivery-slip-page"
            });
}

var Store = {
  make: ApiComponents__DeliverySlip$Store
};

function ApiComponents__DeliverySlip$Transporter$TransportCompany(props) {
  var match = Curry._2(ColiswebApi__Fetcher.V5.Transporter.FetchTransporterInformations.use, undefined, props.transporterId);
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_961d81da",
                                                    defaultMessage: "Société de transport"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                children: [
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_9557b0a5",
                                                  defaultMessage: "Transporteur: "
                                                }))
                                      }),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(match[0].transporterName)
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_c67d063c",
                                                  defaultMessage: "Livreur {number}",
                                                  values: {
                                                    number: Belt_Option.flatMap(props.carrier, (function (param) {
                                                            return param.carrierNumber;
                                                          }))
                                                  }
                                                }))
                                      })
                                ]
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Transporter$ColiswebOwner(props) {
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Table, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      height: "20",
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$TableCell, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "deliverySlip.ownerTitle",
                                                    defaultMessage: "Donneur d'ordre"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$TableRow, {
                      children: Caml_option.some(JsxRuntime.jsxs(ApiComponents__DeliverySlip$TableCell, {
                                children: [
                                  "Colisweb",
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Label, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "deliverySlip.ownerAddress",
                                                  defaultMessage: "Adresse: "
                                                }))
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: "4 Avenue des Saules"
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: "Bâtiment Doge B"
                                      }),
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx(ApiComponents__DeliverySlip$Value, {
                                        children: Caml_option.some(join(undefined, [
                                                  "59000",
                                                  "Lille",
                                                  "FRANCE"
                                                ]))
                                      })
                                ]
                              }))
                    })
              ]
            });
}

function ApiComponents__DeliverySlip$Transporter(props) {
  var delivery = props.delivery;
  var intl = ReactIntl.useIntl();
  var match = delivery.heftingOptions;
  var options = Belt_Array.concat(match.isInRoom ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.inRoom)] : (
          match.isSidewalk ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.sidewalk)] : (
              match.isTrailed ? [intl.formatMessage(ColiswebApi__Locales.HeftingOption.trailed)] : []
            )
        ), Belt_Array.map(delivery.skills, (function (skill) {
              return skill.description;
            })));
  return JsxRuntime.jsxs(ApiComponents__DeliverySlip$Container, {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Heading, {
                                          textAlign: "center",
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_d9c1496d",
                                                    defaultMessage: "Bordereau de livraison transporteur"
                                                  }))
                                        }))
                              }))
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Transporter$ColiswebOwner, {}))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$References, {
                                        id: delivery.id,
                                        timeSlotStart: delivery.timeSlot.start,
                                        timeSlotEnd: delivery.timeSlot.end_,
                                        references: [
                                          delivery.ref1,
                                          delivery.ref2,
                                          delivery.ref3,
                                          delivery.ref4
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Transporter$TransportCompany, {
                                          transporterId: props.transporterId,
                                          carrier: delivery.carrier
                                        }))
                              }))
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(Belt_Option.mapWithDefault(delivery.pickup, null, (function (pickup) {
                                          return JsxRuntime.jsx(ApiComponents__DeliverySlip$Location, {
                                                      variant: "origin",
                                                      name1: pickup.name,
                                                      name2: Toolkit__Primitives.$$Option.fromString(join(" ", [
                                                                Belt_Option.getWithDefault(pickup.firstName, ""),
                                                                Belt_Option.getWithDefault(pickup.lastName, "")
                                                              ])),
                                                      line1: pickup.line1,
                                                      line2: pickup.line2,
                                                      postalCode: pickup.postalCode,
                                                      city: pickup.city,
                                                      country: pickup.country,
                                                      phone1: pickup.phone1,
                                                      phone2: pickup.phone2,
                                                      comment: pickup.comment
                                                    });
                                        })))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(Belt_Option.mapWithDefault(delivery.shipping, null, (function (shipping) {
                                          return JsxRuntime.jsx(ApiComponents__DeliverySlip$Location, {
                                                      variant: "destination",
                                                      name1: shipping.name,
                                                      name2: Toolkit__Primitives.$$Option.fromString(join(" ", [
                                                                Belt_Option.getWithDefault(shipping.firstName, ""),
                                                                Belt_Option.getWithDefault(shipping.lastName, "")
                                                              ])),
                                                      line1: shipping.line1,
                                                      line2: shipping.line2,
                                                      postalCode: shipping.postalCode,
                                                      city: shipping.city,
                                                      country: shipping.country,
                                                      phone1: shipping.phone1,
                                                      phone2: shipping.phone2,
                                                      comment: shipping.comment
                                                    });
                                        })))
                            })
                      ]
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(options.length !== 0 ? JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                  children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Options, {
                                            options: options
                                          }))
                                }) : null)
                    }),
                JsxRuntime.jsx(ApiComponents__DeliverySlip$Row, {
                      children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                                children: Caml_option.some(Belt_Option.mapWithDefault(delivery.packaging, null, (function (packaging) {
                                            return JsxRuntime.jsx(ApiComponents__DeliverySlip$Packaging, {
                                                        description: packaging.description,
                                                        packetsCount: packaging.packetsCount,
                                                        weightKg: packaging.weightKg
                                                      });
                                          })))
                              }))
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "origin",
                                        problem: Belt_Option.flatMap(delivery.lastPickupTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "destination",
                                        problem: Belt_Option.flatMap(delivery.lastShippingTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Problem, {
                                        variant: "return",
                                        problem: Belt_Option.flatMap(delivery.lastReturnTransition, (function (param) {
                                                return param.problem;
                                              }))
                                      }))
                            })
                      ],
                      style: {
                        breakInside: "avoid"
                      }
                    }),
                JsxRuntime.jsxs(ApiComponents__DeliverySlip$Row, {
                      children: [
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "origin",
                                        signature: delivery.pickupSignature,
                                        transition: delivery.lastPickupTransition
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "destination",
                                        signature: delivery.shippingSignature,
                                        transition: delivery.lastShippingTransition
                                      }))
                            }),
                        JsxRuntime.jsx(ApiComponents__DeliverySlip$Col, {
                              children: Caml_option.some(JsxRuntime.jsx(ApiComponents__DeliverySlip$Status, {
                                        variant: "return",
                                        signature: delivery.returnSignature,
                                        transition: delivery.lastReturnTransition
                                      }))
                            })
                      ],
                      style: {
                        breakInside: "avoid"
                      }
                    })
              ],
              className: "delivery-slip-page"
            });
}

var Transporter = {
  make: ApiComponents__DeliverySlip$Transporter
};

export {
  Store ,
  Transporter ,
}
/* react Not a pure module */
