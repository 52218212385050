

import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__DeliveryOptionTag from "../../src/components/delivery-options/ApiComponents__DeliveryOptionTag.bs.js";

function Playground_DeliveryOptionTag(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__DeliveryOptionTag.make, {
                      option: "InRoom"
                    }),
                JsxRuntime.jsx(ApiComponents__DeliveryOptionTag.make, {
                      option: "Sidewalk"
                    }),
                JsxRuntime.jsx(ApiComponents__DeliveryOptionTag.make, {
                      option: "Trailed"
                    }),
                JsxRuntime.jsx(ApiComponents__DeliveryOptionTag.make, {
                      option: "SecurityCode"
                    })
              ],
              className: "flex flex-col gap-4"
            });
}

var Tag;

var make = Playground_DeliveryOptionTag;

export {
  Tag ,
  make ,
}
/* react/jsx-runtime Not a pure module */
