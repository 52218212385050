

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__V6_Utils from "./ColiswebApi__V6_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

var kyInstance = Ky.default.create({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external",
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

var keyBase = "V6_Config";

function t_encode(v) {
  if (v === "PurchaseRule") {
    return "PurchaseRule";
  } else {
    return "DefaultSkippedCost";
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("PurchaseRule" === v) {
          return {
                  TAG: "Ok",
                  _0: "PurchaseRule"
                };
        } else if ("DefaultSkippedCost" === v) {
          return {
                  TAG: "Ok",
                  _0: "DefaultSkippedCost"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode;

var Kind = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString
};

var enumArray = [
  "PurchaseRule",
  "DefaultSkippedCost"
];

function defaultSkippedCost_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "kind",
                    false,
                    t_encode(v.kind)
                  ],
                  [
                    "defaultSkippedCost",
                    false,
                    Spice.floatToJson(v.defaultSkippedCost)
                  ]
                ]));
}

function defaultSkippedCost_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var kind = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "kind"), null));
  if (kind.TAG === "Ok") {
    var defaultSkippedCost = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "defaultSkippedCost"), null));
    if (defaultSkippedCost.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                kind: kind._0,
                defaultSkippedCost: defaultSkippedCost._0
              }
            };
    }
    var e = defaultSkippedCost._0;
    return {
            TAG: "Error",
            _0: {
              path: ".defaultSkippedCost" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = kind._0;
  return {
          TAG: "Error",
          _0: {
            path: ".kind" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function purchaseRule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "kind",
                    false,
                    t_encode(v.kind)
                  ],
                  [
                    "purchaseRuleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.purchaseRuleId)
                  ],
                  [
                    "purchaseCostRatio",
                    false,
                    Spice.floatToJson(v.purchaseCostRatio)
                  ]
                ]));
}

function purchaseRule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var kind = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "kind"), null));
  if (kind.TAG === "Ok") {
    var purchaseRuleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "purchaseRuleId"), null));
    if (purchaseRuleId.TAG === "Ok") {
      var purchaseCostRatio = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "purchaseCostRatio"), null));
      if (purchaseCostRatio.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  kind: kind._0,
                  purchaseRuleId: purchaseRuleId._0,
                  purchaseCostRatio: purchaseCostRatio._0
                }
              };
      }
      var e = purchaseCostRatio._0;
      return {
              TAG: "Error",
              _0: {
                path: ".purchaseCostRatio" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = purchaseRuleId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".purchaseRuleId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = kind._0;
  return {
          TAG: "Error",
          _0: {
            path: ".kind" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function encoder(value) {
  if (value.TAG === "PurchaseRule") {
    return purchaseRule_encode(value._0);
  } else {
    return defaultSkippedCost_encode(value._0);
  }
}

function decoder(json) {
  var kind = t_decode(json.kind);
  if (kind.TAG === "Ok") {
    if (kind._0 === "PurchaseRule") {
      return Curry._2(Toolkit__Primitives_Result.Result.mapOk, purchaseRule_decode(json), (function (v) {
                    return {
                            TAG: "PurchaseRule",
                            _0: v
                          };
                  }));
    } else {
      return Curry._2(Toolkit__Primitives_Result.Result.map, defaultSkippedCost_decode(json), (function (v) {
                    return {
                            TAG: "DefaultSkippedCost",
                            _0: v
                          };
                  }));
    }
  } else {
    return kind;
  }
}

var codec = [
  encoder,
  decoder
];

var t_encode$1 = encoder;

var t_decode$1 = decoder;

var SkippedCost = {
  Kind: Kind,
  enumArray: enumArray,
  defaultSkippedCost_encode: defaultSkippedCost_encode,
  defaultSkippedCost_decode: defaultSkippedCost_decode,
  purchaseRule_encode: purchaseRule_encode,
  purchaseRule_decode: purchaseRule_decode,
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function encoder$1(_v) {
  return Spice.stringToJson("");
}

function decoder$1(json) {
  var match = json.code;
  var value;
  if (match !== undefined) {
    switch (match) {
      case "STORE_FOUND_IN_MULTIPLE_PROJECTS" :
          var projectIds = json.projectIds;
          value = {
            NAME: "storeFoundInMultipleProjects",
            VAL: projectIds
          };
          break;
      case "STORE_HAVE_DRAFT_TRIGGER" :
          value = "storeHaveDraftTrigger";
          break;
      case "STORE_IN_MULTIPLE_SELECTION_ON_PROJECT" :
          var projectId = json.projectId;
          value = {
            NAME: "storeInMultipleSelectionOnProject",
            VAL: projectId
          };
          break;
      default:
        value = "unknown";
    }
  } else {
    value = "unknown";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec$1 = [
  encoder$1,
  decoder$1
];

var t_encode$2 = encoder$1;

var t_decode$2 = decoder$1;

var SelectionError = {
  encoder: encoder$1,
  decoder: decoder$1,
  codec: codec$1,
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

var noZone = {
  id: "_484ba2a9",
  defaultMessage: "Sans zone"
};

var north = {
  id: "_bf0f5e42",
  defaultMessage: "Nord"
};

var south = {
  id: "_1965bfae",
  defaultMessage: "Sud"
};

var eastWest = {
  id: "_d6358186",
  defaultMessage: "Est/Ouest"
};

function t_encode$3(v) {
  switch (v) {
    case "noZone" :
        return "noZone";
    case "north" :
        return "north";
    case "south" :
        return "south";
    case "eastWest" :
        return "eastWest";
    
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("noZone" === v) {
          return {
                  TAG: "Ok",
                  _0: "noZone"
                };
        } else if ("north" === v) {
          return {
                  TAG: "Ok",
                  _0: "north"
                };
        } else if ("south" === v) {
          return {
                  TAG: "Ok",
                  _0: "south"
                };
        } else if ("eastWest" === v) {
          return {
                  TAG: "Ok",
                  _0: "eastWest"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var all = [
  "noZone",
  "north",
  "south",
  "eastWest"
];

function humanize(intl, t) {
  var msg;
  switch (t) {
    case "noZone" :
        msg = noZone;
        break;
    case "north" :
        msg = north;
        break;
    case "south" :
        msg = south;
        break;
    case "eastWest" :
        msg = eastWest;
        break;
    
  }
  return intl.formatMessage(msg);
}

var Sector = {
  noZone: noZone,
  north: north,
  south: south,
  eastWest: eastWest,
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  all: all,
  humanize: humanize
};

function t_encode$4(v) {
  switch (v) {
    case "MaxNumberOfPackets" :
        return "MaxNumberOfPackets";
    case "TotalVolume" :
        return "TotalVolume";
    case "BinPacking" :
        return "BinPacking";
    case "TotalVolumeWithDimensions" :
        return "TotalVolumeWithDimensions";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("MaxNumberOfPackets" === v) {
          return {
                  TAG: "Ok",
                  _0: "MaxNumberOfPackets"
                };
        } else if ("TotalVolume" === v) {
          return {
                  TAG: "Ok",
                  _0: "TotalVolume"
                };
        } else if ("BinPacking" === v) {
          return {
                  TAG: "Ok",
                  _0: "BinPacking"
                };
        } else if ("TotalVolumeWithDimensions" === v) {
          return {
                  TAG: "Ok",
                  _0: "TotalVolumeWithDimensions"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Type = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

var enumArray$1 = [
  "MaxNumberOfPackets",
  "TotalVolume",
  "BinPacking",
  "TotalVolumeWithDimensions"
];

function maxNumberOfPackets_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$4(v.type_)
                  ],
                  [
                    "count",
                    false,
                    Spice.intToJson(v.count)
                  ]
                ]));
}

function maxNumberOfPackets_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var count = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "count"), null));
    if (count.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                count: count._0
              }
            };
    }
    var e = count._0;
    return {
            TAG: "Error",
            _0: {
              path: ".count" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function totalVolume_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$4(v.type_)
                  ],
                  [
                    "volume",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Volume.WithUnit.t_encode, v.volume)
                  ]
                ]));
}

function totalVolume_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var volume = Spice.optionFromJson(Decoders__UnitMeasure.Volume.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "volume"), null));
    if (volume.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                volume: volume._0
              }
            };
    }
    var e = volume._0;
    return {
            TAG: "Error",
            _0: {
              path: ".volume" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function dimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var length = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
  if (length.TAG === "Ok") {
    var width = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
    if (width.TAG === "Ok") {
      var height = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
      if (height.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  length: length._0,
                  width: width._0,
                  height: height._0
                }
              };
      }
      var e = height._0;
      return {
              TAG: "Error",
              _0: {
                path: ".height" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = width._0;
    return {
            TAG: "Error",
            _0: {
              path: ".width" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = length._0;
  return {
          TAG: "Error",
          _0: {
            path: ".length" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function dimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "length",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "width",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ]
                ]));
}

function binPacking_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$4(v.type_)
                  ],
                  [
                    "dimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.dimensions)
                  ]
                ]));
}

function binPacking_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var dimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
    if (dimensions.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                dimensions: dimensions._0
              }
            };
    }
    var e = dimensions._0;
    return {
            TAG: "Error",
            _0: {
              path: ".dimensions" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function totalVolumeWithDimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$4(v.type_)
                  ],
                  [
                    "dimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.dimensions)
                  ],
                  [
                    "fillingVolumeRatio",
                    false,
                    Spice.floatToJson(v.fillingVolumeRatio)
                  ]
                ]));
}

function totalVolumeWithDimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var dimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
    if (dimensions.TAG === "Ok") {
      var fillingVolumeRatio = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "fillingVolumeRatio"), null));
      if (fillingVolumeRatio.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  type_: type_._0,
                  dimensions: dimensions._0,
                  fillingVolumeRatio: fillingVolumeRatio._0
                }
              };
      }
      var e = fillingVolumeRatio._0;
      return {
              TAG: "Error",
              _0: {
                path: ".fillingVolumeRatio" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = dimensions._0;
    return {
            TAG: "Error",
            _0: {
              path: ".dimensions" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

function encoder$2(value) {
  switch (value.TAG) {
    case "MaxNumberOfPackets" :
        return maxNumberOfPackets_encode(value._0);
    case "TotalVolume" :
        return totalVolume_encode(value._0);
    case "BinPacking" :
        return binPacking_encode(value._0);
    case "TotalVolumeWithDimensions" :
        return totalVolumeWithDimensions_encode(value._0);
    
  }
}

function decoder$2(json) {
  var kind = t_decode$4(json.type);
  if (kind.TAG !== "Ok") {
    return kind;
  }
  switch (kind._0) {
    case "MaxNumberOfPackets" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, maxNumberOfPackets_decode(json), (function (v) {
                      return {
                              TAG: "MaxNumberOfPackets",
                              _0: v
                            };
                    }));
    case "TotalVolume" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, totalVolume_decode(json), (function (v) {
                      return {
                              TAG: "TotalVolume",
                              _0: v
                            };
                    }));
    case "BinPacking" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, binPacking_decode(json), (function (v) {
                      return {
                              TAG: "BinPacking",
                              _0: v
                            };
                    }));
    case "TotalVolumeWithDimensions" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, totalVolumeWithDimensions_decode(json), (function (v) {
                      return {
                              TAG: "TotalVolumeWithDimensions",
                              _0: v
                            };
                    }));
    
  }
}

var codec$2 = [
  encoder$2,
  decoder$2
];

var t_encode$5 = encoder$2;

var t_decode$5 = decoder$2;

var PacketsConstraint = {
  Type: Type,
  enumArray: enumArray$1,
  maxNumberOfPackets_encode: maxNumberOfPackets_encode,
  maxNumberOfPackets_decode: maxNumberOfPackets_decode,
  totalVolume_encode: totalVolume_encode,
  totalVolume_decode: totalVolume_decode,
  binPacking_encode: binPacking_encode,
  binPacking_decode: binPacking_decode,
  dimensions_encode: dimensions_encode,
  dimensions_decode: dimensions_decode,
  totalVolumeWithDimensions_encode: totalVolumeWithDimensions_encode,
  totalVolumeWithDimensions_decode: totalVolumeWithDimensions_decode,
  encoder: encoder$2,
  decoder: decoder$2,
  codec: codec$2,
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

var Shared = {
  SkippedCost: SkippedCost,
  SelectionError: SelectionError,
  Sector: Sector,
  PacketsConstraint: PacketsConstraint
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external/projects"
    });

function routePlan_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.RoutePlanId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "sector",
                    false,
                    t_encode$3(v.sector)
                  ],
                  [
                    "prefix",
                    false,
                    Spice.stringToJson(v.prefix)
                  ],
                  [
                    "activationStart",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.activationStart)
                  ],
                  [
                    "activationEnd",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.$$Date.t_encode, v.activationEnd)
                  ]
                ]));
}

function routePlan_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var sector = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "sector"), null));
      if (sector.TAG === "Ok") {
        var prefix = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "prefix"), null));
        if (prefix.TAG === "Ok") {
          var activationStart = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "activationStart"), null));
          if (activationStart.TAG === "Ok") {
            var activationEnd = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "activationEnd"), null));
            if (activationEnd.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        id: id._0,
                        name: name._0,
                        sector: sector._0,
                        prefix: prefix._0,
                        activationStart: activationStart._0,
                        activationEnd: activationEnd._0
                      }
                    };
            }
            var e = activationEnd._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".activationEnd" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = activationStart._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".activationStart" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = prefix._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".prefix" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = sector._0;
      return {
              TAG: "Error",
              _0: {
                path: ".sector" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode(v) {
  return Spice.arrayToJson(routePlan_encode, v);
}

function response_decode(v) {
  return Spice.arrayFromJson(routePlan_decode, v);
}

function config(param) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetRouteProjectsList"
          ]
        };
}

var GetAll = {
  routePlan_encode: routePlan_encode,
  routePlan_decode: routePlan_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  config: config
};

function t_encode$6(v) {
  switch (v) {
    case "store" :
        return "store";
    case "hub" :
        return "hub";
    case "hubAddress" :
        return "hubAddress";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store" === v) {
          return {
                  TAG: "Ok",
                  _0: "store"
                };
        } else if ("hub" === v) {
          return {
                  TAG: "Ok",
                  _0: "hub"
                };
        } else if ("hubAddress" === v) {
          return {
                  TAG: "Ok",
                  _0: "hubAddress"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Type$1 = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

var enumArray$2 = [
  "store",
  "hub",
  "hubAddress"
];

function store_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$6(v.type_)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function store_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  type_: type_._0,
                  storeId: storeId._0,
                  clientId: clientId._0
                }
              };
      }
      var e = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

function hub_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$6(v.type_)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ]
                ]));
}

function hub_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var hubSlugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
    if (hubSlugId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                hubSlugId: hubSlugId._0
              }
            };
    }
    var e = hubSlugId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubSlugId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function encoder$3(value) {
  switch (value.TAG) {
    case "Store" :
        return store_encode(value._0);
    case "Hub" :
    case "HubAddress" :
        return hub_encode(value._0);
    
  }
}

function decoder$3(json) {
  var kind = t_decode$6(json.type);
  if (kind.TAG !== "Ok") {
    return kind;
  }
  switch (kind._0) {
    case "store" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, store_decode(json), (function (v) {
                      return {
                              TAG: "Store",
                              _0: v
                            };
                    }));
    case "hub" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, hub_decode(json), (function (v) {
                      return {
                              TAG: "Hub",
                              _0: v
                            };
                    }));
    case "hubAddress" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, hub_decode(json), (function (v) {
                      return {
                              TAG: "HubAddress",
                              _0: v
                            };
                    }));
    
  }
}

var codec$3 = [
  encoder$3,
  decoder$3
];

var t_encode$7 = encoder$3;

var t_decode$7 = decoder$3;

var RouteTriggerSelectionPickupPoint = {
  Type: Type$1,
  enumArray: enumArray$2,
  store_encode: store_encode,
  store_decode: store_decode,
  hub_encode: hub_encode,
  hub_decode: hub_decode,
  encoder: encoder$3,
  decoder: decoder$3,
  codec: codec$3,
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

function t_encode$8(v) {
  switch (v) {
    case "driving" :
        return "driving";
    case "bicycling" :
        return "bicycling";
    case "walking" :
        return "walking";
    case "transit" :
        return "transit";
    case "unknown" :
        return "unknown";
    case "car" :
        return "car";
    case "truck" :
        return "truck";
    case "bicycle" :
        return "bicycle";
    case "pedestrian" :
        return "pedestrian";
    case "scooter" :
        return "scooter";
    
  }
}

var toString$1 = t_encode$8;

function t_decode$8(v) {
  return Curry._2(Toolkit__Primitives_Result.Result.flatMap, Spice.stringFromJson(v), (function (v) {
                var v$1 = Spice.stringToJson(v.toLowerCase());
                if (!Array.isArray(v$1) && (v$1 === null || typeof v$1 !== "object") && typeof v$1 !== "number" && typeof v$1 !== "string" && typeof v$1 !== "boolean") {
                  return Spice.error(undefined, "Not a JSONString", v$1);
                }
                switch (typeof v$1) {
                  case "string" :
                      if ("driving" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "driving"
                              };
                      } else if ("bicycling" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "bicycling"
                              };
                      } else if ("walking" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "walking"
                              };
                      } else if ("transit" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "transit"
                              };
                      } else if ("unknown" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "unknown"
                              };
                      } else if ("car" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "car"
                              };
                      } else if ("truck" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "truck"
                              };
                      } else if ("bicycle" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "bicycle"
                              };
                      } else if ("pedestrian" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "pedestrian"
                              };
                      } else if ("scooter" === v$1) {
                        return {
                                TAG: "Ok",
                                _0: "scooter"
                              };
                      } else {
                        return Spice.error(undefined, "Not matched", v$1);
                      }
                  case "number" :
                      return Spice.error(undefined, "Not matched", v$1);
                  default:
                    return Spice.error(undefined, "Not a JSONString", v$1);
                }
              }));
}

var enumArray$3 = [
  "driving",
  "bicycling",
  "walking",
  "transit",
  "unknown",
  "car",
  "truck",
  "bicycle",
  "pedestrian",
  "scooter"
];

var TravelMode = {
  t_encode: t_encode$8,
  toString: toString$1,
  t_decode: t_decode$8,
  enumArray: enumArray$3
};

function t_encode$9(v) {
  if (v === "google") {
    return "google";
  } else {
    return "here";
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("google" === v) {
          return {
                  TAG: "Ok",
                  _0: "google"
                };
        } else if ("here" === v) {
          return {
                  TAG: "Ok",
                  _0: "here"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var enumArray$4 = [
  "google",
  "here"
];

var toString$2 = t_encode$9;

var DistanceProvider = {
  t_encode: t_encode$9,
  t_decode: t_decode$9,
  enumArray: enumArray$4,
  toString: toString$2
};

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function eligibilityPickups_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(Identifiers.StoreId.t_encode, v.stores)
                  ],
                  [
                    "warehouses",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.warehouses)
                  ]
                ]));
}

function eligibilityPickups_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    var warehouses = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouses"), null));
    if (warehouses.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                stores: stores._0,
                warehouses: warehouses._0
              }
            };
    }
    var e = warehouses._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouses" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function eligibility_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "departments",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Spice.stringToJson, param);
                          }), v.departments)
                  ],
                  [
                    "pickups",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(eligibilityPickups_encode, v.pickups)
                  ]
                ]));
}

function eligibility_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
  if (country.TAG === "Ok") {
    var departments = Toolkit__Decoders.$$Option.t_decode((function (param) {
            return Spice.arrayFromJson(Spice.stringFromJson, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "departments"), null));
    if (departments.TAG === "Ok") {
      var pickups = Toolkit__Decoders.$$Option.t_decode(eligibilityPickups_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickups"), null));
      if (pickups.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  country: country._0,
                  departments: departments._0,
                  pickups: pickups._0
                }
              };
      }
      var e = pickups._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickups" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = departments._0;
    return {
            TAG: "Error",
            _0: {
              path: ".departments" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = country._0;
  return {
          TAG: "Error",
          _0: {
            path: ".country" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function updateWindow_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "delayAfterFinalTrigger",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.delayAfterFinalTrigger)
                  ],
                  [
                    "delayBeforeRouteStart",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.delayBeforeRouteStart)
                  ],
                  [
                    "allowedExecutionTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.allowedExecutionTime)
                  ],
                  [
                    "firstPickUpRadius",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.firstPickUpRadius)
                  ],
                  [
                    "firstPickUpDelay",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.firstPickUpDelay)
                  ],
                  [
                    "firstPickUpAdvance",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.firstPickUpAdvance)
                  ],
                  [
                    "allowRouteDeliveriesToBeSkipped",
                    false,
                    Spice.boolToJson(v.allowRouteDeliveriesToBeSkipped)
                  ]
                ]));
}

function updateWindow_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var delayAfterFinalTrigger = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "delayAfterFinalTrigger"), null));
  if (delayAfterFinalTrigger.TAG === "Ok") {
    var delayBeforeRouteStart = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "delayBeforeRouteStart"), null));
    if (delayBeforeRouteStart.TAG === "Ok") {
      var allowedExecutionTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "allowedExecutionTime"), null));
      if (allowedExecutionTime.TAG === "Ok") {
        var firstPickUpRadius = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "firstPickUpRadius"), null));
        if (firstPickUpRadius.TAG === "Ok") {
          var firstPickUpDelay = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "firstPickUpDelay"), null));
          if (firstPickUpDelay.TAG === "Ok") {
            var firstPickUpAdvance = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "firstPickUpAdvance"), null));
            if (firstPickUpAdvance.TAG === "Ok") {
              var allowRouteDeliveriesToBeSkipped = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowRouteDeliveriesToBeSkipped"), null));
              if (allowRouteDeliveriesToBeSkipped.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          delayAfterFinalTrigger: delayAfterFinalTrigger._0,
                          delayBeforeRouteStart: delayBeforeRouteStart._0,
                          allowedExecutionTime: allowedExecutionTime._0,
                          firstPickUpRadius: firstPickUpRadius._0,
                          firstPickUpDelay: firstPickUpDelay._0,
                          firstPickUpAdvance: firstPickUpAdvance._0,
                          allowRouteDeliveriesToBeSkipped: allowRouteDeliveriesToBeSkipped._0
                        }
                      };
              }
              var e = allowRouteDeliveriesToBeSkipped._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".allowRouteDeliveriesToBeSkipped" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = firstPickUpAdvance._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".firstPickUpAdvance" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = firstPickUpDelay._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".firstPickUpDelay" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = firstPickUpRadius._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".firstPickUpRadius" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = allowedExecutionTime._0;
      return {
              TAG: "Error",
              _0: {
                path: ".allowedExecutionTime" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = delayBeforeRouteStart._0;
    return {
            TAG: "Error",
            _0: {
              path: ".delayBeforeRouteStart" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = delayAfterFinalTrigger._0;
  return {
          TAG: "Error",
          _0: {
            path: ".delayAfterFinalTrigger" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function finalTrigger_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dayOfWeek",
                    false,
                    ColiswebApi__V6_Utils.DayOfWeek.t_encode(v.dayOfWeek)
                  ],
                  [
                    "at",
                    false,
                    Spice.stringToJson(v.at)
                  ],
                  [
                    "refineSlotSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.refineSlotSize)
                  ],
                  [
                    "deliveryDay",
                    false,
                    ColiswebApi__V6_Utils.DeliveryDay.t_encode(v.deliveryDay)
                  ],
                  [
                    "during",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.during)
                  ],
                  [
                    "specificSelections",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.specificSelections)
                  ]
                ]));
}

function finalTrigger_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dayOfWeek = ColiswebApi__V6_Utils.DayOfWeek.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dayOfWeek"), null));
  if (dayOfWeek.TAG === "Ok") {
    var at = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "at"), null));
    if (at.TAG === "Ok") {
      var refineSlotSize = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "refineSlotSize"), null));
      if (refineSlotSize.TAG === "Ok") {
        var deliveryDay = ColiswebApi__V6_Utils.DeliveryDay.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryDay"), null));
        if (deliveryDay.TAG === "Ok") {
          var during = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "during"), null));
          if (during.TAG === "Ok") {
            var specificSelections = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "specificSelections"), null));
            if (specificSelections.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        dayOfWeek: dayOfWeek._0,
                        at: at._0,
                        refineSlotSize: refineSlotSize._0,
                        deliveryDay: deliveryDay._0,
                        during: during._0,
                        specificSelections: specificSelections._0
                      }
                    };
            }
            var e = specificSelections._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".specificSelections" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = during._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".during" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = deliveryDay._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deliveryDay" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = refineSlotSize._0;
      return {
              TAG: "Error",
              _0: {
                path: ".refineSlotSize" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = at._0;
    return {
            TAG: "Error",
            _0: {
              path: ".at" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = dayOfWeek._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dayOfWeek" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function owner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "webhookUrl",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.webhookUrl)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ]
                ]));
}

function owner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var webhookUrl = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "webhookUrl"), null));
  if (webhookUrl.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
      if (storeId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  webhookUrl: webhookUrl._0,
                  clientId: clientId._0,
                  storeId: storeId._0
                }
              };
      }
      var e = storeId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = webhookUrl._0;
  return {
          TAG: "Error",
          _0: {
            path: ".webhookUrl" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function timeSlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Spice.stringToJson(v.start)
                  ],
                  [
                    "end",
                    false,
                    Spice.stringToJson(v._end)
                  ]
                ]));
}

function timeSlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var _end = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (_end.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                _end: _end._0
              }
            };
    }
    var e = _end._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function draftTrigger_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dayOfWeek",
                    false,
                    ColiswebApi__V6_Utils.DayOfWeek.t_encode(v.dayOfWeek)
                  ],
                  [
                    "at",
                    false,
                    Spice.stringToJson(v.at)
                  ],
                  [
                    "during",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.during)
                  ],
                  [
                    "specificSelections",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.specificSelections)
                  ],
                  [
                    "deliveryDay",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__V6_Utils.DeliveryDay.t_encode, v.deliveryDay)
                  ],
                  [
                    "deliverySlots",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(timeSlot_encode, param);
                          }), v.deliverySlots)
                  ],
                  [
                    "deliverySlotSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.deliverySlotSize)
                  ]
                ]));
}

function draftTrigger_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dayOfWeek = ColiswebApi__V6_Utils.DayOfWeek.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dayOfWeek"), null));
  if (dayOfWeek.TAG === "Ok") {
    var at = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "at"), null));
    if (at.TAG === "Ok") {
      var during = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "during"), null));
      if (during.TAG === "Ok") {
        var specificSelections = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "specificSelections"), null));
        if (specificSelections.TAG === "Ok") {
          var deliveryDay = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__V6_Utils.DeliveryDay.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryDay"), null));
          if (deliveryDay.TAG === "Ok") {
            var deliverySlots = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(timeSlot_decode, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "deliverySlots"), null));
            if (deliverySlots.TAG === "Ok") {
              var deliverySlotSize = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliverySlotSize"), null));
              if (deliverySlotSize.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          dayOfWeek: dayOfWeek._0,
                          at: at._0,
                          during: during._0,
                          specificSelections: specificSelections._0,
                          deliveryDay: deliveryDay._0,
                          deliverySlots: deliverySlots._0,
                          deliverySlotSize: deliverySlotSize._0
                        }
                      };
              }
              var e = deliverySlotSize._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".deliverySlotSize" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = deliverySlots._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".deliverySlots" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = deliveryDay._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".deliveryDay" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = specificSelections._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".specificSelections" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = during._0;
      return {
              TAG: "Error",
              _0: {
                path: ".during" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = at._0;
    return {
            TAG: "Error",
            _0: {
              path: ".at" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = dayOfWeek._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dayOfWeek" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function t_encode$10(v) {
  switch (v) {
    case "electric" :
        return "electric";
    case "gaz" :
        return "gaz";
    case "humanPowered" :
        return "humanPowered";
    
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("electric" === v) {
          return {
                  TAG: "Ok",
                  _0: "electric"
                };
        } else if ("gaz" === v) {
          return {
                  TAG: "Ok",
                  _0: "gaz"
                };
        } else if ("humanPowered" === v) {
          return {
                  TAG: "Ok",
                  _0: "humanPowered"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Type$2 = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

var enumArray$5 = [
  "electric",
  "gaz",
  "humanPowered"
];

function electric_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$10(v.type_)
                  ],
                  [
                    "batteryCapacity",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.EnergyCapacity.WithUnit.t_encode, v.batteryCapacity)
                  ],
                  [
                    "emptyLoadWeight",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.emptyLoadWeight)
                  ]
                ]));
}

function electric_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var batteryCapacity = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.EnergyCapacity.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "batteryCapacity"), null));
    if (batteryCapacity.TAG === "Ok") {
      var emptyLoadWeight = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "emptyLoadWeight"), null));
      if (emptyLoadWeight.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  type_: type_._0,
                  batteryCapacity: batteryCapacity._0,
                  emptyLoadWeight: emptyLoadWeight._0
                }
              };
      }
      var e = emptyLoadWeight._0;
      return {
              TAG: "Error",
              _0: {
                path: ".emptyLoadWeight" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = batteryCapacity._0;
    return {
            TAG: "Error",
            _0: {
              path: ".batteryCapacity" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

function gaz_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "type",
                    false,
                    t_encode$10(v.type_)
                  ]]));
}

function gaz_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              type_: type_._0
            }
          };
  }
  var e = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e.path),
            message: e.message,
            value: e.value
          }
        };
}

function humanPowered_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "type",
                    false,
                    t_encode$10(v.type_)
                  ]]));
}

function humanPowered_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              type_: type_._0
            }
          };
  }
  var e = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e.path),
            message: e.message,
            value: e.value
          }
        };
}

function encoder$4(value) {
  switch (value.TAG) {
    case "Electric" :
        return electric_encode(value._0);
    case "Gaz" :
        return gaz_encode(value._0);
    case "HumanPowered" :
        return humanPowered_encode(value._0);
    
  }
}

function decoder$4(json) {
  var kind = t_decode$10(json.type);
  if (kind.TAG !== "Ok") {
    return kind;
  }
  switch (kind._0) {
    case "electric" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, electric_decode(json), (function (v) {
                      return {
                              TAG: "Electric",
                              _0: v
                            };
                    }));
    case "gaz" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, gaz_decode(json), (function (v) {
                      return {
                              TAG: "Gaz",
                              _0: v
                            };
                    }));
    case "humanPowered" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, humanPowered_decode(json), (function (v) {
                      return {
                              TAG: "HumanPowered",
                              _0: v
                            };
                    }));
    
  }
}

var codec$4 = [
  encoder$4,
  decoder$4
];

var t_encode$11 = encoder$4;

var t_decode$11 = decoder$4;

var Energy = {
  Type: Type$2,
  enumArray: enumArray$5,
  electric_encode: electric_encode,
  electric_decode: electric_decode,
  gaz_encode: gaz_encode,
  gaz_decode: gaz_decode,
  humanPowered_encode: humanPowered_encode,
  humanPowered_decode: humanPowered_decode,
  encoder: encoder$4,
  decoder: decoder$4,
  codec: codec$4,
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function workingSchedule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "end",
                    false,
                    Spice.stringToJson(v.end_)
                  ],
                  [
                    "start",
                    false,
                    Spice.stringToJson(v.start)
                  ],
                  [
                    "dayOfWeek",
                    false,
                    ColiswebApi__V6_Utils.DayOfWeek.t_encode(v.dayOfWeek)
                  ]
                ]));
}

function workingSchedule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var end_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
  if (end_.TAG === "Ok") {
    var start = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
    if (start.TAG === "Ok") {
      var dayOfWeek = ColiswebApi__V6_Utils.DayOfWeek.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dayOfWeek"), null));
      if (dayOfWeek.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  end_: end_._0,
                  start: start._0,
                  dayOfWeek: dayOfWeek._0
                }
              };
      }
      var e = dayOfWeek._0;
      return {
              TAG: "Error",
              _0: {
                path: ".dayOfWeek" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = start._0;
    return {
            TAG: "Error",
            _0: {
              path: ".start" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = end_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("end" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

function commonOwner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.StoreId.t_encode, v.storeId)
                  ]
                ]));
}

function commonOwner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Toolkit__Decoders.$$Option.t_decode(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                clientId: clientId._0,
                storeId: storeId._0
              }
            };
    }
    var e = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function deliverySlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startsAfter",
                    false,
                    Spice.stringToJson(v.startsAfter)
                  ],
                  [
                    "endsBefore",
                    false,
                    Spice.stringToJson(v.endsBefore)
                  ]
                ]));
}

function deliverySlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startsAfter = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "startsAfter"), null));
  if (startsAfter.TAG === "Ok") {
    var endsBefore = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "endsBefore"), null));
    if (endsBefore.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startsAfter: startsAfter._0,
                endsBefore: endsBefore._0
              }
            };
    }
    var e = endsBefore._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endsBefore" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startsAfter._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startsAfter" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function deliveryFilters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliverySlots",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(deliverySlot_encode, param);
                          }), v.deliverySlots)
                  ]]));
}

function deliveryFilters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliverySlots = Toolkit__Decoders.$$Option.t_decode((function (param) {
          return Spice.arrayFromJson(deliverySlot_decode, param);
        }), Belt_Option.getWithDefault(Js_dict.get(v, "deliverySlots"), null));
  if (deliverySlots.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliverySlots: deliverySlots._0
            }
          };
  }
  var e = deliverySlots._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliverySlots" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function finalTriggerParameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "skippedCost",
                    false,
                    encoder(v.skippedCost)
                  ],
                  [
                    "deliveryFilters",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(deliveryFilters_encode, v.deliveryFilters)
                  ]
                ]));
}

function finalTriggerParameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var skippedCost = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "skippedCost"), null));
  if (skippedCost.TAG === "Ok") {
    var deliveryFilters = Toolkit__Decoders.$$Option.t_decode(deliveryFilters_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryFilters"), null));
    if (deliveryFilters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                skippedCost: skippedCost._0,
                deliveryFilters: deliveryFilters._0
              }
            };
    }
    var e = deliveryFilters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryFilters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = skippedCost._0;
  return {
          TAG: "Error",
          _0: {
            path: ".skippedCost" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function commonDraftTriggerParametersEligibilityPickups_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(Identifiers.StoreId.t_encode, v.stores)
                  ],
                  [
                    "warehouses",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.warehouses)
                  ]
                ]));
}

function commonDraftTriggerParametersEligibilityPickups_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    var warehouses = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "warehouses"), null));
    if (warehouses.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                stores: stores._0,
                warehouses: warehouses._0
              }
            };
    }
    var e = warehouses._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouses" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function commonDraftTriggerParametersEligibility_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "departments",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Spice.stringToJson, param);
                          }), v.departments)
                  ],
                  [
                    "pickups",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(commonDraftTriggerParametersEligibilityPickups_encode, v.pickups)
                  ]
                ]));
}

function commonDraftTriggerParametersEligibility_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
  if (country.TAG === "Ok") {
    var departments = Toolkit__Decoders.$$Option.t_decode((function (param) {
            return Spice.arrayFromJson(Spice.stringFromJson, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "departments"), null));
    if (departments.TAG === "Ok") {
      var pickups = Toolkit__Decoders.$$Option.t_decode(commonDraftTriggerParametersEligibilityPickups_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickups"), null));
      if (pickups.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  country: country._0,
                  departments: departments._0,
                  pickups: pickups._0
                }
              };
      }
      var e = pickups._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickups" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = departments._0;
    return {
            TAG: "Error",
            _0: {
              path: ".departments" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = country._0;
  return {
          TAG: "Error",
          _0: {
            path: ".country" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function draftTriggerParameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "eligibility",
                    false,
                    Spice.arrayToJson(commonDraftTriggerParametersEligibility_encode, v.eligibility)
                  ],
                  [
                    "allowRecipientToChangeSlot",
                    false,
                    Spice.boolToJson(v.allowRecipientToChangeSlot)
                  ],
                  [
                    "webhookUrl",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.webhookUrl)
                  ]
                ]));
}

function draftTriggerParameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var eligibility = Spice.arrayFromJson(commonDraftTriggerParametersEligibility_decode, Belt_Option.getWithDefault(Js_dict.get(v, "eligibility"), null));
  if (eligibility.TAG === "Ok") {
    var allowRecipientToChangeSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowRecipientToChangeSlot"), null));
    if (allowRecipientToChangeSlot.TAG === "Ok") {
      var webhookUrl = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "webhookUrl"), null));
      if (webhookUrl.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  eligibility: eligibility._0,
                  allowRecipientToChangeSlot: allowRecipientToChangeSlot._0,
                  webhookUrl: webhookUrl._0
                }
              };
      }
      var e = webhookUrl._0;
      return {
              TAG: "Error",
              _0: {
                path: ".webhookUrl" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = allowRecipientToChangeSlot._0;
    return {
            TAG: "Error",
            _0: {
              path: ".allowRecipientToChangeSlot" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = eligibility._0;
  return {
          TAG: "Error",
          _0: {
            path: ".eligibility" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function commonSelections_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "owner",
                    false,
                    commonOwner_encode(v.owner)
                  ],
                  [
                    "pickupPoint",
                    false,
                    encoder$3(v.pickupPoint)
                  ],
                  [
                    "finalTriggerParameters",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(finalTriggerParameters_encode, v.finalTriggerParameters)
                  ],
                  [
                    "draftTriggerParameters",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(draftTriggerParameters_encode, v.draftTriggerParameters)
                  ]
                ]));
}

function commonSelections_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var owner = commonOwner_decode(Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
    if (owner.TAG === "Ok") {
      var pickupPoint = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "pickupPoint"), null));
      if (pickupPoint.TAG === "Ok") {
        var finalTriggerParameters = Toolkit__Decoders.$$Option.t_decode(finalTriggerParameters_decode, Belt_Option.getWithDefault(Js_dict.get(v, "finalTriggerParameters"), null));
        if (finalTriggerParameters.TAG === "Ok") {
          var draftTriggerParameters = Toolkit__Decoders.$$Option.t_decode(draftTriggerParameters_decode, Belt_Option.getWithDefault(Js_dict.get(v, "draftTriggerParameters"), null));
          if (draftTriggerParameters.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      code: code._0,
                      owner: owner._0,
                      pickupPoint: pickupPoint._0,
                      finalTriggerParameters: finalTriggerParameters._0,
                      draftTriggerParameters: draftTriggerParameters._0
                    }
                  };
          }
          var e = draftTriggerParameters._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".draftTriggerParameters" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = finalTriggerParameters._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".finalTriggerParameters" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = pickupPoint._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickupPoint" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = owner._0;
    return {
            TAG: "Error",
            _0: {
              path: ".owner" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function vehiclePriceOwner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ]
                ]));
}

function vehiclePriceOwner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                clientId: clientId._0,
                storeId: storeId._0
              }
            };
    }
    var e = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function vehiclePrice_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "price",
                    false,
                    Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_encode, v.price)
                  ],
                  [
                    "owners",
                    false,
                    Spice.arrayToJson(vehiclePriceOwner_encode, v.owners)
                  ]
                ]));
}

function vehiclePrice_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var price = Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "price"), null));
  if (price.TAG === "Ok") {
    var owners = Spice.arrayFromJson(vehiclePriceOwner_decode, Belt_Option.getWithDefault(Js_dict.get(v, "owners"), null));
    if (owners.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                price: price._0,
                owners: owners._0
              }
            };
    }
    var e = owners._0;
    return {
            TAG: "Error",
            _0: {
              path: ".owners" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = price._0;
  return {
          TAG: "Error",
          _0: {
            path: ".price" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skills_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "requireAll",
                    false,
                    Spice.arrayToJson(skill_encode, v.requireAll)
                  ],
                  [
                    "requireAny",
                    false,
                    Spice.arrayToJson(skill_encode, v.requireAny)
                  ],
                  [
                    "requireNone",
                    false,
                    Spice.arrayToJson(skill_encode, v.requireNone)
                  ]
                ]));
}

function skills_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var requireAll = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "requireAll"), null));
  if (requireAll.TAG === "Ok") {
    var requireAny = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "requireAny"), null));
    if (requireAny.TAG === "Ok") {
      var requireNone = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "requireNone"), null));
      if (requireNone.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  requireAll: requireAll._0,
                  requireAny: requireAny._0,
                  requireNone: requireNone._0
                }
              };
      }
      var e = requireNone._0;
      return {
              TAG: "Error",
              _0: {
                path: ".requireNone" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = requireAny._0;
    return {
            TAG: "Error",
            _0: {
              path: ".requireAny" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = requireAll._0;
  return {
          TAG: "Error",
          _0: {
            path: ".requireAll" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function projectVehicle_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "occurrences",
                    false,
                    Spice.intToJson(v.occurrences)
                  ],
                  [
                    "paidOccurrences",
                    false,
                    Spice.intToJson(v.paidOccurrences)
                  ],
                  [
                    "travelMode",
                    false,
                    t_encode$8(v.travelMode)
                  ],
                  [
                    "numberOfHandlers",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.numberOfHandlers)
                  ],
                  [
                    "packetsConstraint",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$5, v.packetsConstraint)
                  ],
                  [
                    "maxWeight",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.maxWeight)
                  ],
                  [
                    "workingSchedule",
                    false,
                    Spice.arrayToJson(workingSchedule_encode, v.workingSchedule)
                  ],
                  [
                    "maximumDistance",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.maximumDistance)
                  ],
                  [
                    "maximumWorkTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.maximumWorkTime)
                  ],
                  [
                    "maximumHandlingTime",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.maximumHandlingTime)
                  ],
                  [
                    "uniquePickUp",
                    false,
                    Spice.boolToJson(v.uniquePickUp)
                  ],
                  [
                    "useCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_encode, v.useCost)
                  ],
                  [
                    "additionalKilometricCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerDistance.WithUnit.t_encode, v.additionalKilometricCost)
                  ],
                  [
                    "additionalHourlyCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerTime.WithUnit.t_encode, v.additionalHourlyCost)
                  ],
                  [
                    "paidDistance",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.paidDistance)
                  ],
                  [
                    "paidWorkTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.paidWorkTime)
                  ],
                  [
                    "waitingTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.waitingTime)
                  ],
                  [
                    "waitingTimeConsolidationFactor",
                    false,
                    Spice.floatToJson(v.waitingTimeConsolidationFactor)
                  ],
                  [
                    "startingPoint",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.Coordinates.t_encode, v.startingPoint)
                  ],
                  [
                    "endingPoint",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.Coordinates.t_encode, v.endingPoint)
                  ],
                  [
                    "packetWeightLimit",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.packetWeightLimit)
                  ],
                  [
                    "endingPointZoneRadius",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.endingPointZoneRadius)
                  ],
                  [
                    "skills",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(skills_encode, v.skills)
                  ],
                  [
                    "co2EmissionsPerKm",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.co2EmissionsPerKm)
                  ],
                  [
                    "maxWeightPerDelivery",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.maxWeightPerDelivery)
                  ],
                  [
                    "energy",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$11, v.energy)
                  ],
                  [
                    "maxSpeed",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Speed.WithUnit.t_encode, v.maxSpeed)
                  ],
                  [
                    "startingPointZoneRadius",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.startingPointZoneRadius)
                  ],
                  [
                    "prices",
                    false,
                    Spice.arrayToJson(vehiclePrice_encode, v.prices)
                  ],
                  [
                    "maxDeliveries",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.maxDeliveries)
                  ],
                  [
                    "vehicleFormatId",
                    false,
                    Spice.optionToJson(Identifiers.VehicleFormatId.t_encode, v.vehicleFormatId)
                  ]
                ]));
}

function projectVehicle_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var occurrences = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "occurrences"), null));
    if (occurrences.TAG === "Ok") {
      var paidOccurrences = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "paidOccurrences"), null));
      if (paidOccurrences.TAG === "Ok") {
        var travelMode = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "travelMode"), null));
        if (travelMode.TAG === "Ok") {
          var numberOfHandlers = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "numberOfHandlers"), null));
          if (numberOfHandlers.TAG === "Ok") {
            var packetsConstraint = Toolkit__Decoders.$$Option.t_decode(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "packetsConstraint"), null));
            if (packetsConstraint.TAG === "Ok") {
              var maxWeight = Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxWeight"), null));
              if (maxWeight.TAG === "Ok") {
                var workingSchedule = Spice.arrayFromJson(workingSchedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "workingSchedule"), null));
                if (workingSchedule.TAG === "Ok") {
                  var maximumDistance = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maximumDistance"), null));
                  if (maximumDistance.TAG === "Ok") {
                    var maximumWorkTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maximumWorkTime"), null));
                    if (maximumWorkTime.TAG === "Ok") {
                      var maximumHandlingTime = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maximumHandlingTime"), null));
                      if (maximumHandlingTime.TAG === "Ok") {
                        var uniquePickUp = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "uniquePickUp"), null));
                        if (uniquePickUp.TAG === "Ok") {
                          var useCost = Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "useCost"), null));
                          if (useCost.TAG === "Ok") {
                            var additionalKilometricCost = Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerDistance.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalKilometricCost"), null));
                            if (additionalKilometricCost.TAG === "Ok") {
                              var additionalHourlyCost = Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerTime.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalHourlyCost"), null));
                              if (additionalHourlyCost.TAG === "Ok") {
                                var paidDistance = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "paidDistance"), null));
                                if (paidDistance.TAG === "Ok") {
                                  var paidWorkTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "paidWorkTime"), null));
                                  if (paidWorkTime.TAG === "Ok") {
                                    var waitingTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "waitingTime"), null));
                                    if (waitingTime.TAG === "Ok") {
                                      var waitingTimeConsolidationFactor = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "waitingTimeConsolidationFactor"), null));
                                      if (waitingTimeConsolidationFactor.TAG === "Ok") {
                                        var startingPoint = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "startingPoint"), null));
                                        if (startingPoint.TAG === "Ok") {
                                          var endingPoint = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "endingPoint"), null));
                                          if (endingPoint.TAG === "Ok") {
                                            var packetWeightLimit = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "packetWeightLimit"), null));
                                            if (packetWeightLimit.TAG === "Ok") {
                                              var endingPointZoneRadius = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "endingPointZoneRadius"), null));
                                              if (endingPointZoneRadius.TAG === "Ok") {
                                                var skills = Toolkit__Decoders.$$Option.t_decode(skills_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                                                if (skills.TAG === "Ok") {
                                                  var co2EmissionsPerKm = Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "co2EmissionsPerKm"), null));
                                                  if (co2EmissionsPerKm.TAG === "Ok") {
                                                    var maxWeightPerDelivery = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxWeightPerDelivery"), null));
                                                    if (maxWeightPerDelivery.TAG === "Ok") {
                                                      var energy = Toolkit__Decoders.$$Option.t_decode(t_decode$11, Belt_Option.getWithDefault(Js_dict.get(v, "energy"), null));
                                                      if (energy.TAG === "Ok") {
                                                        var maxSpeed = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Speed.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxSpeed"), null));
                                                        if (maxSpeed.TAG === "Ok") {
                                                          var startingPointZoneRadius = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "startingPointZoneRadius"), null));
                                                          if (startingPointZoneRadius.TAG === "Ok") {
                                                            var prices = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "prices"), (function (param) {
                                                                        return Spice.arrayFromJson(vehiclePrice_decode, param);
                                                                      })), {
                                                                  TAG: "Ok",
                                                                  _0: []
                                                                });
                                                            if (prices.TAG === "Ok") {
                                                              var maxDeliveries = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "maxDeliveries"), null));
                                                              if (maxDeliveries.TAG === "Ok") {
                                                                var vehicleFormatId = Spice.optionFromJson(Identifiers.VehicleFormatId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "vehicleFormatId"), null));
                                                                if (vehicleFormatId.TAG === "Ok") {
                                                                  return {
                                                                          TAG: "Ok",
                                                                          _0: {
                                                                            name: name._0,
                                                                            occurrences: occurrences._0,
                                                                            paidOccurrences: paidOccurrences._0,
                                                                            travelMode: travelMode._0,
                                                                            numberOfHandlers: numberOfHandlers._0,
                                                                            packetsConstraint: packetsConstraint._0,
                                                                            maxWeight: maxWeight._0,
                                                                            workingSchedule: workingSchedule._0,
                                                                            maximumDistance: maximumDistance._0,
                                                                            maximumWorkTime: maximumWorkTime._0,
                                                                            maximumHandlingTime: maximumHandlingTime._0,
                                                                            uniquePickUp: uniquePickUp._0,
                                                                            useCost: useCost._0,
                                                                            additionalKilometricCost: additionalKilometricCost._0,
                                                                            additionalHourlyCost: additionalHourlyCost._0,
                                                                            paidDistance: paidDistance._0,
                                                                            paidWorkTime: paidWorkTime._0,
                                                                            waitingTime: waitingTime._0,
                                                                            waitingTimeConsolidationFactor: waitingTimeConsolidationFactor._0,
                                                                            startingPoint: startingPoint._0,
                                                                            endingPoint: endingPoint._0,
                                                                            packetWeightLimit: packetWeightLimit._0,
                                                                            endingPointZoneRadius: endingPointZoneRadius._0,
                                                                            skills: skills._0,
                                                                            co2EmissionsPerKm: co2EmissionsPerKm._0,
                                                                            maxWeightPerDelivery: maxWeightPerDelivery._0,
                                                                            energy: energy._0,
                                                                            maxSpeed: maxSpeed._0,
                                                                            startingPointZoneRadius: startingPointZoneRadius._0,
                                                                            prices: prices._0,
                                                                            maxDeliveries: maxDeliveries._0,
                                                                            vehicleFormatId: vehicleFormatId._0
                                                                          }
                                                                        };
                                                                }
                                                                var e = vehicleFormatId._0;
                                                                return {
                                                                        TAG: "Error",
                                                                        _0: {
                                                                          path: ".vehicleFormatId" + e.path,
                                                                          message: e.message,
                                                                          value: e.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$1 = maxDeliveries._0;
                                                              return {
                                                                      TAG: "Error",
                                                                      _0: {
                                                                        path: ".maxDeliveries" + e$1.path,
                                                                        message: e$1.message,
                                                                        value: e$1.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$2 = prices._0;
                                                            return {
                                                                    TAG: "Error",
                                                                    _0: {
                                                                      path: ".prices" + e$2.path,
                                                                      message: e$2.message,
                                                                      value: e$2.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$3 = startingPointZoneRadius._0;
                                                          return {
                                                                  TAG: "Error",
                                                                  _0: {
                                                                    path: ".startingPointZoneRadius" + e$3.path,
                                                                    message: e$3.message,
                                                                    value: e$3.value
                                                                  }
                                                                };
                                                        }
                                                        var e$4 = maxSpeed._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".maxSpeed" + e$4.path,
                                                                  message: e$4.message,
                                                                  value: e$4.value
                                                                }
                                                              };
                                                      }
                                                      var e$5 = energy._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".energy" + e$5.path,
                                                                message: e$5.message,
                                                                value: e$5.value
                                                              }
                                                            };
                                                    }
                                                    var e$6 = maxWeightPerDelivery._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".maxWeightPerDelivery" + e$6.path,
                                                              message: e$6.message,
                                                              value: e$6.value
                                                            }
                                                          };
                                                  }
                                                  var e$7 = co2EmissionsPerKm._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".co2EmissionsPerKm" + e$7.path,
                                                            message: e$7.message,
                                                            value: e$7.value
                                                          }
                                                        };
                                                }
                                                var e$8 = skills._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".skills" + e$8.path,
                                                          message: e$8.message,
                                                          value: e$8.value
                                                        }
                                                      };
                                              }
                                              var e$9 = endingPointZoneRadius._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".endingPointZoneRadius" + e$9.path,
                                                        message: e$9.message,
                                                        value: e$9.value
                                                      }
                                                    };
                                            }
                                            var e$10 = packetWeightLimit._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".packetWeightLimit" + e$10.path,
                                                      message: e$10.message,
                                                      value: e$10.value
                                                    }
                                                  };
                                          }
                                          var e$11 = endingPoint._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".endingPoint" + e$11.path,
                                                    message: e$11.message,
                                                    value: e$11.value
                                                  }
                                                };
                                        }
                                        var e$12 = startingPoint._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".startingPoint" + e$12.path,
                                                  message: e$12.message,
                                                  value: e$12.value
                                                }
                                              };
                                      }
                                      var e$13 = waitingTimeConsolidationFactor._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".waitingTimeConsolidationFactor" + e$13.path,
                                                message: e$13.message,
                                                value: e$13.value
                                              }
                                            };
                                    }
                                    var e$14 = waitingTime._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".waitingTime" + e$14.path,
                                              message: e$14.message,
                                              value: e$14.value
                                            }
                                          };
                                  }
                                  var e$15 = paidWorkTime._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".paidWorkTime" + e$15.path,
                                            message: e$15.message,
                                            value: e$15.value
                                          }
                                        };
                                }
                                var e$16 = paidDistance._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".paidDistance" + e$16.path,
                                          message: e$16.message,
                                          value: e$16.value
                                        }
                                      };
                              }
                              var e$17 = additionalHourlyCost._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".additionalHourlyCost" + e$17.path,
                                        message: e$17.message,
                                        value: e$17.value
                                      }
                                    };
                            }
                            var e$18 = additionalKilometricCost._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".additionalKilometricCost" + e$18.path,
                                      message: e$18.message,
                                      value: e$18.value
                                    }
                                  };
                          }
                          var e$19 = useCost._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".useCost" + e$19.path,
                                    message: e$19.message,
                                    value: e$19.value
                                  }
                                };
                        }
                        var e$20 = uniquePickUp._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".uniquePickUp" + e$20.path,
                                  message: e$20.message,
                                  value: e$20.value
                                }
                              };
                      }
                      var e$21 = maximumHandlingTime._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".maximumHandlingTime" + e$21.path,
                                message: e$21.message,
                                value: e$21.value
                              }
                            };
                    }
                    var e$22 = maximumWorkTime._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".maximumWorkTime" + e$22.path,
                              message: e$22.message,
                              value: e$22.value
                            }
                          };
                  }
                  var e$23 = maximumDistance._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".maximumDistance" + e$23.path,
                            message: e$23.message,
                            value: e$23.value
                          }
                        };
                }
                var e$24 = workingSchedule._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".workingSchedule" + e$24.path,
                          message: e$24.message,
                          value: e$24.value
                        }
                      };
              }
              var e$25 = maxWeight._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".maxWeight" + e$25.path,
                        message: e$25.message,
                        value: e$25.value
                      }
                    };
            }
            var e$26 = packetsConstraint._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".packetsConstraint" + e$26.path,
                      message: e$26.message,
                      value: e$26.value
                    }
                  };
          }
          var e$27 = numberOfHandlers._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".numberOfHandlers" + e$27.path,
                    message: e$27.message,
                    value: e$27.value
                  }
                };
        }
        var e$28 = travelMode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".travelMode" + e$28.path,
                  message: e$28.message,
                  value: e$28.value
                }
              };
      }
      var e$29 = paidOccurrences._0;
      return {
              TAG: "Error",
              _0: {
                path: ".paidOccurrences" + e$29.path,
                message: e$29.message,
                value: e$29.value
              }
            };
    }
    var e$30 = occurrences._0;
    return {
            TAG: "Error",
            _0: {
              path: ".occurrences" + e$30.path,
              message: e$30.message,
              value: e$30.value
            }
          };
  }
  var e$31 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$31.path,
            message: e$31.message,
            value: e$31.value
          }
        };
}

function outOfSlotParameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "allowedTimeToDeliverOutOfSlot",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.allowedTimeToDeliverOutOfSlot)
                  ],
                  [
                    "deliveriesPercentage",
                    false,
                    Spice.floatToJson(v.deliveriesPercentage)
                  ]
                ]));
}

function outOfSlotParameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allowedTimeToDeliverOutOfSlot = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "allowedTimeToDeliverOutOfSlot"), null));
  if (allowedTimeToDeliverOutOfSlot.TAG === "Ok") {
    var deliveriesPercentage = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveriesPercentage"), null));
    if (deliveriesPercentage.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                allowedTimeToDeliverOutOfSlot: allowedTimeToDeliverOutOfSlot._0,
                deliveriesPercentage: deliveriesPercentage._0
              }
            };
    }
    var e = deliveriesPercentage._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveriesPercentage" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = allowedTimeToDeliverOutOfSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allowedTimeToDeliverOutOfSlot" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function parameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "vehicles",
                    false,
                    Spice.arrayToJson(projectVehicle_encode, v.vehicles)
                  ],
                  [
                    "enableHubTransportCapacityManagement",
                    false,
                    Spice.boolToJson(v.enableHubTransportCapacityManagement)
                  ],
                  [
                    "handlingTime",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.handlingTime)
                  ],
                  [
                    "consolidationFactorForHandlingTime",
                    false,
                    Spice.floatToJson(v.consolidationFactorForHandlingTime)
                  ],
                  [
                    "isTrafficIncluded",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.boolToJson, v.isTrafficIncluded)
                  ],
                  [
                    "enablePickUpQueues",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.boolToJson, v.enablePickUpQueues)
                  ],
                  [
                    "distanceBackend",
                    false,
                    t_encode$9(v.distanceBackend)
                  ],
                  [
                    "travelTimeRatio",
                    false,
                    Spice.floatToJson(v.travelTimeRatio)
                  ],
                  [
                    "enableBreak",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.boolToJson, v.enableBreak)
                  ],
                  [
                    "maxParallelExecution",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.maxParallelExecution)
                  ],
                  [
                    "outOfSlotParameters",
                    false,
                    Spice.optionToJson(outOfSlotParameters_encode, v.outOfSlotParameters)
                  ]
                ]));
}

function parameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var vehicles = Spice.arrayFromJson(projectVehicle_decode, Belt_Option.getWithDefault(Js_dict.get(v, "vehicles"), null));
  if (vehicles.TAG === "Ok") {
    var enableHubTransportCapacityManagement = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "enableHubTransportCapacityManagement"), null));
    if (enableHubTransportCapacityManagement.TAG === "Ok") {
      var handlingTime = Toolkit__Decoders.$$Option.t_decode(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "handlingTime"), null));
      if (handlingTime.TAG === "Ok") {
        var consolidationFactorForHandlingTime = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "consolidationFactorForHandlingTime"), null));
        if (consolidationFactorForHandlingTime.TAG === "Ok") {
          var isTrafficIncluded = Toolkit__Decoders.$$Option.t_decode(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "isTrafficIncluded"), null));
          if (isTrafficIncluded.TAG === "Ok") {
            var enablePickUpQueues = Toolkit__Decoders.$$Option.t_decode(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "enablePickUpQueues"), null));
            if (enablePickUpQueues.TAG === "Ok") {
              var distanceBackend = t_decode$9(Belt_Option.getWithDefault(Js_dict.get(v, "distanceBackend"), null));
              if (distanceBackend.TAG === "Ok") {
                var travelTimeRatio = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "travelTimeRatio"), Spice.floatFromJson), {
                      TAG: "Ok",
                      _0: 1
                    });
                if (travelTimeRatio.TAG === "Ok") {
                  var enableBreak = Toolkit__Decoders.$$Option.t_decode(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "enableBreak"), null));
                  if (enableBreak.TAG === "Ok") {
                    var maxParallelExecution = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "maxParallelExecution"), null));
                    if (maxParallelExecution.TAG === "Ok") {
                      var outOfSlotParameters = Spice.optionFromJson(outOfSlotParameters_decode, Belt_Option.getWithDefault(Js_dict.get(v, "outOfSlotParameters"), null));
                      if (outOfSlotParameters.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  vehicles: vehicles._0,
                                  enableHubTransportCapacityManagement: enableHubTransportCapacityManagement._0,
                                  handlingTime: handlingTime._0,
                                  consolidationFactorForHandlingTime: consolidationFactorForHandlingTime._0,
                                  isTrafficIncluded: isTrafficIncluded._0,
                                  enablePickUpQueues: enablePickUpQueues._0,
                                  distanceBackend: distanceBackend._0,
                                  travelTimeRatio: travelTimeRatio._0,
                                  enableBreak: enableBreak._0,
                                  maxParallelExecution: maxParallelExecution._0,
                                  outOfSlotParameters: outOfSlotParameters._0
                                }
                              };
                      }
                      var e = outOfSlotParameters._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".outOfSlotParameters" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = maxParallelExecution._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".maxParallelExecution" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = enableBreak._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".enableBreak" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = travelTimeRatio._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".travelTimeRatio" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = distanceBackend._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".distanceBackend" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = enablePickUpQueues._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".enablePickUpQueues" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = isTrafficIncluded._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isTrafficIncluded" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = consolidationFactorForHandlingTime._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".consolidationFactorForHandlingTime" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = handlingTime._0;
      return {
              TAG: "Error",
              _0: {
                path: ".handlingTime" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = enableHubTransportCapacityManagement._0;
    return {
            TAG: "Error",
            _0: {
              path: ".enableHubTransportCapacityManagement" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = vehicles._0;
  return {
          TAG: "Error",
          _0: {
            path: ".vehicles" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function routePlan_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.RoutePlanId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "prefix",
                    false,
                    Spice.stringToJson(v.prefix)
                  ],
                  [
                    "sector",
                    false,
                    t_encode$3(v.sector)
                  ],
                  [
                    "finalRoutesTriggers",
                    false,
                    Spice.arrayToJson(finalTrigger_encode, v.finalRoutesTriggers)
                  ],
                  [
                    "updateWindow",
                    false,
                    Spice.optionToJson(updateWindow_encode, v.updateWindow)
                  ],
                  [
                    "draftRoutesTriggers",
                    false,
                    Spice.arrayToJson(draftTrigger_encode, v.draftRoutesTriggers)
                  ],
                  [
                    "timeZone",
                    false,
                    Spice.stringToJson(v.timeZone)
                  ],
                  [
                    "notificationEmails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.notificationEmails)
                  ],
                  [
                    "analysisDriveFolderId",
                    false,
                    Spice.stringToJson(v.analysisDriveFolderId)
                  ],
                  [
                    "notificationChatUrl",
                    false,
                    Spice.stringToJson(v.notificationChatUrl)
                  ],
                  [
                    "webhookUrl",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.webhookUrl)
                  ],
                  [
                    "activationStart",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.activationStart)
                  ],
                  [
                    "activationEnd",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.$$Date.t_encode, v.activationEnd)
                  ],
                  [
                    "simulation",
                    false,
                    Spice.boolToJson(v.simulation)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode(v.parameters)
                  ],
                  [
                    "selections",
                    false,
                    Spice.arrayToJson(commonSelections_encode, v.selections)
                  ]
                ]));
}

function routePlan_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var prefix = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "prefix"), null));
      if (prefix.TAG === "Ok") {
        var sector = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "sector"), null));
        if (sector.TAG === "Ok") {
          var finalRoutesTriggers = Spice.arrayFromJson(finalTrigger_decode, Belt_Option.getWithDefault(Js_dict.get(v, "finalRoutesTriggers"), null));
          if (finalRoutesTriggers.TAG === "Ok") {
            var updateWindow = Spice.optionFromJson(updateWindow_decode, Belt_Option.getWithDefault(Js_dict.get(v, "updateWindow"), null));
            if (updateWindow.TAG === "Ok") {
              var draftRoutesTriggers = Spice.arrayFromJson(draftTrigger_decode, Belt_Option.getWithDefault(Js_dict.get(v, "draftRoutesTriggers"), null));
              if (draftRoutesTriggers.TAG === "Ok") {
                var timeZone = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "timeZone"), null));
                if (timeZone.TAG === "Ok") {
                  var notificationEmails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "notificationEmails"), null));
                  if (notificationEmails.TAG === "Ok") {
                    var analysisDriveFolderId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "analysisDriveFolderId"), null));
                    if (analysisDriveFolderId.TAG === "Ok") {
                      var notificationChatUrl = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notificationChatUrl"), null));
                      if (notificationChatUrl.TAG === "Ok") {
                        var webhookUrl = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "webhookUrl"), null));
                        if (webhookUrl.TAG === "Ok") {
                          var activationStart = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "activationStart"), null));
                          if (activationStart.TAG === "Ok") {
                            var activationEnd = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "activationEnd"), null));
                            if (activationEnd.TAG === "Ok") {
                              var simulation = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "simulation"), null));
                              if (simulation.TAG === "Ok") {
                                var parameters = parameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
                                if (parameters.TAG === "Ok") {
                                  var selections = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "selections"), (function (param) {
                                              return Spice.arrayFromJson(commonSelections_decode, param);
                                            })), {
                                        TAG: "Ok",
                                        _0: []
                                      });
                                  if (selections.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              id: id._0,
                                              name: name._0,
                                              prefix: prefix._0,
                                              sector: sector._0,
                                              finalRoutesTriggers: finalRoutesTriggers._0,
                                              updateWindow: updateWindow._0,
                                              draftRoutesTriggers: draftRoutesTriggers._0,
                                              timeZone: timeZone._0,
                                              notificationEmails: notificationEmails._0,
                                              analysisDriveFolderId: analysisDriveFolderId._0,
                                              notificationChatUrl: notificationChatUrl._0,
                                              webhookUrl: webhookUrl._0,
                                              activationStart: activationStart._0,
                                              activationEnd: activationEnd._0,
                                              simulation: simulation._0,
                                              parameters: parameters._0,
                                              selections: selections._0
                                            }
                                          };
                                  }
                                  var e = selections._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".selections" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = parameters._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".parameters" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = simulation._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".simulation" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = activationEnd._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".activationEnd" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = activationStart._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".activationStart" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = webhookUrl._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".webhookUrl" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = notificationChatUrl._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".notificationChatUrl" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = analysisDriveFolderId._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".analysisDriveFolderId" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = notificationEmails._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".notificationEmails" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = timeZone._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".timeZone" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = draftRoutesTriggers._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".draftRoutesTriggers" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = updateWindow._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".updateWindow" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = finalRoutesTriggers._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".finalRoutesTriggers" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = sector._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".sector" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = prefix._0;
      return {
              TAG: "Error",
              _0: {
                path: ".prefix" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

var response_encode$1 = routePlan_encode$1;

var response_decode$1 = routePlan_decode$1;

function config$1(routePlanId) {
  return {
          kyInstance: kyInstance$1,
          path: routePlanId,
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetRouteProject",
            routePlanId
          ]
        };
}

var GetDetails = {
  RouteTriggerSelectionPickupPoint: RouteTriggerSelectionPickupPoint,
  TravelMode: TravelMode,
  DistanceProvider: DistanceProvider,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  eligibilityPickups_encode: eligibilityPickups_encode,
  eligibilityPickups_decode: eligibilityPickups_decode,
  eligibility_encode: eligibility_encode,
  eligibility_decode: eligibility_decode,
  updateWindow_encode: updateWindow_encode,
  updateWindow_decode: updateWindow_decode,
  finalTrigger_encode: finalTrigger_encode,
  finalTrigger_decode: finalTrigger_decode,
  owner_encode: owner_encode,
  owner_decode: owner_decode,
  timeSlot_encode: timeSlot_encode,
  timeSlot_decode: timeSlot_decode,
  draftTrigger_encode: draftTrigger_encode,
  draftTrigger_decode: draftTrigger_decode,
  Energy: Energy,
  workingSchedule_encode: workingSchedule_encode,
  workingSchedule_decode: workingSchedule_decode,
  commonOwner_encode: commonOwner_encode,
  commonOwner_decode: commonOwner_decode,
  deliverySlot_encode: deliverySlot_encode,
  deliverySlot_decode: deliverySlot_decode,
  deliveryFilters_encode: deliveryFilters_encode,
  deliveryFilters_decode: deliveryFilters_decode,
  finalTriggerParameters_encode: finalTriggerParameters_encode,
  finalTriggerParameters_decode: finalTriggerParameters_decode,
  commonDraftTriggerParametersEligibilityPickups_encode: commonDraftTriggerParametersEligibilityPickups_encode,
  commonDraftTriggerParametersEligibilityPickups_decode: commonDraftTriggerParametersEligibilityPickups_decode,
  commonDraftTriggerParametersEligibility_encode: commonDraftTriggerParametersEligibility_encode,
  commonDraftTriggerParametersEligibility_decode: commonDraftTriggerParametersEligibility_decode,
  draftTriggerParameters_encode: draftTriggerParameters_encode,
  draftTriggerParameters_decode: draftTriggerParameters_decode,
  commonSelections_encode: commonSelections_encode,
  commonSelections_decode: commonSelections_decode,
  vehiclePriceOwner_encode: vehiclePriceOwner_encode,
  vehiclePriceOwner_decode: vehiclePriceOwner_decode,
  vehiclePrice_encode: vehiclePrice_encode,
  vehiclePrice_decode: vehiclePrice_decode,
  skills_encode: skills_encode,
  skills_decode: skills_decode,
  projectVehicle_encode: projectVehicle_encode,
  projectVehicle_decode: projectVehicle_decode,
  outOfSlotParameters_encode: outOfSlotParameters_encode,
  outOfSlotParameters_decode: outOfSlotParameters_decode,
  parameters_encode: parameters_encode,
  parameters_decode: parameters_decode,
  routePlan_encode: routePlan_encode$1,
  routePlan_decode: routePlan_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  config: config$1
};

var body_decode = routePlan_decode$1;

var body_encode = routePlan_encode$1;

function argument_encode(v) {
  return [
          Curry._1(Identifiers.RoutePlanId.t_encode, v[0]),
          routePlan_encode$1(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.RoutePlanId.t_decode, v0);
  var match$1 = routePlan_decode$1(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$2 = Spice.unitToJson;

var response_decode$2 = Spice.unitFromJson;

function errorResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function errorResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$2(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: routePlan_encode$1(param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorResponse_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                if (decodedError._0.message.includes("trigger overlapped")) {
                                  return {
                                          TAG: "Custom",
                                          _0: "TriggerOverlapped"
                                        };
                                } else {
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                                }
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Update = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  config: config$2
};

var body_decode$1 = routePlan_decode$1;

var body_encode$1 = routePlan_encode$1;

var argument_encode$1 = routePlan_encode$1;

var argument_decode$1 = routePlan_decode$1;

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "id",
                    false,
                    Curry._1(Identifiers.RoutePlanId.t_encode, v.id)
                  ]]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              id: id._0
            }
          };
  }
  var e = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorResponse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function errorResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function error_encode(v) {
  if (v === "slugname_already_taken") {
    return ["slugname_already_taken"];
  } else {
    return ["prefix_already_taken"];
  }
}

function error_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "prefix_already_taken" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "prefix_already_taken"
                  };
          }
      case "slugname_already_taken" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "slugname_already_taken"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function config$3(body) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "POST",
            json: routePlan_encode$1(body)
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorResponse_decode$1), (function (decodedError) {
                              if (decodedError.TAG !== "Ok") {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                              var message = decodedError._0.message;
                              if (message.includes("project_prefix_unique")) {
                                return {
                                        TAG: "Custom",
                                        _0: "prefix_already_taken"
                                      };
                              } else if (message.includes("slugName")) {
                                return {
                                        TAG: "Custom",
                                        _0: "slugname_already_taken"
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Create = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  errorResponse_encode: errorResponse_encode$1,
  errorResponse_decode: errorResponse_decode$1,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config$3
};

function searchParams_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryDate",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.deliveryDate)
                  ]]));
}

function searchParams_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryDate = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryDate"), null));
  if (deliveryDate.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryDate: deliveryDate._0
            }
          };
  }
  var e = deliveryDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryDate" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function trigger_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "projectId",
                    false,
                    Curry._1(Identifiers.RoutePlanId.t_encode, v.projectId)
                  ],
                  [
                    "projectName",
                    false,
                    Spice.stringToJson(v.projectName)
                  ],
                  [
                    "simulation",
                    false,
                    Spice.boolToJson(v.simulation)
                  ],
                  [
                    "at",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.at)
                  ],
                  [
                    "deliveryDate",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.deliveryDate)
                  ],
                  [
                    "during",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.during)
                  ]
                ]));
}

function trigger_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var projectId = Curry._1(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
  if (projectId.TAG === "Ok") {
    var projectName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "projectName"), null));
    if (projectName.TAG === "Ok") {
      var simulation = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "simulation"), null));
      if (simulation.TAG === "Ok") {
        var at = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "at"), null));
        if (at.TAG === "Ok") {
          var deliveryDate = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryDate"), null));
          if (deliveryDate.TAG === "Ok") {
            var during = Spice.optionFromJson(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "during"), null));
            if (during.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        projectId: projectId._0,
                        projectName: projectName._0,
                        simulation: simulation._0,
                        at: at._0,
                        deliveryDate: deliveryDate._0,
                        during: during._0
                      }
                    };
            }
            var e = during._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".during" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = deliveryDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".deliveryDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = at._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".at" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = simulation._0;
      return {
              TAG: "Error",
              _0: {
                path: ".simulation" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = projectName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".projectName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = projectId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".projectId" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$4(v) {
  return Spice.arrayToJson(trigger_encode, v);
}

function response_decode$4(v) {
  return Spice.arrayFromJson(trigger_decode, v);
}

function config$4(param) {
  var routePlanId = param[0];
  return {
          kyInstance: kyInstance$1,
          path: routePlanId + "/triggers/final",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(searchParams_encode({
                      deliveryDate: param[1]
                    }))
          },
          key: [
            keyBase,
            "GetTriggers",
            routePlanId
          ]
        };
}

var GetTriggers = {
  searchParams_encode: searchParams_encode,
  searchParams_decode: searchParams_decode,
  trigger_encode: trigger_encode,
  trigger_decode: trigger_decode,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  config: config$4
};

function argument_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "simulation",
                    false,
                    Spice.boolToJson(v.simulation)
                  ],
                  [
                    "activeAtDate",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.activeAtDate)
                  ]
                ]));
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var simulation = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "simulation"), null));
  if (simulation.TAG === "Ok") {
    var activeAtDate = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "activeAtDate"), null));
    if (activeAtDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                simulation: simulation._0,
                activeAtDate: activeAtDate._0
              }
            };
    }
    var e = activeAtDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".activeAtDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = simulation._0;
  return {
          TAG: "Error",
          _0: {
            path: ".simulation" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function activeProject_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var prefix = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "prefix"), null));
      if (prefix.TAG === "Ok") {
        var activationStart = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "activationStart"), null));
        if (activationStart.TAG === "Ok") {
          var activationEnd = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "activationEnd"), null));
          if (activationEnd.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      name: name._0,
                      prefix: prefix._0,
                      activationStart: activationStart._0,
                      activationEnd: activationEnd._0
                    }
                  };
          }
          var e = activationEnd._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".activationEnd" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = activationStart._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".activationStart" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = prefix._0;
      return {
              TAG: "Error",
              _0: {
                path: ".prefix" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function activeProject_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProjectId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "prefix",
                    false,
                    Spice.stringToJson(v.prefix)
                  ],
                  [
                    "activationStart",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.activationStart)
                  ],
                  [
                    "activationEnd",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.activationEnd)
                  ]
                ]));
}

function response_encode$5(v) {
  return Spice.arrayToJson(activeProject_encode, v);
}

function response_decode$5(v) {
  return Spice.arrayFromJson(activeProject_decode, v);
}

function config$5(params) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$2(params))
          },
          key: [
            keyBase,
            "GetAllActiveProjects",
            JSON.stringify(argument_encode$2(params))
          ]
        };
}

var GetAllActive = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  activeProject_encode: activeProject_encode,
  activeProject_decode: activeProject_decode,
  config: config$5
};

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "skippedCost",
                    false,
                    encoder(v.skippedCost)
                  ]]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var skippedCost = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "skippedCost"), null));
  if (skippedCost.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              skippedCost: skippedCost._0
            }
          };
  }
  var e = skippedCost._0;
  return {
          TAG: "Error",
          _0: {
            path: ".skippedCost" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$6 = Spice.unitToJson;

var response_decode$6 = Spice.unitFromJson;

function config$6(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[2] + "/clients/" + param[0] + "/stores/" + param[1],
          requestOptions: {
            method: "POST",
            json: body_encode$2(param[3])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode$2), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Add = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  config: config$6
};

var response_encode$7 = Spice.unitToJson;

var response_decode$7 = Spice.unitFromJson;

function config$7(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[2] + "/clients/" + param[0] + "/stores/" + param[1],
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, ColiswebApi__V6_Utils.GenericError.t_decode), (function (errorBody) {
                              if (errorBody.TAG !== "Ok") {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              var match = errorBody._0.code;
                              if (match === "SELECTION_CODE_IN_SPECIFIC_SELECTION_CODE") {
                                return {
                                        TAG: "Custom",
                                        _0: "foundInSelection"
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Delete = {
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$7
};

var SelectionForStore = {
  Add: Add,
  Delete: Delete
};

var kyInstance$2 = kyInstance$1.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external/projects/stores"
    });

var argument_encode$3 = Spice.unitToJson;

var argument_decode$3 = Spice.unitFromJson;

function activeProject_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "projectId",
                    false,
                    Curry._1(Identifiers.ProjectId.t_encode, v.projectId)
                  ],
                  [
                    "projectName",
                    false,
                    Spice.stringToJson(v.projectName)
                  ],
                  [
                    "storesIds",
                    false,
                    Spice.arrayToJson(Identifiers.StoreId.t_encode, v.storesIds)
                  ]
                ]));
}

function activeProject_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var projectId = Curry._1(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
  if (projectId.TAG === "Ok") {
    var projectName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "projectName"), null));
    if (projectName.TAG === "Ok") {
      var storesIds = Spice.arrayFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storesIds"), null));
      if (storesIds.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  projectId: projectId._0,
                  projectName: projectName._0,
                  storesIds: storesIds._0
                }
              };
      }
      var e = storesIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storesIds" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = projectName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".projectName" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = projectId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".projectId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "projects",
                    false,
                    Spice.arrayToJson(activeProject_encode$1, v.projects)
                  ]]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var projects = Spice.arrayFromJson(activeProject_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "projects"), null));
  if (projects.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              projects: projects._0
            }
          };
  }
  var e = projects._0;
  return {
          TAG: "Error",
          _0: {
            path: ".projects" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$8(param) {
  return {
          kyInstance: kyInstance$2,
          path: "",
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetAllActiveProjectsActivatedStores"
          ]
        };
}

var GetAllActive$1 = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  activeProject_encode: activeProject_encode$1,
  activeProject_decode: activeProject_decode$1,
  config: config$8
};

var Stores = {
  kyInstance: kyInstance$2,
  GetAllActive: GetAllActive$1
};

var RouteProjects = {
  kyInstance: kyInstance$1,
  GetAll: GetAll,
  GetDetails: GetDetails,
  Update: Update,
  Create: Create,
  GetTriggers: GetTriggers,
  GetAllActive: GetAllActive,
  SelectionForStore: SelectionForStore,
  Stores: Stores
};

var kyInstance$3 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external/tenants"
    });

function skill_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "displayName",
                    false,
                    Spice.stringToJson(v.displayName)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ]
                ]));
}

function skill_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    var displayName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "displayName"), null));
    if (displayName.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  tag: tag._0,
                  displayName: displayName._0,
                  description: description._0
                }
              };
      }
      var e = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = displayName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".displayName" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$9(v) {
  return Spice.arrayToJson(skill_encode$1, v);
}

function response_decode$9(v) {
  return Spice.arrayFromJson(skill_decode$1, v);
}

function config$9(param) {
  return {
          kyInstance: kyInstance$3,
          path: "colisweb/skills",
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetSkills"
          ]
        };
}

var GetSkills = {
  skill_encode: skill_encode$1,
  skill_decode: skill_decode$1,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  config: config$9
};

var Tenants = {
  kyInstance: kyInstance$3,
  GetSkills: GetSkills
};

function response_encode$10(v) {
  return Spice.arrayToJson(routePlan_encode$1, v);
}

function response_decode$10(v) {
  return Spice.arrayFromJson(routePlan_decode$1, v);
}

function config$10(param) {
  return {
          kyInstance: kyInstance,
          path: "projectsDetailed",
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetFullRouteProjectsList"
          ],
          mapRawResponse: (function (result) {
              return $$Promise.resolved($$Request.decodeRawResponse(result, (function (json) {
                                var arr = Js_json.decodeArray(json);
                                if (arr !== undefined) {
                                  return Js_array.reducei((function (acc, jsonI, i) {
                                                var match = routePlan_decode$1(jsonI);
                                                if (acc.TAG !== "Ok") {
                                                  return acc;
                                                }
                                                if (match.TAG === "Ok") {
                                                  return {
                                                          TAG: "Ok",
                                                          _0: Js_array.concat([match._0], acc._0)
                                                        };
                                                }
                                                var error = match._0;
                                                var path = error.path;
                                                var id = jsonI.id;
                                                var name = jsonI.name;
                                                var message = !(id == null) && !(name == null) ? "[routeplanlist decode error] plan with id: " + id + " (name: " + name + ") at " + path + " " + error.message : error.message;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: "[" + String(i) + "]" + path,
                                                          message: message,
                                                          value: error.value
                                                        }
                                                      };
                                              }), {
                                              TAG: "Ok",
                                              _0: []
                                            }, arr);
                                } else {
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: "",
                                            message: "Not an array",
                                            value: json
                                          }
                                        };
                                }
                              })));
            })
        };
}

var GetFullRouteProjectsList = {
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  config: config$10
};

function project_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "projectId",
                    false,
                    Curry._1(Identifiers.ProjectId.t_encode, v.projectId)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "skippedCost",
                    false,
                    encoder(v.skippedCost)
                  ]
                ]));
}

function project_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
  if (storeId.TAG === "Ok") {
    var projectId = Curry._1(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
    if (projectId.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var skippedCost = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "skippedCost"), null));
        if (skippedCost.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    storeId: storeId._0,
                    projectId: projectId._0,
                    name: name._0,
                    skippedCost: skippedCost._0
                  }
                };
        }
        var e = skippedCost._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".skippedCost" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = projectId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".projectId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = storeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var decodedResponse_encode = project_encode;

var decodedResponse_decode = project_decode;

function response_encode$11(v) {
  return Spice.optionToJson(project_encode, v);
}

function response_decode$11(v) {
  return Spice.optionFromJson(project_decode, v);
}

function config$11(storeId) {
  return {
          kyInstance: kyInstance,
          path: "projectForStore/" + storeId,
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetProjectForStore",
            storeId
          ],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode$2), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            }),
          mapRawResponse: (function (response) {
              var data = $$Request.decodeRawResponse(response, decodedResponse_decode);
              var tmp;
              if (data.TAG === "Ok") {
                tmp = {
                  TAG: "Ok",
                  _0: data._0
                };
              } else {
                var match = data._0;
                switch (match.TAG) {
                  case "Unknown" :
                      var match$1 = match._0.response;
                      tmp = match$1 !== undefined && match$1.status === 404 ? ({
                            TAG: "Ok",
                            _0: undefined
                          }) : data;
                      break;
                  case "DecodeError" :
                  case "Custom" :
                      tmp = data;
                      break;
                  
                }
              }
              return $$Promise.resolved(tmp);
            })
        };
}

var GetProjectForStore = {
  project_encode: project_encode,
  project_decode: project_decode,
  decodedResponse_encode: decodedResponse_encode,
  decodedResponse_decode: decodedResponse_decode,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$11
};

var kyInstance$4 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external/projectTriggers"
    });

function searchResult_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "enableHubTransportCapacityManagement",
                    false,
                    Spice.boolToJson(v.enableHubTransportCapacityManagement)
                  ]]));
}

function searchResult_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var enableHubTransportCapacityManagement = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "enableHubTransportCapacityManagement"), null));
  if (enableHubTransportCapacityManagement.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              enableHubTransportCapacityManagement: enableHubTransportCapacityManagement._0
            }
          };
  }
  var e = enableHubTransportCapacityManagement._0;
  return {
          TAG: "Error",
          _0: {
            path: ".enableHubTransportCapacityManagement" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "result",
                    false,
                    Spice.optionToJson(searchResult_encode, v.result)
                  ]]));
}

function response_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var result = Spice.optionFromJson(searchResult_decode, Belt_Option.getWithDefault(Js_dict.get(v, "result"), null));
  if (result.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              result: result._0
            }
          };
  }
  var e = result._0;
  return {
          TAG: "Error",
          _0: {
            path: ".result" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$12(body) {
  return {
          kyInstance: kyInstance$4,
          path: "find",
          requestOptions: {
            method: "POST",
            json: ColiswebApi__V6_Utils.Eligible.encode(body)
          },
          key: [
            "ProjectTriggersFind",
            JSON.stringify(ColiswebApi__V6_Utils.Eligible.encode(body))
          ]
        };
}

var Find = {
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  config: config$12
};

var ProjectTriggers = {
  kyInstance: kyInstance$4,
  Find: Find
};

var kyInstance$5 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/config/external/vehicleFormats"
    });

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "width",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_encode, v.height)
                  ],
                  [
                    "length",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_encode, v.length)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var width = Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
    if (width.TAG === "Ok") {
      var height = Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
      if (height.TAG === "Ok") {
        var length = Curry._1(Decoders__UnitMeasure.Dimension.Cm.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
        if (length.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    name: name._0,
                    width: width._0,
                    height: height._0,
                    length: length._0
                  }
                };
        }
        var e = length._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".length" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = height._0;
      return {
              TAG: "Error",
              _0: {
                path: ".height" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = width._0;
    return {
            TAG: "Error",
            _0: {
              path: ".width" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var argument_encode$4 = body_encode$3;

var argument_decode$4 = body_decode$3;

var response_encode$13 = Spice.unitToJson;

var response_decode$13 = Spice.unitFromJson;

function config$13(body) {
  return {
          kyInstance: kyInstance$5,
          path: "",
          requestOptions: {
            method: "POST",
            json: body_encode$3(body)
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, ColiswebApi__V6_Utils.GenericError.t_decode), (function (errorBody) {
                              if (errorBody.TAG !== "Ok") {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              var match = errorBody._0.code;
                              if (match === undefined) {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              switch (match) {
                                case "DIMENSIONS_ALREADY_USED" :
                                    return {
                                            TAG: "Custom",
                                            _0: "DimensionsAlreadyUsed"
                                          };
                                case "NAME_ALREADY_USED" :
                                    return {
                                            TAG: "Custom",
                                            _0: "NameAlreadyUsed"
                                          };
                                default:
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Create$1 = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  argument_encode: argument_encode$4,
  argument_decode: argument_decode$4,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  config: config$13
};

function argument_encode$5(v) {
  return [
          Curry._1(Identifiers.VehicleFormatId.t_encode, v[0]),
          body_encode$3(v[1])
        ];
}

function argument_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.VehicleFormatId.t_decode, v0);
  var match$1 = body_decode$3(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$14 = Spice.unitToJson;

var response_decode$14 = Spice.unitFromJson;

function config$14(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode$3(param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, ColiswebApi__V6_Utils.GenericError.t_decode), (function (errorBody) {
                              if (errorBody.TAG !== "Ok") {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              var match = errorBody._0.code;
                              if (match === undefined) {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              switch (match) {
                                case "DIMENSIONS_ALREADY_USED" :
                                    return {
                                            TAG: "Custom",
                                            _0: "DimensionsAlreadyUsed"
                                          };
                                case "NAME_ALREADY_USED" :
                                    return {
                                            TAG: "Custom",
                                            _0: "NameAlreadyUsed"
                                          };
                                default:
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Update$1 = {
  argument_encode: argument_encode$5,
  argument_decode: argument_decode$5,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  config: config$14
};

var response_encode$15 = Spice.unitToJson;

var response_decode$15 = Spice.unitFromJson;

function config$15(vehicleFormatId) {
  return {
          kyInstance: kyInstance$5,
          path: vehicleFormatId,
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, ColiswebApi__V6_Utils.GenericError.t_decode), (function (errorBody) {
                              if (errorBody.TAG !== "Ok") {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              var match = errorBody._0.code;
                              if (match === "FORMAT_STILL_USED") {
                                return {
                                        TAG: "Custom",
                                        _0: "FormatStillUsed"
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Delete$1 = {
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  config: config$15
};

function vehicleFormat_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.VehicleFormatId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "width",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ],
                  [
                    "length",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "volume",
                    false,
                    Curry._1(Decoders__UnitMeasure.Volume.WithUnit.t_encode, v.volume)
                  ]
                ]));
}

function vehicleFormat_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.VehicleFormatId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var width = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
      if (width.TAG === "Ok") {
        var height = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
        if (height.TAG === "Ok") {
          var length = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
          if (length.TAG === "Ok") {
            var volume = Curry._1(Decoders__UnitMeasure.Volume.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "volume"), null));
            if (volume.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        id: id._0,
                        name: name._0,
                        width: width._0,
                        height: height._0,
                        length: length._0,
                        volume: volume._0
                      }
                    };
            }
            var e = volume._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".volume" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = length._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".length" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = height._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".height" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = width._0;
      return {
              TAG: "Error",
              _0: {
                path: ".width" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "formats",
                    false,
                    Spice.arrayToJson(vehicleFormat_encode, v.formats)
                  ]]));
}

function response_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var formats = Spice.arrayFromJson(vehicleFormat_decode, Belt_Option.getWithDefault(Js_dict.get(v, "formats"), null));
  if (formats.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              formats: formats._0
            }
          };
  }
  var e = formats._0;
  return {
          TAG: "Error",
          _0: {
            path: ".formats" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$16(param) {
  return {
          kyInstance: kyInstance$5,
          path: "",
          requestOptions: {
            method: "GET"
          },
          key: ["VehicleFormatsGetAll"]
        };
}

var GetAll$1 = {
  vehicleFormat_encode: vehicleFormat_encode,
  vehicleFormat_decode: vehicleFormat_decode,
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  config: config$16
};

function vehicle_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "projectId",
                    false,
                    Curry._1(Identifiers.ProjectId.t_encode, v.projectId)
                  ],
                  [
                    "projectName",
                    false,
                    Spice.stringToJson(v.projectName)
                  ],
                  [
                    "packetsConstraint",
                    false,
                    Spice.optionToJson(t_encode$5, v.packetsConstraint)
                  ],
                  [
                    "useCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_encode, v.useCost)
                  ],
                  [
                    "additionalKilometricCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerDistance.WithUnit.t_encode, v.additionalKilometricCost)
                  ],
                  [
                    "additionalHourlyCost",
                    false,
                    Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerTime.WithUnit.t_encode, v.additionalHourlyCost)
                  ],
                  [
                    "maximumDistance",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.maximumDistance)
                  ],
                  [
                    "maximumWorkTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.maximumWorkTime)
                  ],
                  [
                    "maxWeight",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.maxWeight)
                  ],
                  [
                    "paidDistance",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.paidDistance)
                  ],
                  [
                    "paidWorkTime",
                    false,
                    Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_encode, v.paidWorkTime)
                  ]
                ]));
}

function vehicle_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var projectId = Curry._1(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
  if (projectId.TAG === "Ok") {
    var projectName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "projectName"), null));
    if (projectName.TAG === "Ok") {
      var packetsConstraint = Spice.optionFromJson(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "packetsConstraint"), null));
      if (packetsConstraint.TAG === "Ok") {
        var useCost = Curry._1(Decoders__UnitMeasure.Currency.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "useCost"), null));
        if (useCost.TAG === "Ok") {
          var additionalKilometricCost = Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerDistance.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalKilometricCost"), null));
          if (additionalKilometricCost.TAG === "Ok") {
            var additionalHourlyCost = Curry._1(Decoders__UnitMeasure.CompositeUnits.CurrencyPerTime.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalHourlyCost"), null));
            if (additionalHourlyCost.TAG === "Ok") {
              var maximumDistance = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maximumDistance"), null));
              if (maximumDistance.TAG === "Ok") {
                var maximumWorkTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maximumWorkTime"), null));
                if (maximumWorkTime.TAG === "Ok") {
                  var maxWeight = Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxWeight"), null));
                  if (maxWeight.TAG === "Ok") {
                    var paidDistance = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "paidDistance"), null));
                    if (paidDistance.TAG === "Ok") {
                      var paidWorkTime = Curry._1(Decoders__UnitMeasure.Time.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "paidWorkTime"), null));
                      if (paidWorkTime.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  projectId: projectId._0,
                                  projectName: projectName._0,
                                  packetsConstraint: packetsConstraint._0,
                                  useCost: useCost._0,
                                  additionalKilometricCost: additionalKilometricCost._0,
                                  additionalHourlyCost: additionalHourlyCost._0,
                                  maximumDistance: maximumDistance._0,
                                  maximumWorkTime: maximumWorkTime._0,
                                  maxWeight: maxWeight._0,
                                  paidDistance: paidDistance._0,
                                  paidWorkTime: paidWorkTime._0
                                }
                              };
                      }
                      var e = paidWorkTime._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".paidWorkTime" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = paidDistance._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".paidDistance" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = maxWeight._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".maxWeight" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = maximumWorkTime._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".maximumWorkTime" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = maximumDistance._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".maximumDistance" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = additionalHourlyCost._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".additionalHourlyCost" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = additionalKilometricCost._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".additionalKilometricCost" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = useCost._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".useCost" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = packetsConstraint._0;
      return {
              TAG: "Error",
              _0: {
                path: ".packetsConstraint" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = projectName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".projectName" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = projectId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".projectId" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.VehicleFormatId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "width",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ],
                  [
                    "length",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "volume",
                    false,
                    Curry._1(Decoders__UnitMeasure.Volume.WithUnit.t_encode, v.volume)
                  ],
                  [
                    "vehicles",
                    false,
                    Spice.arrayToJson(vehicle_encode, v.vehicles)
                  ]
                ]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.VehicleFormatId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var width = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
      if (width.TAG === "Ok") {
        var height = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
        if (height.TAG === "Ok") {
          var length = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
          if (length.TAG === "Ok") {
            var volume = Curry._1(Decoders__UnitMeasure.Volume.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "volume"), null));
            if (volume.TAG === "Ok") {
              var vehicles = Spice.arrayFromJson(vehicle_decode, Belt_Option.getWithDefault(Js_dict.get(v, "vehicles"), null));
              if (vehicles.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          id: id._0,
                          name: name._0,
                          width: width._0,
                          height: height._0,
                          length: length._0,
                          volume: volume._0,
                          vehicles: vehicles._0
                        }
                      };
              }
              var e = vehicles._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".vehicles" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = volume._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".volume" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = length._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".length" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = height._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".height" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = width._0;
      return {
              TAG: "Error",
              _0: {
                path: ".width" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function config$17(vehicleFormatId) {
  return {
          kyInstance: kyInstance$5,
          path: vehicleFormatId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "GetVehicleFormat",
            vehicleFormatId
          ]
        };
}

var Get = {
  vehicle_encode: vehicle_encode,
  vehicle_decode: vehicle_decode,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  config: config$17
};

var VehicleFormats = {
  kyInstance: kyInstance$5,
  Create: Create$1,
  Update: Update$1,
  Delete: Delete$1,
  GetAll: GetAll$1,
  Get: Get
};

var Decoders;

export {
  Decoders ,
  kyInstance ,
  keyBase ,
  Shared ,
  RouteProjects ,
  Tenants ,
  GetFullRouteProjectsList ,
  GetProjectForStore ,
  ProjectTriggers ,
  VehicleFormats ,
}
/* kyInstance Not a pure module */
