

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Utils from "./ColiswebApi__V6_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

var prefixBaseUrl = ColiswebApi__Env.v6ApiUrl + "/client/external";

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function period_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.end)
                  ],
                  [
                    "endProposal",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.endProposal)
                  ]
                ]));
}

function period_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      var endProposal = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "endProposal"), null));
      if (endProposal.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  start: start._0,
                  end: end_._0,
                  endProposal: endProposal._0
                }
              };
      }
      var e = endProposal._0;
      return {
              TAG: "Error",
              _0: {
                path: ".endProposal" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e$1.path),
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function activation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "periods",
                    false,
                    Spice.arrayToJson(period_encode, v.periods)
                  ]
                ]));
}

function activation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      var periods = Spice.arrayFromJson(period_decode, Belt_Option.getWithDefault(Js_dict.get(v, "periods"), null));
      if (periods.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0,
                  periods: periods._0
                }
              };
      }
      var e = periods._0;
      return {
              TAG: "Error",
              _0: {
                path: ".periods" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function originatorIds_encode(v) {
  return Spice.arrayToJson((function (param) {
                return [
                        Curry._1(Identifiers.ClientId.t_encode, param[0]),
                        Spice.optionToJson(Identifiers.StoreId.t_encode, param[1])
                      ];
              }), v);
}

function originatorIds_decode(v) {
  return Spice.arrayFromJson((function (json) {
                if (!Array.isArray(json) && (json === null || typeof json !== "object") && typeof json !== "number" && typeof json !== "string" && typeof json !== "boolean") {
                  return Spice.error(undefined, "Not a tuple", json);
                }
                if (!Array.isArray(json)) {
                  return Spice.error(undefined, "Not a tuple", json);
                }
                if (json.length !== 2) {
                  return Spice.error(undefined, "Incorrect cardinality", json);
                }
                var v0 = json[0];
                var v1 = json[1];
                var match = Curry._1(Identifiers.ClientId.t_decode, v0);
                var match$1 = Spice.optionFromJson(Identifiers.StoreId.t_decode, v1);
                if (match.TAG === "Ok") {
                  if (match$1.TAG === "Ok") {
                    return {
                            TAG: "Ok",
                            _0: [
                              match._0,
                              match$1._0
                            ]
                          };
                  }
                  var e = match$1._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: "[1]" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = match._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: "[0]" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }), v);
}

function encoder(_v) {
  return Spice.stringToJson("");
}

function decoder(json) {
  var match = json.code;
  var value;
  if (match !== undefined) {
    switch (match) {
      case "ACCESS_REFUSED" :
          value = "accessRefused";
          break;
      case "COMPILE_ERROR" :
          value = {
            NAME: "compileError",
            VAL: json.compileError
          };
          break;
      case "OVERLAPPING_ACTIVATIONS" :
          value = {
            NAME: "overlappingActivations",
            VAL: Belt_Array.map(json.overlapped, (function (a) {
                    return Curry._1(Toolkit__Primitives_Result.Result.getExn, activation_decode(a));
                  }))
          };
          break;
      case "RULE_HAS_ACTIVATIONS" :
          value = {
            NAME: "ruleHasActivations",
            VAL: Curry._1(Toolkit__Primitives_Result.Result.getExn, originatorIds_decode(json.originatorsIds))
          };
          break;
      default:
        value = "unknown";
    }
  } else {
    value = "unknown";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec = [
  encoder,
  decoder
];

var t_encode = encoder;

var t_decode = decoder;

var $$Error = {
  period_encode: period_encode,
  period_decode: period_decode,
  activation_encode: activation_encode,
  activation_decode: activation_decode,
  originatorIds_encode: originatorIds_encode,
  originatorIds_decode: originatorIds_decode,
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode,
  t_decode: t_decode
};

function t_encode$1(v) {
  if (v === "clientPricing") {
    return "clientPricing";
  } else {
    return "customerPricing";
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("clientPricing" === v) {
          return {
                  TAG: "Ok",
                  _0: "clientPricing"
                };
        } else if ("customerPricing" === v) {
          return {
                  TAG: "Ok",
                  _0: "customerPricing"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var RuleType = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function area_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "countryCode",
                    false,
                    Curry._1(Identifiers.CountryCode.t_encode, v.countryCode)
                  ],
                  [
                    "level1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.level1)
                  ],
                  [
                    "level2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.level2)
                  ]
                ]));
}

function area_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var countryCode = Curry._1(Identifiers.CountryCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "countryCode"), null));
  if (countryCode.TAG === "Ok") {
    var level1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "level1"), null));
    if (level1.TAG === "Ok") {
      var level2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "level2"), null));
      if (level2.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  countryCode: countryCode._0,
                  level1: level1._0,
                  level2: level2._0
                }
              };
      }
      var e = level2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level2" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = level1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".level1" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = countryCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".countryCode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode$2(v) {
  switch (v) {
    case "allowed" :
        return "allowed";
    case "forbidden" :
        return "forbidden";
    case "doNotApply" :
        return "doNotApply";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("allowed" === v) {
          return {
                  TAG: "Ok",
                  _0: "allowed"
                };
        } else if ("forbidden" === v) {
          return {
                  TAG: "Ok",
                  _0: "forbidden"
                };
        } else if ("doNotApply" === v) {
          return {
                  TAG: "Ok",
                  _0: "doNotApply"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var allowed = {
  id: "_2f9552bf",
  defaultMessage: "Autoriser"
};

var forbidden = {
  id: "_b87b9054",
  defaultMessage: "Ne pas autoriser"
};

var doNotApply = {
  id: "_27b5ec45",
  defaultMessage: "Ignorer"
};

function humanize(intl, t) {
  var message;
  switch (t) {
    case "allowed" :
        message = allowed;
        break;
    case "forbidden" :
        message = forbidden;
        break;
    case "doNotApply" :
        message = doNotApply;
        break;
    
  }
  return intl.formatMessage(message);
}

var PartialDeliveriesFilter = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  allowed: allowed,
  forbidden: forbidden,
  doNotApply: doNotApply,
  humanize: humanize
};

var Shared = {
  $$Error: $$Error,
  RuleType: RuleType,
  area_encode: area_encode,
  area_decode: area_decode,
  PartialDeliveriesFilter: PartialDeliveriesFilter
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/rules"
    });

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
  if (ruleType.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              ruleType: ruleType._0
            }
          };
  }
  var e = ruleType._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleType" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function grid_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "isActive",
                    false,
                    Spice.boolToJson(v.isActive)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ],
                  [
                    "lastModification",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.lastModification)
                  ]
                ]));
}

function grid_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
    if (ruleId.TAG === "Ok") {
      var isActive = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isActive"), null));
      if (isActive.TAG === "Ok") {
        var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
        if (ruleType.TAG === "Ok") {
          var lastModification = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lastModification"), null));
          if (lastModification.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      title: title._0,
                      ruleId: ruleId._0,
                      isActive: isActive._0,
                      ruleType: ruleType._0,
                      lastModification: lastModification._0
                    }
                  };
          }
          var e = lastModification._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastModification" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = ruleType._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ruleType" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = isActive._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isActive" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = ruleId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode(v) {
  return Spice.arrayToJson(grid_encode, v);
}

function response_decode(v) {
  return Spice.arrayFromJson(grid_decode, v);
}

function config(params) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode(params))
          },
          key: [
            "ColiswebApi__V6_Client",
            "RulesGetList",
            JSON.stringify(params_encode(params))
          ]
        };
}

var GetList = {
  params_encode: params_encode,
  params_decode: params_decode,
  grid_encode: grid_encode,
  grid_decode: grid_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  config: config
};

function activation_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "period",
                    false,
                    ColiswebApi__V6_Utils.Period.t_encode(v.period)
                  ]
                ]));
}

function activation_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var period = ColiswebApi__V6_Utils.Period.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
      if (period.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clientId: clientId._0,
                  storeId: storeId._0,
                  period: period._0
                }
              };
      }
      var e = period._0;
      return {
              TAG: "Error",
              _0: {
                path: ".period" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "activations",
                    false,
                    Spice.arrayToJson(activation_encode$1, v.activations)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      var activations = Spice.arrayFromJson(activation_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "activations"), null));
      if (activations.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  title: title._0,
                  definition: definition._0,
                  activations: activations._0
                }
              };
      }
      var e = activations._0;
      return {
              TAG: "Error",
              _0: {
                path: ".activations" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function params_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "withSamples",
                    false,
                    Spice.boolToJson(v.withSamples)
                  ]]));
}

function params_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var withSamples = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "withSamples"), null));
  if (withSamples.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              withSamples: withSamples._0
            }
          };
  }
  var e = withSamples._0;
  return {
          TAG: "Error",
          _0: {
            path: ".withSamples" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$1(ruleId) {
  return {
          kyInstance: kyInstance$1,
          path: ruleId,
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$1({
                      withSamples: false
                    }))
          },
          key: [
            "ColiswebApi__V6_Client",
            "RulesGetDetails",
            ruleId
          ]
        };
}

var GetDetails = {
  activation_encode: activation_encode$1,
  activation_decode: activation_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  params_encode: params_encode$1,
  params_decode: params_decode$1,
  config: config$1
};

function activation_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "period",
                    false,
                    ColiswebApi__V6_Utils.Period.t_encode(v.period)
                  ]
                ]));
}

function activation_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var period = ColiswebApi__V6_Utils.Period.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
      if (period.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clientId: clientId._0,
                  storeId: storeId._0,
                  period: period._0
                }
              };
      }
      var e = period._0;
      return {
              TAG: "Error",
              _0: {
                path: ".period" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "activations",
                    false,
                    Spice.arrayToJson(activation_encode$2, v.activations)
                  ],
                  [
                    "samples",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.samples)
                  ]
                ]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
    if (title.TAG === "Ok") {
      var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
      if (definition.TAG === "Ok") {
        var activations = Spice.arrayFromJson(activation_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "activations"), null));
        if (activations.TAG === "Ok") {
          var samples = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "samples"), null));
          if (samples.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      ruleId: ruleId._0,
                      title: title._0,
                      definition: definition._0,
                      activations: activations._0,
                      samples: samples._0
                    }
                  };
          }
          var e = samples._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".samples" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = activations._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".activations" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = definition._0;
      return {
              TAG: "Error",
              _0: {
                path: ".definition" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = title._0;
    return {
            TAG: "Error",
            _0: {
              path: ".title" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function params_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "withSamples",
                    false,
                    Spice.boolToJson(v.withSamples)
                  ]]));
}

function params_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var withSamples = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "withSamples"), null));
  if (withSamples.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              withSamples: withSamples._0
            }
          };
  }
  var e = withSamples._0;
  return {
          TAG: "Error",
          _0: {
            path: ".withSamples" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$2(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[0],
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$2({
                      withSamples: param[1]
                    }))
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var GetDetailsWithSample = {
  activation_encode: activation_encode$2,
  activation_decode: activation_decode$2,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  params_encode: params_encode$2,
  params_decode: params_decode$2,
  config: config$2
};

function argument_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
      if (ruleType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  title: title._0,
                  definition: definition._0,
                  ruleType: ruleType._0
                }
              };
      }
      var e = ruleType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$3(v) {
  return Curry._1(Identifiers.ClientRuleId.t_encode, v);
}

function response_decode$3(v) {
  return Curry._1(Identifiers.ClientRuleId.t_decode, v);
}

function config$3(body) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "POST",
            json: argument_encode(body)
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var Create = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  config: config$3
};

function argument_encode$1(v) {
  return Curry._1(Identifiers.ClientRuleId.t_encode, v);
}

function argument_decode$1(v) {
  return Curry._1(Identifiers.ClientRuleId.t_decode, v);
}

var response_encode$4 = Spice.unitToJson;

var response_decode$4 = Spice.unitFromJson;

function config$4(ruleId) {
  return {
          kyInstance: kyInstance$1,
          path: ruleId,
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var Delete = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  config: config$4
};

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
      if (ruleType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  title: title._0,
                  definition: definition._0,
                  ruleType: ruleType._0
                }
              };
      }
      var e = ruleType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.ClientRuleId.t_encode, v[0]),
          body_encode(v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientRuleId.t_decode, v0);
  var match$1 = body_decode(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$5 = Spice.unitToJson;

var response_decode$5 = Spice.unitFromJson;

function config$5(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var Update = {
  body_encode: body_encode,
  body_decode: body_decode,
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  config: config$5
};

var Rules = {
  kyInstance: kyInstance$1,
  GetList: GetList,
  GetDetails: GetDetails,
  GetDetailsWithSample: GetDetailsWithSample,
  Create: Create,
  Delete: Delete,
  Update: Update
};

var kyInstance$2 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/activations"
    });

function argument_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientsIds",
                    false,
                    Toolkit__Decoders.StringArray.t_encode(Identifiers.ClientId.t_encode, v.clientsIds)
                  ],
                  [
                    "storesIds",
                    false,
                    Toolkit__Decoders.StringArray.t_encode(Identifiers.StoreId.t_encode, v.storesIds)
                  ],
                  [
                    "date",
                    true,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.date)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function argument_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientsIds = Toolkit__Decoders.StringArray.t_decode(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientsIds"), null));
  if (clientsIds.TAG === "Ok") {
    var storesIds = Toolkit__Decoders.StringArray.t_decode(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storesIds"), null));
    if (storesIds.TAG === "Ok") {
      var date = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
      if (date.TAG === "Ok") {
        var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
        if (ruleType.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    clientsIds: clientsIds._0,
                    storesIds: storesIds._0,
                    date: date._0,
                    ruleType: ruleType._0
                  }
                };
        }
        var e = ruleType._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ruleType" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = date._0;
      return {
              TAG: "Error",
              _0: {
                path: ".date" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storesIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storesIds" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = clientsIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientsIds" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function activation_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleDefinition",
                    false,
                    Spice.stringToJson(v.ruleDefinition)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "period",
                    false,
                    ColiswebApi__V6_Utils.Period.t_encode(v.period)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function activation_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleDefinition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleDefinition"), null));
    if (ruleDefinition.TAG === "Ok") {
      var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
      if (ruleTitle.TAG === "Ok") {
        var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
        if (clientId.TAG === "Ok") {
          var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
          if (storeId.TAG === "Ok") {
            var period = ColiswebApi__V6_Utils.Period.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
            if (period.TAG === "Ok") {
              var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
              if (ruleType.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          ruleId: ruleId._0,
                          ruleDefinition: ruleDefinition._0,
                          ruleTitle: ruleTitle._0,
                          clientId: clientId._0,
                          storeId: storeId._0,
                          period: period._0,
                          ruleType: ruleType._0
                        }
                      };
              }
              var e = ruleType._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".ruleType" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = period._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".period" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = storeId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".storeId" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = clientId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".clientId" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = ruleTitle._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleTitle" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = ruleDefinition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleDefinition" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$6(v) {
  return Spice.arrayToJson(activation_encode$3, v);
}

function response_decode$6(v) {
  return Spice.arrayFromJson(activation_decode$3, v);
}

function config$6(argument) {
  return {
          kyInstance: kyInstance$2,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$3(argument))
          },
          key: [
            "ColiswebApi__V6_Client",
            "ActivationsGetAll",
            JSON.stringify(argument_encode$3(argument))
          ]
        };
}

var GetAll = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  activation_encode: activation_encode$3,
  activation_decode: activation_decode$3,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  config: config$6
};

function activation_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "period",
                    false,
                    Spice.optionToJson(ColiswebApi__V6_Utils.Period.t_encode, v.period)
                  ],
                  [
                    "applyPossibleModifications",
                    false,
                    Spice.boolToJson(v.applyPossibleModifications)
                  ]
                ]));
}

function activation_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var period = Spice.optionFromJson(ColiswebApi__V6_Utils.Period.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
      if (period.TAG === "Ok") {
        var applyPossibleModifications = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "applyPossibleModifications"), null));
        if (applyPossibleModifications.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    clientId: clientId._0,
                    storeId: storeId._0,
                    period: period._0,
                    applyPossibleModifications: applyPossibleModifications._0
                  }
                };
        }
        var e = applyPossibleModifications._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".applyPossibleModifications" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = period._0;
      return {
              TAG: "Error",
              _0: {
                path: ".period" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function body_encode$1(v) {
  return Spice.arrayToJson(activation_encode$4, v);
}

function body_decode$1(v) {
  return Spice.arrayFromJson(activation_decode$4, v);
}

var response_encode$7 = Spice.unitToJson;

var response_decode$7 = Spice.unitFromJson;

function config$7(param) {
  return {
          kyInstance: kyInstance$2,
          path: "rules/" + param[0],
          requestOptions: {
            method: "POST",
            json: Spice.arrayToJson(activation_encode$4, param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var UpdatePriceGrids = {
  activation_encode: activation_encode$4,
  activation_decode: activation_decode$4,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$7
};

var Activations = {
  kyInstance: kyInstance$2,
  GetAll: GetAll,
  UpdatePriceGrids: UpdatePriceGrids
};

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
  if (definition.TAG === "Ok") {
    var ruleType = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "ruleType"), null));
    if (ruleType.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                definition: definition._0,
                ruleType: ruleType._0
              }
            };
    }
    var e = ruleType._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleType" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = definition._0;
  return {
          TAG: "Error",
          _0: {
            path: ".definition" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "compile",
                    false,
                    Spice.boolToJson(v.compile)
                  ],
                  [
                    "error",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.error)
                  ]
                ]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var compile = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "compile"), null));
  if (compile.TAG === "Ok") {
    var error = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
    if (error.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                compile: compile._0,
                error: error._0
              }
            };
    }
    var e = error._0;
    return {
            TAG: "Error",
            _0: {
              path: ".error" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = compile._0;
  return {
          TAG: "Error",
          _0: {
            path: ".compile" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$8(body) {
  return {
          kyInstance: kyInstance,
          path: "testRule",
          requestOptions: {
            method: "POST",
            json: body_encode$2(body)
          }
        };
}

var TestPriceDefinition = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  config: config$8
};

var kyInstance$3 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/hubs"
    });

function barcodePattern_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "regex",
                    false,
                    Spice.stringToJson(v.regex)
                  ]]));
}

function barcodePattern_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var regex = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "regex"), null));
  if (regex.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              regex: regex._0
            }
          };
  }
  var e = regex._0;
  return {
          TAG: "Error",
          _0: {
            path: ".regex" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcodePatterns",
                    false,
                    Spice.arrayToJson(barcodePattern_encode, v.barcodePatterns)
                  ]]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcodePatterns = Spice.arrayFromJson(barcodePattern_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcodePatterns"), null));
  if (barcodePatterns.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcodePatterns: barcodePatterns._0
            }
          };
  }
  var e = barcodePatterns._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcodePatterns" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$9(hubId) {
  return {
          kyInstance: kyInstance$3,
          path: hubId + "/barcodePatterns",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "HubsGetBarcodePatterns",
            hubId
          ]
        };
}

var GetBarcodePatterns = {
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  barcodePattern_encode: barcodePattern_encode,
  barcodePattern_decode: barcodePattern_decode,
  config: config$9
};

function countryContainer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var country = Curry._1(Identifiers.CountryCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
  if (country.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              country: country._0
            }
          };
  }
  var e = country._0;
  return {
          TAG: "Error",
          _0: {
            path: ".country" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function countryContainer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "country",
                    false,
                    Curry._1(Identifiers.CountryCode.t_encode, v.country)
                  ]]));
}

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "countries",
                    false,
                    Spice.arrayToJson(countryContainer_encode, v.countries)
                  ]]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var countries = Spice.arrayFromJson(countryContainer_decode, Belt_Option.getWithDefault(Js_dict.get(v, "countries"), null));
  if (countries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              countries: countries._0
            }
          };
  }
  var e = countries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".countries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$10(hubId) {
  return {
          kyInstance: kyInstance$3,
          path: hubId + "/countries",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "HubsGetCountries",
            hubId
          ]
        };
}

var GetCountries = {
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  countryContainer_encode: countryContainer_encode,
  countryContainer_decode: countryContainer_decode,
  config: config$10
};

var Hubs = {
  kyInstance: kyInstance$3,
  GetBarcodePatterns: GetBarcodePatterns,
  GetCountries: GetCountries
};

function warehouse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function warehouse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
  if (warehouseCode.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  warehouseCode: warehouseCode._0,
                  name: name._0,
                  clientId: clientId._0
                }
              };
      }
      var e = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = warehouseCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouseCode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "warehouses",
                    false,
                    Spice.arrayToJson(warehouse_encode, v.warehouses)
                  ]]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouses = Spice.arrayFromJson(warehouse_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouses"), null));
  if (warehouses.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              warehouses: warehouses._0
            }
          };
  }
  var e = warehouses._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouses" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$11(param) {
  return {
          kyInstance: kyInstance,
          path: "warehouses/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsWarehousesGetAll"
          ]
        };
}

var GetAll$1 = {
  warehouse_encode: warehouse_encode,
  warehouse_decode: warehouse_decode,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$11
};

var Warehouses = {
  GetAll: GetAll$1
};

function argument_encode$4(v) {
  return [
          Spice.optionToJson(Identifiers.ClientId.t_encode, v[0]),
          Spice.optionToJson(Identifiers.HubId.t_encode, v[1])
        ];
}

function argument_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Spice.optionFromJson(Identifiers.ClientId.t_decode, v0);
  var match$1 = Spice.optionFromJson(Identifiers.HubId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function element_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "areaId",
                    false,
                    Curry._1(Identifiers.AreaId.t_encode, v.areaId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "area",
                    false,
                    area_encode(v.area)
                  ]
                ]));
}

function element_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var areaId = Curry._1(Identifiers.AreaId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "areaId"), null));
  if (areaId.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
      if (hubId.TAG === "Ok") {
        var area = area_decode(Belt_Option.getWithDefault(Js_dict.get(v, "area"), null));
        if (area.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    areaId: areaId._0,
                    clientId: clientId._0,
                    hubId: hubId._0,
                    area: area._0
                  }
                };
        }
        var e = area._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".area" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = hubId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = areaId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".areaId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$12(v) {
  return Spice.arrayToJson(element_encode, v);
}

function response_decode$12(v) {
  return Spice.arrayFromJson(element_decode, v);
}

function params_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    true,
                    Spice.optionToJson(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubId",
                    true,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function params_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Spice.optionFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                clientId: clientId._0,
                hubId: hubId._0
              }
            };
    }
    var e = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var error_encode = Spice.unitToJson;

var error_decode = Spice.unitFromJson;

function config$12(param) {
  var hubId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "areas",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$3({
                      clientId: clientId,
                      hubId: hubId
                    }))
          },
          key: [
            "ColiswebApi__V6_Client",
            "AreasGetAreas",
            Belt_Option.getWithDefault(clientId, ""),
            Belt_Option.getWithDefault(hubId, "")
          ]
        };
}

var GetByHubId = {
  argument_encode: argument_encode$4,
  argument_decode: argument_decode$4,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  element_encode: element_encode,
  element_decode: element_decode,
  params_encode: params_encode$3,
  params_decode: params_decode$3,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config$12
};

var Areas = {
  GetByHubId: GetByHubId
};

var kyInstance$4 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/clients"
    });

function configuration_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "logoUrl",
                    false,
                    Spice.stringToJson(v.logoUrl)
                  ],
                  [
                    "primaryTextColor",
                    false,
                    Spice.stringToJson(v.primaryTextColor)
                  ],
                  [
                    "primaryCtaColor",
                    false,
                    Spice.stringToJson(v.primaryCtaColor)
                  ],
                  [
                    "primaryCtaTextColor",
                    false,
                    Spice.stringToJson(v.primaryCtaTextColor)
                  ]
                ]));
}

function configuration_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var logoUrl = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "logoUrl"), null));
  if (logoUrl.TAG === "Ok") {
    var primaryTextColor = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryTextColor"), null));
    if (primaryTextColor.TAG === "Ok") {
      var primaryCtaColor = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryCtaColor"), null));
      if (primaryCtaColor.TAG === "Ok") {
        var primaryCtaTextColor = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryCtaTextColor"), null));
        if (primaryCtaTextColor.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    logoUrl: logoUrl._0,
                    primaryTextColor: primaryTextColor._0,
                    primaryCtaColor: primaryCtaColor._0,
                    primaryCtaTextColor: primaryCtaTextColor._0
                  }
                };
        }
        var e = primaryCtaTextColor._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".primaryCtaTextColor" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = primaryCtaColor._0;
      return {
              TAG: "Error",
              _0: {
                path: ".primaryCtaColor" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = primaryTextColor._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryTextColor" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = logoUrl._0;
  return {
          TAG: "Error",
          _0: {
            path: ".logoUrl" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "configuration",
                    false,
                    Spice.optionToJson(configuration_encode, v.configuration)
                  ]]));
}

function response_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var configuration = Spice.optionFromJson(configuration_decode, Belt_Option.getWithDefault(Js_dict.get(v, "configuration"), null));
  if (configuration.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              configuration: configuration._0
            }
          };
  }
  var e = configuration._0;
  return {
          TAG: "Error",
          _0: {
            path: ".configuration" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$13(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/postPurchaseConfiguration",
          requestOptions: {
            method: "GET"
          },
          key: [
            "PostPurchaseConfigurationGet",
            clientId
          ]
        };
}

var Get = {
  configuration_encode: configuration_encode,
  configuration_decode: configuration_decode,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  config: config$13
};

var response_encode$14 = Spice.unitToJson;

var response_decode$14 = Spice.unitFromJson;

function config$14(param) {
  var body = param[1];
  var formData = new FormData();
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/postPurchaseConfiguration",
          requestOptions: {
            method: "POST",
            body: Caml_option.some((formData.append("logo", body.logo), formData.append("primaryTextColor", body.primaryTextColor), formData.append("primaryCtaColor", body.primaryCtaColor), formData.append("primaryCtaTextColor", body.primaryCtaTextColor), formData))
          }
        };
}

var Create$1 = {
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  config: config$14
};

var response_encode$15 = Spice.unitToJson;

var response_decode$15 = Spice.unitFromJson;

function config$15(param) {
  var body = param[1];
  var formData = new FormData();
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/postPurchaseConfiguration",
          requestOptions: {
            method: "PUT",
            body: Caml_option.some((Belt_Option.forEach(body.logo, (function (logo) {
                          formData.append("logo", logo);
                        })), formData.append("primaryTextColor", body.primaryTextColor), formData.append("primaryCtaColor", body.primaryCtaColor), formData.append("primaryCtaTextColor", body.primaryCtaTextColor), formData))
          }
        };
}

var Update$1 = {
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  config: config$15
};

var response_encode$16 = Spice.unitToJson;

var response_decode$16 = Spice.unitFromJson;

function config$16(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/postPurchaseConfiguration",
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$1 = {
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  config: config$16
};

var PostPurchaseConfiguration = {
  Get: Get,
  Create: Create$1,
  Update: Update$1,
  Delete: Delete$1
};

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "area",
                    false,
                    area_encode(v.area)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    var area = area_decode(Belt_Option.getWithDefault(Js_dict.get(v, "area"), null));
    if (area.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                hubId: hubId._0,
                area: area._0
              }
            };
    }
    var e = area._0;
    return {
            TAG: "Error",
            _0: {
              path: ".area" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$5(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          body_encode$3(v[1])
        ];
}

function argument_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = body_decode$3(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "areaId",
                    false,
                    Curry._1(Identifiers.AreaId.t_encode, v.areaId)
                  ]]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var areaId = Curry._1(Identifiers.AreaId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "areaId"), null));
  if (areaId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              areaId: areaId._0
            }
          };
  }
  var e = areaId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".areaId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function areaOverlapResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "overlappingGeoAreas",
                    false,
                    Spice.arrayToJson(body_encode$3, v.overlappingGeoAreas)
                  ]]));
}

function areaOverlapResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var overlappingGeoAreas = Spice.arrayFromJson(body_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "overlappingGeoAreas"), null));
  if (overlappingGeoAreas.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              overlappingGeoAreas: overlappingGeoAreas._0
            }
          };
  }
  var e = overlappingGeoAreas._0;
  return {
          TAG: "Error",
          _0: {
            path: ".overlappingGeoAreas" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function encoder$1(_v) {
  return Spice.stringToJson("");
}

function decoder$1(json) {
  var match = json.code;
  var value = match === "AREA_OVERLAP" ? Curry._3(Toolkit__Primitives_Result.Result.mapWithDefault, areaOverlapResponse_decode(json), "unknown", (function (response) {
            return {
                    NAME: "areaOverlap",
                    VAL: response
                  };
          })) : "unknown";
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec$1 = [
  encoder$1,
  decoder$1
];

var t_encode$3 = encoder$1;

var t_decode$3 = decoder$1;

var $$Error$1 = {
  areaOverlapResponse_encode: areaOverlapResponse_encode,
  areaOverlapResponse_decode: areaOverlapResponse_decode,
  encoder: encoder$1,
  decoder: decoder$1,
  codec: codec$1,
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

function config$17(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/areas",
          requestOptions: {
            method: "POST",
            json: body_encode$3(param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode$3), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Create$2 = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  argument_encode: argument_encode$5,
  argument_decode: argument_decode$5,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  $$Error: $$Error$1,
  config: config$17
};

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "area",
                    false,
                    area_encode(v.area)
                  ]
                ]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    var area = area_decode(Belt_Option.getWithDefault(Js_dict.get(v, "area"), null));
    if (area.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                hubId: hubId._0,
                area: area._0
              }
            };
    }
    var e = area._0;
    return {
            TAG: "Error",
            _0: {
              path: ".area" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$6(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.AreaId.t_encode, v[1]),
          body_encode$4(v[2])
        ];
}

function argument_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 3) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var v2 = v[2];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.AreaId.t_decode, v1);
  var match$2 = body_decode$4(v2);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      if (match$2.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: [
                  match._0,
                  match$1._0,
                  match$2._0
                ]
              };
      }
      var e = match$2._0;
      return {
              TAG: "Error",
              _0: {
                path: "[2]" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function area_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "countryCode",
                    false,
                    Curry._1(Identifiers.CountryCode.t_encode, v.countryCode)
                  ],
                  [
                    "level1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.level1)
                  ],
                  [
                    "level2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.level2)
                  ]
                ]));
}

function area_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var countryCode = Curry._1(Identifiers.CountryCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "countryCode"), null));
  if (countryCode.TAG === "Ok") {
    var level1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "level1"), null));
    if (level1.TAG === "Ok") {
      var level2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "level2"), null));
      if (level2.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  countryCode: countryCode._0,
                  level1: level1._0,
                  level2: level2._0
                }
              };
      }
      var e = level2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level2" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = level1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".level1" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = countryCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".countryCode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function areaOverlapResponse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "overlappingZones",
                    false,
                    Spice.arrayToJson(body_encode$4, v.overlappingZones)
                  ]]));
}

function areaOverlapResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var overlappingZones = Spice.arrayFromJson(body_decode$4, Belt_Option.getWithDefault(Js_dict.get(v, "overlappingZones"), null));
  if (overlappingZones.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              overlappingZones: overlappingZones._0
            }
          };
  }
  var e = overlappingZones._0;
  return {
          TAG: "Error",
          _0: {
            path: ".overlappingZones" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function encoder$2(_v) {
  return Spice.stringToJson("");
}

function decoder$2(json) {
  var match = json.code;
  var value;
  if (match !== undefined) {
    switch (match) {
      case "AREA_NOT_FOUND" :
          value = "areaNotFound";
          break;
      case "AREA_OVERLAP" :
          value = Curry._3(Toolkit__Primitives_Result.Result.mapWithDefault, areaOverlapResponse_decode$1(json), "unknown", (function (response) {
                  return {
                          NAME: "areaOverlap",
                          VAL: response
                        };
                }));
          break;
      default:
        value = "unknown";
    }
  } else {
    value = "unknown";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec$2 = [
  encoder$2,
  decoder$2
];

var t_encode$4 = encoder$2;

var t_decode$4 = decoder$2;

var $$Error$2 = {
  areaOverlapResponse_encode: areaOverlapResponse_encode$1,
  areaOverlapResponse_decode: areaOverlapResponse_decode$1,
  encoder: encoder$2,
  decoder: decoder$2,
  codec: codec$2,
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function config$18(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/areas/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$4(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var Update$2 = {
  argument_encode: argument_encode$6,
  argument_decode: argument_decode$6,
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  area_encode: area_encode$1,
  area_decode: area_decode$1,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  $$Error: $$Error$2,
  config: config$18
};

function argument_encode$7(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.AreaId.t_encode, v[1])
        ];
}

function argument_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.AreaId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$19 = Spice.unitToJson;

var response_decode$19 = Spice.unitFromJson;

function config$19(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/areas/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$2 = {
  argument_encode: argument_encode$7,
  argument_decode: argument_decode$7,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  config: config$19
};

var Areas$1 = {
  Create: Create$2,
  Update: Update$2,
  Delete: Delete$2
};

function warehouse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function warehouse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
    if (warehouseCode.TAG === "Ok") {
      var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
      if (line1.TAG === "Ok") {
        var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
        if (line2.TAG === "Ok") {
          var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
          if (city.TAG === "Ok") {
            var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
            if (postalCode.TAG === "Ok") {
              var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          warehouseCode: warehouseCode._0,
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          postalCode: postalCode._0,
                          country: country._0
                        }
                      };
              }
              var e = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = postalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".postalCode" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = line2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line2" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = line1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".line1" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = warehouseCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouseCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "warehouses",
                    false,
                    Spice.arrayToJson(warehouse_encode$1, v.warehouses)
                  ]]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouses = Spice.arrayFromJson(warehouse_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "warehouses"), null));
  if (warehouses.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              warehouses: warehouses._0
            }
          };
  }
  var e = warehouses._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouses" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$20(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/warehouses",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsWarehousesGetList",
            clientId
          ]
        };
}

var GetList$1 = {
  warehouse_encode: warehouse_encode$1,
  warehouse_decode: warehouse_decode$1,
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  config: config$20
};

function warehouse_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function warehouse_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
    if (warehouseCode.TAG === "Ok") {
      var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
      if (line1.TAG === "Ok") {
        var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
        if (line2.TAG === "Ok") {
          var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
          if (city.TAG === "Ok") {
            var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
            if (postalCode.TAG === "Ok") {
              var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          warehouseCode: warehouseCode._0,
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          postalCode: postalCode._0,
                          country: country._0
                        }
                      };
              }
              var e = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = postalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".postalCode" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = line2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line2" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = line1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".line1" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = warehouseCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouseCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

var response_encode$21 = warehouse_encode$2;

var response_decode$21 = warehouse_decode$2;

function errorPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$21(param) {
  var warehouseCode = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/warehouses/" + warehouseCode,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsWarehousesGet",
            clientId,
            warehouseCode
          ],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                if (decodedError._0.code === "WAREHOUSE_NOT_FOUND") {
                                  return {
                                          TAG: "Custom",
                                          _0: "WarehouseNotFound"
                                        };
                                } else {
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                                }
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Get$1 = {
  warehouse_encode: warehouse_encode$2,
  warehouse_decode: warehouse_decode$2,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  errorPayload_decode: errorPayload_decode,
  config: config$21
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
    if (warehouseCode.TAG === "Ok") {
      var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
      if (line1.TAG === "Ok") {
        var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
        if (line2.TAG === "Ok") {
          var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
          if (city.TAG === "Ok") {
            var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
            if (postalCode.TAG === "Ok") {
              var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          warehouseCode: warehouseCode._0,
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          postalCode: postalCode._0,
                          country: country._0
                        }
                      };
              }
              var e = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = postalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".postalCode" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = line2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line2" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = line1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".line1" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = warehouseCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouseCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function config$22(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/warehouses",
          requestOptions: {
            method: "POST",
            json: body_encode$5(param[1])
          },
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 409 ? ({
                              TAG: "Custom",
                              _0: "WarehouseCodeExist"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var Create$3 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  config: config$22
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
    if (warehouseCode.TAG === "Ok") {
      var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
      if (line1.TAG === "Ok") {
        var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
        if (line2.TAG === "Ok") {
          var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
          if (city.TAG === "Ok") {
            var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
            if (postalCode.TAG === "Ok") {
              var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          warehouseCode: warehouseCode._0,
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          postalCode: postalCode._0,
                          country: country._0
                        }
                      };
              }
              var e = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = postalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".postalCode" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = line2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line2" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = line1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".line1" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = warehouseCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouseCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

function config$23(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/warehouses/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$6(param[2])
          },
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 409 ? ({
                              TAG: "Custom",
                              _0: "WarehouseCodeExist"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var Edit = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  config: config$23
};

var response_encode$24 = Spice.unitToJson;

var response_decode$24 = Spice.unitFromJson;

function config$24(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/warehouses/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$3 = {
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  config: config$24
};

var Warehouses$1 = {
  GetList: GetList$1,
  Get: Get$1,
  Create: Create$3,
  Edit: Edit,
  Delete: Delete$3
};

function warehouse_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouseCode = Curry._1(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
  if (warehouseCode.TAG === "Ok") {
    var warehouseName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "warehouseName"), null));
    if (warehouseName.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                warehouseCode: warehouseCode._0,
                warehouseName: warehouseName._0
              }
            };
    }
    var e = warehouseName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouseName" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = warehouseCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouseCode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function warehouse_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "warehouseCode",
                    false,
                    Curry._1(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "warehouseName",
                    false,
                    Spice.stringToJson(v.warehouseName)
                  ]
                ]));
}

function client_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var warehouses = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "warehouses"), (function (param) {
                return Spice.arrayFromJson(warehouse_decode$3, param);
              })), {
          TAG: "Ok",
          _0: []
        });
    if (warehouses.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clientId: clientId._0,
                  warehouses: warehouses._0,
                  name: name._0
                }
              };
      }
      var e = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = warehouses._0;
    return {
            TAG: "Error",
            _0: {
              path: ".warehouses" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function client_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "warehouses",
                    false,
                    Spice.arrayToJson(warehouse_encode$3, v.warehouses)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function response_encode$25(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode, v.clients)
                  ]]));
}

function response_decode$25(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "clients"), (function (param) {
              return Spice.arrayFromJson(client_decode, param);
            })), {
        TAG: "Ok",
        _0: []
      });
  if (clients.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clients: clients._0
            }
          };
  }
  var e = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function params_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ]]));
}

function params_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              hubId: hubId._0
            }
          };
  }
  var e = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$25(hubId) {
  return {
          kyInstance: kyInstance$4,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$4({
                      hubId: hubId
                    }))
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsWarehousesGetByHubId",
            hubId
          ]
        };
}

var GetByHubId$1 = {
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  client_encode: client_encode,
  client_decode: client_decode,
  warehouse_encode: warehouse_encode$3,
  warehouse_decode: warehouse_decode$3,
  params_encode: params_encode$4,
  params_decode: params_decode$4,
  config: config$25
};

function client_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function client_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$26(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode$1, v.clients)
                  ]]));
}

function response_decode$26(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Spice.arrayFromJson(client_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
  if (clients.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clients: clients._0
            }
          };
  }
  var e = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$26(param) {
  return {
          kyInstance: kyInstance$4,
          path: "names",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client.res",
            "GetAllNames"
          ]
        };
}

var GetAllNames = {
  client_encode: client_encode$1,
  client_decode: client_decode$1,
  response_encode: response_encode$26,
  response_decode: response_decode$26,
  config: config$26
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.name)
                  ]]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    ColiswebApi__Utils.SortOrder.t_encode(v.name)
                  ]]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = ColiswebApi__Utils.SortOrder.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode(v.pagination)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function queryParams_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ]]));
}

function queryParams_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              date: date._0
            }
          };
  }
  var e = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function activation_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function activation_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      var ruleType = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "ruleType"), t_decode$1), {
            TAG: "Ok",
            _0: "clientPricing"
          });
      if (ruleType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0,
                  ruleType: ruleType._0
                }
              };
      }
      var e = ruleType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function nbActiveStores_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "active",
                    false,
                    Spice.intToJson(v.active)
                  ],
                  [
                    "total",
                    false,
                    Spice.intToJson(v.total)
                  ]
                ]));
}

function nbActiveStores_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var active = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "active"), null));
  if (active.TAG === "Ok") {
    var total = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "total"), null));
    if (total.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                active: active._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: "Error",
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = active._0;
  return {
          TAG: "Error",
          _0: {
            path: ".active" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function client_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "clientName",
                    false,
                    Spice.stringToJson(v.clientName)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ],
                  [
                    "activation",
                    false,
                    Spice.optionToJson(activation_encode$5, v.activation)
                  ],
                  [
                    "nbStores",
                    false,
                    Spice.optionToJson(nbActiveStores_encode, v.nbStores)
                  ]
                ]));
}

function client_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var clientName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientName"), null));
    if (clientName.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        var activation = Spice.optionFromJson(activation_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "activation"), null));
        if (activation.TAG === "Ok") {
          var nbStores = Spice.optionFromJson(nbActiveStores_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nbStores"), null));
          if (nbStores.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      clientId: clientId._0,
                      clientName: clientName._0,
                      updatedAt: updatedAt._0,
                      activation: activation._0,
                      nbStores: nbStores._0
                    }
                  };
          }
          var e = nbStores._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nbStores" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = activation._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".activation" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = clientName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$27(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode$2, v.clients)
                  ]
                ]));
}

function response_decode$27(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var clients = Spice.arrayFromJson(client_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
    if (clients.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                clients: clients._0
              }
            };
    }
    var e = clients._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clients" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$27(param) {
  var params = param[1];
  var body = param[0];
  return {
          kyInstance: kyInstance$4,
          path: "all",
          requestOptions: {
            method: "POST",
            json: body_encode$7(body),
            searchParams: Belt_Option.map(params, (function (params) {
                    return Qs.stringify(queryParams_encode(params));
                  }))
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsGetAll",
            JSON.stringify(body_encode$7(body)),
            Belt_Option.mapWithDefault(params, "", (function (p) {
                    return JSON.stringify(queryParams_encode(p));
                  }))
          ]
        };
}

var GetAllPaginated = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  pagination_encode: pagination_encode,
  pagination_decode: pagination_decode,
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  queryParams_encode: queryParams_encode,
  queryParams_decode: queryParams_decode,
  activation_encode: activation_encode$5,
  activation_decode: activation_decode$5,
  nbActiveStores_encode: nbActiveStores_encode,
  nbActiveStores_decode: nbActiveStores_decode,
  client_encode: client_encode$2,
  client_decode: client_decode$2,
  response_encode: response_encode$27,
  response_decode: response_decode$27,
  config: config$27
};

function barcodePattern_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var regex = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "regex"), null));
  if (regex.TAG === "Ok") {
    var sample = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "sample"), null));
    if (sample.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                regex: regex._0,
                sample: sample._0
              }
            };
    }
    var e = sample._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sample" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = regex._0;
  return {
          TAG: "Error",
          _0: {
            path: ".regex" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function area_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var areaId = Curry._1(Identifiers.AreaId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "areaId"), null));
  if (areaId.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
      if (hubId.TAG === "Ok") {
        var area = area_decode(Belt_Option.getWithDefault(Js_dict.get(v, "area"), null));
        if (area.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    areaId: areaId._0,
                    clientId: clientId._0,
                    hubId: hubId._0,
                    area: area._0
                  }
                };
        }
        var e = area._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".area" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = hubId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = areaId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".areaId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function area_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "areaId",
                    false,
                    Curry._1(Identifiers.AreaId.t_encode, v.areaId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "area",
                    false,
                    area_encode(v.area)
                  ]
                ]));
}

function barcodePattern_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "regex",
                    false,
                    Spice.stringToJson(v.regex)
                  ],
                  [
                    "sample",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.sample)
                  ]
                ]));
}

function response_encode$28(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "areas",
                    false,
                    Spice.arrayToJson(area_encode$2, v.areas)
                  ],
                  [
                    "barcodePattern",
                    false,
                    Spice.optionToJson(barcodePattern_encode$1, v.barcodePattern)
                  ],
                  [
                    "allowOnlinePaymentDelivery",
                    false,
                    Spice.boolToJson(v.allowOnlinePaymentDelivery)
                  ],
                  [
                    "canTrackOrders",
                    false,
                    Spice.boolToJson(v.canTrackOrders)
                  ],
                  [
                    "allowOrdersAccess",
                    false,
                    Spice.boolToJson(v.allowOrdersAccess)
                  ],
                  [
                    "partialDeliveriesFilter",
                    false,
                    t_encode$2(v.partialDeliveriesFilter)
                  ],
                  [
                    "orderIntegrationMaximumDelayInDays",
                    false,
                    Spice.intToJson(v.orderIntegrationMaximumDelayInDays)
                  ],
                  [
                    "canAnswerSurvey",
                    false,
                    Spice.boolToJson(v.canAnswerSurvey)
                  ],
                  [
                    "allowReintegration",
                    false,
                    Spice.boolToJson(v.allowReintegration)
                  ]
                ]));
}

function response_decode$28(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var areas = Spice.arrayFromJson(area_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "areas"), null));
      if (areas.TAG === "Ok") {
        var barcodePattern = Spice.optionFromJson(barcodePattern_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "barcodePattern"), null));
        if (barcodePattern.TAG === "Ok") {
          var allowOnlinePaymentDelivery = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "allowOnlinePaymentDelivery"), Spice.boolFromJson), {
                TAG: "Ok",
                _0: false
              });
          if (allowOnlinePaymentDelivery.TAG === "Ok") {
            var canTrackOrders = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "canTrackOrders"), Spice.boolFromJson), {
                  TAG: "Ok",
                  _0: false
                });
            if (canTrackOrders.TAG === "Ok") {
              var allowOrdersAccess = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "allowOrdersAccess"), Spice.boolFromJson), {
                    TAG: "Ok",
                    _0: false
                  });
              if (allowOrdersAccess.TAG === "Ok") {
                var partialDeliveriesFilter = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "partialDeliveriesFilter"), null));
                if (partialDeliveriesFilter.TAG === "Ok") {
                  var orderIntegrationMaximumDelayInDays = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orderIntegrationMaximumDelayInDays"), null));
                  if (orderIntegrationMaximumDelayInDays.TAG === "Ok") {
                    var canAnswerSurvey = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "canAnswerSurvey"), Spice.boolFromJson), {
                          TAG: "Ok",
                          _0: true
                        });
                    if (canAnswerSurvey.TAG === "Ok") {
                      var allowReintegration = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowReintegration"), null));
                      if (allowReintegration.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  id: id._0,
                                  name: name._0,
                                  areas: areas._0,
                                  barcodePattern: barcodePattern._0,
                                  allowOnlinePaymentDelivery: allowOnlinePaymentDelivery._0,
                                  canTrackOrders: canTrackOrders._0,
                                  allowOrdersAccess: allowOrdersAccess._0,
                                  partialDeliveriesFilter: partialDeliveriesFilter._0,
                                  orderIntegrationMaximumDelayInDays: orderIntegrationMaximumDelayInDays._0,
                                  canAnswerSurvey: canAnswerSurvey._0,
                                  allowReintegration: allowReintegration._0
                                }
                              };
                      }
                      var e = allowReintegration._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".allowReintegration" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = canAnswerSurvey._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".canAnswerSurvey" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = orderIntegrationMaximumDelayInDays._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".orderIntegrationMaximumDelayInDays" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = partialDeliveriesFilter._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".partialDeliveriesFilter" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = allowOrdersAccess._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".allowOrdersAccess" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = canTrackOrders._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".canTrackOrders" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = allowOnlinePaymentDelivery._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".allowOnlinePaymentDelivery" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = barcodePattern._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".barcodePattern" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = areas._0;
      return {
              TAG: "Error",
              _0: {
                path: ".areas" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function errorPayload_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$28(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsGetDetail",
            clientId
          ],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode$1), (function (decodedError) {
                              if (decodedError.TAG !== "Ok") {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                              switch (decodedError._0.code) {
                                case "CLIENT_NOT_FOUND" :
                                case "NO_CLIENT_EXISTS" :
                                    return {
                                            TAG: "Custom",
                                            _0: "NotFoundOrNoData"
                                          };
                                default:
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var AsAdmin = {
  response_encode: response_encode$28,
  response_decode: response_decode$28,
  area_encode: area_encode$2,
  area_decode: area_decode$2,
  barcodePattern_encode: barcodePattern_encode$1,
  barcodePattern_decode: barcodePattern_decode$1,
  errorPayload_decode: errorPayload_decode$1,
  config: config$28
};

function response_encode$29(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "allowOrdersAccess",
                    false,
                    Spice.boolToJson(v.allowOrdersAccess)
                  ]
                ]));
}

function response_decode$29(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var allowOrdersAccess = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "allowOrdersAccess"), Spice.boolFromJson), {
            TAG: "Ok",
            _0: false
          });
      if (allowOrdersAccess.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  allowOrdersAccess: allowOrdersAccess._0
                }
              };
      }
      var e = allowOrdersAccess._0;
      return {
              TAG: "Error",
              _0: {
                path: ".allowOrdersAccess" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function errorPayload_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$29(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsGetDetail",
            clientId
          ],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode$2), (function (decodedError) {
                              if (decodedError.TAG !== "Ok") {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                              switch (decodedError._0.code) {
                                case "CLIENT_NOT_FOUND" :
                                case "NO_CLIENT_EXISTS" :
                                    return {
                                            TAG: "Custom",
                                            _0: "NotFoundOrNoData"
                                          };
                                default:
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var AsClient = {
  response_encode: response_encode$29,
  response_decode: response_decode$29,
  errorPayload_decode: errorPayload_decode$2,
  config: config$29
};

var GetDetail = {
  AsAdmin: AsAdmin,
  AsClient: AsClient
};

function client_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function client_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$30(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "client",
                    false,
                    client_encode$3(v.client)
                  ],
                  [
                    "allowOnlinePaymentDelivery",
                    false,
                    Spice.boolToJson(v.allowOnlinePaymentDelivery)
                  ],
                  [
                    "canAnswerSurvey",
                    false,
                    Spice.optionToJson(Spice.boolToJson, v.canAnswerSurvey)
                  ]
                ]));
}

function response_decode$30(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var client = client_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
      if (client.TAG === "Ok") {
        var allowOnlinePaymentDelivery = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "allowOnlinePaymentDelivery"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (allowOnlinePaymentDelivery.TAG === "Ok") {
          var canAnswerSurvey = Spice.optionFromJson(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "canAnswerSurvey"), null));
          if (canAnswerSurvey.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      name: name._0,
                      client: client._0,
                      allowOnlinePaymentDelivery: allowOnlinePaymentDelivery._0,
                      canAnswerSurvey: canAnswerSurvey._0
                    }
                  };
          }
          var e = canAnswerSurvey._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".canAnswerSurvey" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = allowOnlinePaymentDelivery._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".allowOnlinePaymentDelivery" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = client._0;
      return {
              TAG: "Error",
              _0: {
                path: ".client" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function config$30(param) {
  var storeId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/stores/" + storeId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsGetStore",
            clientId,
            storeId
          ],
          mapCustomErrors: (function (error) {
              var match = error.response;
              if (match !== undefined && match.status === 404) {
                return $$Promise.resolved({
                            TAG: "Custom",
                            _0: "NotFound"
                          });
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var GetStore = {
  client_encode: client_encode$3,
  client_decode: client_decode$3,
  response_encode: response_encode$30,
  response_decode: response_decode$30,
  config: config$30
};

function notFoundResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]
                ]));
}

function notFoundResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
      if (code.TAG === "Ok") {
        var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
        if (message.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    clientId: clientId._0,
                    storeId: storeId._0,
                    code: code._0,
                    message: message._0
                  }
                };
        }
        var e = message._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".message" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = code._0;
      return {
              TAG: "Error",
              _0: {
                path: ".code" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function valueContainer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "value",
                    false,
                    Spice.stringToJson(v.value)
                  ]]));
}

function valueContainer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var value = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "value"), null));
  if (value.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              value: value._0
            }
          };
  }
  var e = value._0;
  return {
          TAG: "Error",
          _0: {
            path: ".value" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function encoder$3(_v) {
  return Spice.stringToJson("");
}

function decoder$3(json) {
  var match = json.code;
  var value;
  if (match !== undefined) {
    switch (match) {
      case "ACCESS_REFUSED" :
          value = "unauthorized";
          break;
      case "NO_LOAD_LIMIT" :
          value = {
            NAME: "notFound",
            VAL: Curry._1(Toolkit__Primitives_Result.Result.getExn, notFoundResponse_decode(json))
          };
          break;
      default:
        value = "unknown";
    }
  } else {
    value = "unknown";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec$3 = [
  encoder$3,
  decoder$3
];

var t_encode$5 = encoder$3;

var t_decode$5 = decoder$3;

var $$Error$3 = {
  notFoundResponse_encode: notFoundResponse_encode,
  notFoundResponse_decode: notFoundResponse_decode,
  valueContainer_encode: valueContainer_encode,
  valueContainer_decode: valueContainer_decode,
  encoder: encoder$3,
  decoder: decoder$3,
  codec: codec$3,
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

function dimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "length",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "width",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ]
                ]));
}

function dimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var length = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
  if (length.TAG === "Ok") {
    var width = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
    if (width.TAG === "Ok") {
      var height = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
      if (height.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  length: length._0,
                  width: width._0,
                  height: height._0
                }
              };
      }
      var e = height._0;
      return {
              TAG: "Error",
              _0: {
                path: ".height" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = width._0;
    return {
            TAG: "Error",
            _0: {
              path: ".width" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = length._0;
  return {
          TAG: "Error",
          _0: {
            path: ".length" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function administrativeArea_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "code1",
                    false,
                    Spice.stringToJson(v.code1)
                  ],
                  [
                    "code2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.code2)
                  ],
                  [
                    "code3",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.code3)
                  ],
                  [
                    "countryCode",
                    false,
                    Spice.stringToJson(v.countryCode)
                  ]
                ]));
}

function administrativeArea_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var code1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code1"), null));
    if (code1.TAG === "Ok") {
      var code2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code2"), null));
      if (code2.TAG === "Ok") {
        var code3 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code3"), null));
        if (code3.TAG === "Ok") {
          var countryCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "countryCode"), null));
          if (countryCode.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      name: name._0,
                      code1: code1._0,
                      code2: code2._0,
                      code3: code3._0,
                      countryCode: countryCode._0
                    }
                  };
          }
          var e = countryCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".countryCode" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = code3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".code3" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = code2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".code2" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = code1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".code1" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function point_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ]
                ]));
}

function point_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
  if (postalCode.TAG === "Ok") {
    var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
    if (administrativeArea.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                postalCode: postalCode._0,
                administrativeArea: administrativeArea._0
              }
            };
    }
    var e = administrativeArea._0;
    return {
            TAG: "Error",
            _0: {
              path: ".administrativeArea" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = postalCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".postalCode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "maxProductWeight",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.maxProductWeight)
                  ],
                  [
                    "totalWeight",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.totalWeight)
                  ],
                  [
                    "packets",
                    false,
                    Spice.intToJson(v.packets)
                  ],
                  [
                    "dimensions",
                    false,
                    dimensions_encode(v.dimensions)
                  ],
                  [
                    "distance",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.distance)
                  ],
                  [
                    "zone",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.zone)
                  ],
                  [
                    "chosenRoom",
                    false,
                    Spice.boolToJson(v.chosenRoom)
                  ],
                  [
                    "floor",
                    false,
                    Spice.intToJson(v.floor)
                  ],
                  [
                    "lift",
                    false,
                    Spice.boolToJson(v.lift)
                  ],
                  [
                    "status",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.status)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.skills)
                  ],
                  [
                    "flowType",
                    false,
                    Spice.stringToJson(v.flowType)
                  ],
                  [
                    "priceChannel",
                    false,
                    Spice.stringToJson(v.priceChannel)
                  ],
                  [
                    "orderChannel",
                    false,
                    Spice.stringToJson(v.orderChannel)
                  ],
                  [
                    "pickup",
                    false,
                    point_encode(v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    point_encode(v.shipping)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var maxProductWeight = Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxProductWeight"), null));
  if (maxProductWeight.TAG === "Ok") {
    var totalWeight = Curry._1(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "totalWeight"), null));
    if (totalWeight.TAG === "Ok") {
      var packets = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "packets"), null));
      if (packets.TAG === "Ok") {
        var dimensions = dimensions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
        if (dimensions.TAG === "Ok") {
          var distance = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "distance"), null));
          if (distance.TAG === "Ok") {
            var zone = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "zone"), null));
            if (zone.TAG === "Ok") {
              var chosenRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "chosenRoom"), null));
              if (chosenRoom.TAG === "Ok") {
                var floor = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  var lift = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                  if (lift.TAG === "Ok") {
                    var status = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
                    if (status.TAG === "Ok") {
                      var skills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                      if (skills.TAG === "Ok") {
                        var flowType = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "flowType"), null));
                        if (flowType.TAG === "Ok") {
                          var priceChannel = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceChannel"), null));
                          if (priceChannel.TAG === "Ok") {
                            var orderChannel = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orderChannel"), null));
                            if (orderChannel.TAG === "Ok") {
                              var pickup = point_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
                              if (pickup.TAG === "Ok") {
                                var shipping = point_decode(Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                                if (shipping.TAG === "Ok") {
                                  return {
                                          TAG: "Ok",
                                          _0: {
                                            maxProductWeight: maxProductWeight._0,
                                            totalWeight: totalWeight._0,
                                            packets: packets._0,
                                            dimensions: dimensions._0,
                                            distance: distance._0,
                                            zone: zone._0,
                                            chosenRoom: chosenRoom._0,
                                            floor: floor._0,
                                            lift: lift._0,
                                            status: status._0,
                                            skills: skills._0,
                                            flowType: flowType._0,
                                            priceChannel: priceChannel._0,
                                            orderChannel: orderChannel._0,
                                            pickup: pickup._0,
                                            shipping: shipping._0
                                          }
                                        };
                                }
                                var e = shipping._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".shipping" + e.path,
                                          message: e.message,
                                          value: e.value
                                        }
                                      };
                              }
                              var e$1 = pickup._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".pickup" + e$1.path,
                                        message: e$1.message,
                                        value: e$1.value
                                      }
                                    };
                            }
                            var e$2 = orderChannel._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".orderChannel" + e$2.path,
                                      message: e$2.message,
                                      value: e$2.value
                                    }
                                  };
                          }
                          var e$3 = priceChannel._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".priceChannel" + e$3.path,
                                    message: e$3.message,
                                    value: e$3.value
                                  }
                                };
                        }
                        var e$4 = flowType._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".flowType" + e$4.path,
                                  message: e$4.message,
                                  value: e$4.value
                                }
                              };
                      }
                      var e$5 = skills._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".skills" + e$5.path,
                                message: e$5.message,
                                value: e$5.value
                              }
                            };
                    }
                    var e$6 = status._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".status" + e$6.path,
                              message: e$6.message,
                              value: e$6.value
                            }
                          };
                  }
                  var e$7 = lift._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".lift" + e$7.path,
                            message: e$7.message,
                            value: e$7.value
                          }
                        };
                }
                var e$8 = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e$8.path,
                          message: e$8.message,
                          value: e$8.value
                        }
                      };
              }
              var e$9 = chosenRoom._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".chosenRoom" + e$9.path,
                        message: e$9.message,
                        value: e$9.value
                      }
                    };
            }
            var e$10 = zone._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".zone" + e$10.path,
                      message: e$10.message,
                      value: e$10.value
                    }
                  };
          }
          var e$11 = distance._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".distance" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
        }
        var e$12 = dimensions._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".dimensions" + e$12.path,
                  message: e$12.message,
                  value: e$12.value
                }
              };
      }
      var e$13 = packets._0;
      return {
              TAG: "Error",
              _0: {
                path: ".packets" + e$13.path,
                message: e$13.message,
                value: e$13.value
              }
            };
    }
    var e$14 = totalWeight._0;
    return {
            TAG: "Error",
            _0: {
              path: ".totalWeight" + e$14.path,
              message: e$14.message,
              value: e$14.value
            }
          };
  }
  var e$15 = maxProductWeight._0;
  return {
          TAG: "Error",
          _0: {
            path: ".maxProductWeight" + e$15.path,
            message: e$15.message,
            value: e$15.value
          }
        };
}

function argument_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "delivery",
                    false,
                    delivery_encode(v.delivery)
                  ]
                ]));
}

function argument_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var delivery = delivery_decode(Belt_Option.getWithDefault(Js_dict.get(v, "delivery"), null));
      if (delivery.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clientId: clientId._0,
                  storeId: storeId._0,
                  delivery: delivery._0
                }
              };
      }
      var e = delivery._0;
      return {
              TAG: "Error",
              _0: {
                path: ".delivery" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function composition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ]
                ]));
}

function composition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "delivery",
                    false,
                    delivery_encode(v.delivery)
                  ]]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var delivery = delivery_decode(Belt_Option.getWithDefault(Js_dict.get(v, "delivery"), null));
  if (delivery.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              delivery: delivery._0
            }
          };
  }
  var e = delivery._0;
  return {
          TAG: "Error",
          _0: {
            path: ".delivery" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function limit_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var maxProductWeight = Spice.optionFromJson(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "maxProductWeight"), null));
  if (maxProductWeight.TAG === "Ok") {
    var totalWeight = Spice.optionFromJson(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "totalWeight"), null));
    if (totalWeight.TAG === "Ok") {
      var dimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
      if (dimensions.TAG === "Ok") {
        var distance = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "distance"), null));
        if (distance.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    maxProductWeight: maxProductWeight._0,
                    totalWeight: totalWeight._0,
                    dimensions: dimensions._0,
                    distance: distance._0
                  }
                };
        }
        var e = distance._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".distance" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = dimensions._0;
      return {
              TAG: "Error",
              _0: {
                path: ".dimensions" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = totalWeight._0;
    return {
            TAG: "Error",
            _0: {
              path: ".totalWeight" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = maxProductWeight._0;
  return {
          TAG: "Error",
          _0: {
            path: ".maxProductWeight" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function limit_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "maxProductWeight",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.maxProductWeight)
                  ],
                  [
                    "totalWeight",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.totalWeight)
                  ],
                  [
                    "dimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.dimensions)
                  ],
                  [
                    "distance",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.distance)
                  ]
                ]));
}

function response_encode$31(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "labels",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.labels)
                  ],
                  [
                    "limit",
                    false,
                    Spice.optionToJson(limit_encode, v.limit)
                  ]
                ]));
}

function response_decode$31(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var labels = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "labels"), null));
  if (labels.TAG === "Ok") {
    var limit = Spice.optionFromJson(limit_decode, Belt_Option.getWithDefault(Js_dict.get(v, "limit"), null));
    if (limit.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                labels: labels._0,
                limit: limit._0
              }
            };
    }
    var e = limit._0;
    return {
            TAG: "Error",
            _0: {
              path: ".limit" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = labels._0;
  return {
          TAG: "Error",
          _0: {
            path: ".labels" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$31(param) {
  var delivery = param.delivery;
  var storeId = param.storeId;
  var clientId = param.clientId;
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/stores/" + storeId + "/loadLimit",
          requestOptions: {
            method: "POST",
            json: body_encode$8({
                  delivery: delivery
                })
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsGetLoadLimit",
            JSON.stringify(delivery_encode(delivery)),
            clientId,
            storeId
          ],
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.decodeError(t_decode, param);
            })
        };
}

var GetLoadLimit = {
  $$Error: $$Error$3,
  dimensions_encode: dimensions_encode,
  dimensions_decode: dimensions_decode,
  administrativeArea_encode: administrativeArea_encode,
  administrativeArea_decode: administrativeArea_decode,
  point_encode: point_encode,
  point_decode: point_decode,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  argument_encode: argument_encode$8,
  argument_decode: argument_decode$8,
  composition_encode: composition_encode,
  composition_decode: composition_decode,
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  response_encode: response_encode$31,
  response_decode: response_decode$31,
  limit_encode: limit_encode,
  limit_decode: limit_decode,
  config: config$31
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "regex",
                    false,
                    Spice.stringToJson(v.regex)
                  ],
                  [
                    "sample",
                    false,
                    Spice.stringToJson(v.sample)
                  ]
                ]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var regex = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "regex"), null));
  if (regex.TAG === "Ok") {
    var sample = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sample"), null));
    if (sample.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                regex: regex._0,
                sample: sample._0
              }
            };
    }
    var e = sample._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sample" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = regex._0;
  return {
          TAG: "Error",
          _0: {
            path: ".regex" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$32 = Spice.unitToJson;

var response_decode$32 = Spice.unitFromJson;

function errorPayload_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$32(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/barcodePattern",
          requestOptions: {
            method: "PUT",
            json: body_encode$9(param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode$3), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                if (decodedError._0.code === "INVALID_REGEX") {
                                  return {
                                          TAG: "Custom",
                                          _0: "InvalidRegex"
                                        };
                                } else {
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                                }
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var PutBarcodePattern = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$32,
  response_decode: response_decode$32,
  errorPayload_decode: errorPayload_decode$3,
  config: config$32
};

function argument_encode$9(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.StoreId.t_encode, v[1])
        ];
}

function argument_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.StoreId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$33(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "canAnswerSurvey",
                    false,
                    Spice.boolToJson(v.canAnswerSurvey)
                  ]]));
}

function response_decode$33(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var canAnswerSurvey = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "canAnswerSurvey"), Spice.boolFromJson), {
        TAG: "Ok",
        _0: true
      });
  if (canAnswerSurvey.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              canAnswerSurvey: canAnswerSurvey._0
            }
          };
  }
  var e = canAnswerSurvey._0;
  return {
          TAG: "Error",
          _0: {
            path: ".canAnswerSurvey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$33(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/stores/" + param[1] + "/settings",
          requestOptions: {
            method: "GET"
          },
          key: ["StoreGetSettings"]
        };
}

var Get$2 = {
  argument_encode: argument_encode$9,
  argument_decode: argument_decode$9,
  response_encode: response_encode$33,
  response_decode: response_decode$33,
  config: config$33
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "nps",
                    false,
                    Spice.intToJson(v.nps)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "answerDate",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.answerDate)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var nps = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "nps"), null));
    if (nps.TAG === "Ok") {
      var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
      if (comment.TAG === "Ok") {
        var answerDate = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "answerDate"), null));
        if (answerDate.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    email: email._0,
                    nps: nps._0,
                    comment: comment._0,
                    answerDate: answerDate._0
                  }
                };
        }
        var e = answerDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".answerDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = comment._0;
      return {
              TAG: "Error",
              _0: {
                path: ".comment" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = nps._0;
    return {
            TAG: "Error",
            _0: {
              path: ".nps" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$34 = Spice.unitToJson;

var response_decode$34 = Spice.unitFromJson;

function config$34(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/stores/" + param[1] + "/settings/survey",
          requestOptions: {
            method: "POST",
            json: body_encode$10(param[2])
          }
        };
}

var AddSurvey = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  response_encode: response_encode$34,
  response_decode: response_decode$34,
  config: config$34
};

var Settings = {
  Get: Get$2,
  AddSurvey: AddSurvey
};

var Stores = {
  Settings: Settings
};

function t_encode$6(v) {
  switch (v) {
    case "ref1" :
        return "ref1";
    case "ref2" :
        return "ref2";
    case "ref3" :
        return "ref3";
    case "ref4" :
        return "ref4";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("ref1" === v) {
          return {
                  TAG: "Ok",
                  _0: "ref1"
                };
        } else if ("ref2" === v) {
          return {
                  TAG: "Ok",
                  _0: "ref2"
                };
        } else if ("ref3" === v) {
          return {
                  TAG: "Ok",
                  _0: "ref3"
                };
        } else if ("ref4" === v) {
          return {
                  TAG: "Ok",
                  _0: "ref4"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ref1 = {
  id: "_8600085a",
  defaultMessage: "Réf. 01"
};

var ref2 = {
  id: "_a3e09ac9",
  defaultMessage: "Réf. 02"
};

var ref3 = {
  id: "_acd0cffb",
  defaultMessage: "Réf. 03"
};

var ref4 = {
  id: "_23c17487",
  defaultMessage: "Réf. 04"
};

var arrayEnum = [
  "ref1",
  "ref2",
  "ref3",
  "ref4"
];

function humanize$1(t, intl) {
  var msg;
  switch (t) {
    case "ref1" :
        msg = ref1;
        break;
    case "ref2" :
        msg = ref2;
        break;
    case "ref3" :
        msg = ref3;
        break;
    case "ref4" :
        msg = ref4;
        break;
    
  }
  return intl.formatMessage(msg);
}

var ReferenceLevel = {
  t_encode: t_encode$6,
  t_decode: t_decode$6,
  ref1: ref1,
  ref2: ref2,
  ref3: ref3,
  ref4: ref4,
  arrayEnum: arrayEnum,
  humanize: humanize$1
};

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "partialDeliveriesFilter",
                    false,
                    t_encode$2(v.partialDeliveriesFilter)
                  ]]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var partialDeliveriesFilter = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "partialDeliveriesFilter"), null));
  if (partialDeliveriesFilter.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              partialDeliveriesFilter: partialDeliveriesFilter._0
            }
          };
  }
  var e = partialDeliveriesFilter._0;
  return {
          TAG: "Error",
          _0: {
            path: ".partialDeliveriesFilter" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$35 = Spice.unitToJson;

var response_decode$35 = Spice.unitFromJson;

function config$35(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/partialDeliveriesFilter",
          requestOptions: {
            method: "PUT",
            json: body_encode$11(param[1])
          }
        };
}

var UpdatePartialDeliveriesFilter = {
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  response_encode: response_encode$35,
  response_decode: response_decode$35,
  config: config$35
};

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderIntegrationMaximumDelayInDays",
                    false,
                    Spice.intToJson(v.orderIntegrationMaximumDelayInDays)
                  ]]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIntegrationMaximumDelayInDays = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orderIntegrationMaximumDelayInDays"), null));
  if (orderIntegrationMaximumDelayInDays.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderIntegrationMaximumDelayInDays: orderIntegrationMaximumDelayInDays._0
            }
          };
  }
  var e = orderIntegrationMaximumDelayInDays._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIntegrationMaximumDelayInDays" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$36 = Spice.unitToJson;

var response_decode$36 = Spice.unitFromJson;

function config$36(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/orderIntegrationMaximumDelay",
          requestOptions: {
            method: "PUT",
            json: body_encode$12(param[1])
          }
        };
}

var UpdateOrderIntegrationMaximumDelay = {
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  response_encode: response_encode$36,
  response_decode: response_decode$36,
  config: config$36
};

function response_encode$37(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "receivedOrderEmail",
                    false,
                    Spice.boolToJson(v.receivedOrderEmail)
                  ],
                  [
                    "confirmedDeliveryEmail",
                    false,
                    Spice.boolToJson(v.confirmedDeliveryEmail)
                  ],
                  [
                    "referenceLevel",
                    false,
                    t_encode$6(v.referenceLevel)
                  ],
                  [
                    "displayName",
                    false,
                    Spice.stringToJson(v.displayName)
                  ]
                ]));
}

function response_decode$37(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedOrderEmail = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "receivedOrderEmail"), null));
  if (receivedOrderEmail.TAG === "Ok") {
    var confirmedDeliveryEmail = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "confirmedDeliveryEmail"), null));
    if (confirmedDeliveryEmail.TAG === "Ok") {
      var referenceLevel = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "referenceLevel"), null));
      if (referenceLevel.TAG === "Ok") {
        var displayName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "displayName"), null));
        if (displayName.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    receivedOrderEmail: receivedOrderEmail._0,
                    confirmedDeliveryEmail: confirmedDeliveryEmail._0,
                    referenceLevel: referenceLevel._0,
                    displayName: displayName._0
                  }
                };
        }
        var e = displayName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".displayName" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = referenceLevel._0;
      return {
              TAG: "Error",
              _0: {
                path: ".referenceLevel" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = confirmedDeliveryEmail._0;
    return {
            TAG: "Error",
            _0: {
              path: ".confirmedDeliveryEmail" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = receivedOrderEmail._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedOrderEmail" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$37(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/settings/notifications",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "ClientsSettingsNotifications",
            clientId
          ]
        };
}

var GetAll$2 = {
  response_encode: response_encode$37,
  response_decode: response_decode$37,
  config: config$37
};

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "receivedOrderEmail",
                    false,
                    Spice.boolToJson(v.receivedOrderEmail)
                  ],
                  [
                    "confirmedDeliveryEmail",
                    false,
                    Spice.boolToJson(v.confirmedDeliveryEmail)
                  ],
                  [
                    "referenceLevel",
                    false,
                    t_encode$6(v.referenceLevel)
                  ],
                  [
                    "displayName",
                    false,
                    Spice.stringToJson(v.displayName)
                  ]
                ]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedOrderEmail = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "receivedOrderEmail"), null));
  if (receivedOrderEmail.TAG === "Ok") {
    var confirmedDeliveryEmail = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "confirmedDeliveryEmail"), null));
    if (confirmedDeliveryEmail.TAG === "Ok") {
      var referenceLevel = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "referenceLevel"), null));
      if (referenceLevel.TAG === "Ok") {
        var displayName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "displayName"), null));
        if (displayName.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    receivedOrderEmail: receivedOrderEmail._0,
                    confirmedDeliveryEmail: confirmedDeliveryEmail._0,
                    referenceLevel: referenceLevel._0,
                    displayName: displayName._0
                  }
                };
        }
        var e = displayName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".displayName" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = referenceLevel._0;
      return {
              TAG: "Error",
              _0: {
                path: ".referenceLevel" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = confirmedDeliveryEmail._0;
    return {
            TAG: "Error",
            _0: {
              path: ".confirmedDeliveryEmail" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = receivedOrderEmail._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedOrderEmail" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$38 = Spice.unitToJson;

var response_decode$38 = Spice.unitFromJson;

function config$38(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/notifications",
          requestOptions: {
            method: "PUT",
            json: body_encode$13(param[1])
          }
        };
}

var Update$3 = {
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  response_encode: response_encode$38,
  response_decode: response_decode$38,
  config: config$38
};

var Notifications = {
  GetAll: GetAll$2,
  Update: Update$3
};

function body_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "allowOnlinePaymentDelivery",
                    false,
                    Spice.boolToJson(v.allowOnlinePaymentDelivery)
                  ]]));
}

function body_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allowOnlinePaymentDelivery = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowOnlinePaymentDelivery"), null));
  if (allowOnlinePaymentDelivery.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              allowOnlinePaymentDelivery: allowOnlinePaymentDelivery._0
            }
          };
  }
  var e = allowOnlinePaymentDelivery._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allowOnlinePaymentDelivery" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$39 = Spice.unitToJson;

var response_decode$39 = Spice.unitFromJson;

function config$39(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/allowOnlinePaymentDelivery",
          requestOptions: {
            method: "PUT",
            json: body_encode$14(param[1])
          }
        };
}

var AllowOnlinePaymentDeliveryForClients = {
  body_encode: body_encode$14,
  body_decode: body_decode$14,
  response_encode: response_encode$39,
  response_decode: response_decode$39,
  config: config$39
};

function body_encode$15(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "allowReintegration",
                    false,
                    Spice.boolToJson(v.allowReintegration)
                  ]]));
}

function body_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allowReintegration = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowReintegration"), null));
  if (allowReintegration.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              allowReintegration: allowReintegration._0
            }
          };
  }
  var e = allowReintegration._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allowReintegration" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$40 = Spice.unitToJson;

var response_decode$40 = Spice.unitFromJson;

function config$40(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/allowReintegration",
          requestOptions: {
            method: "PUT",
            json: body_encode$15(param[1])
          }
        };
}

var AllowReintegration = {
  body_encode: body_encode$15,
  body_decode: body_decode$15,
  response_encode: response_encode$40,
  response_decode: response_decode$40,
  config: config$40
};

function body_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "canAnswerSurvey",
                    false,
                    Spice.boolToJson(v.canAnswerSurvey)
                  ]]));
}

function body_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var canAnswerSurvey = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canAnswerSurvey"), null));
  if (canAnswerSurvey.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              canAnswerSurvey: canAnswerSurvey._0
            }
          };
  }
  var e = canAnswerSurvey._0;
  return {
          TAG: "Error",
          _0: {
            path: ".canAnswerSurvey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$41 = Spice.unitToJson;

var response_decode$41 = Spice.unitFromJson;

function config$41(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/survey",
          requestOptions: {
            method: "PUT",
            json: body_encode$16(param[1])
          }
        };
}

var UpdateSurvey = {
  body_encode: body_encode$16,
  body_decode: body_decode$16,
  response_encode: response_encode$41,
  response_decode: response_decode$41,
  config: config$41
};

function body_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "allowOrdersAccess",
                    false,
                    Spice.boolToJson(v.allowOrdersAccess)
                  ]]));
}

function body_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allowOrdersAccess = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowOrdersAccess"), null));
  if (allowOrdersAccess.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              allowOrdersAccess: allowOrdersAccess._0
            }
          };
  }
  var e = allowOrdersAccess._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allowOrdersAccess" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$42 = Spice.unitToJson;

var response_decode$42 = Spice.unitFromJson;

function config$42(param) {
  return {
          kyInstance: kyInstance$4,
          path: param[0] + "/settings/allowOrdersAccess",
          requestOptions: {
            method: "PUT",
            json: body_encode$17(param[1])
          }
        };
}

var AllowOrdersAccessForClients = {
  body_encode: body_encode$17,
  body_decode: body_decode$17,
  response_encode: response_encode$42,
  response_decode: response_decode$42,
  config: config$42
};

var Settings$1 = {
  ReferenceLevel: ReferenceLevel,
  UpdatePartialDeliveriesFilter: UpdatePartialDeliveriesFilter,
  UpdateOrderIntegrationMaximumDelay: UpdateOrderIntegrationMaximumDelay,
  Notifications: Notifications,
  AllowOnlinePaymentDeliveryForClients: AllowOnlinePaymentDeliveryForClients,
  AllowReintegration: AllowReintegration,
  UpdateSurvey: UpdateSurvey,
  AllowOrdersAccessForClients: AllowOrdersAccessForClients
};

function user_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "apiKey",
                    false,
                    Spice.stringToJson(v.apiKey)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ]
                ]));
}

function user_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var apiKey = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "apiKey"), null));
        if (apiKey.TAG === "Ok") {
          var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      uuid: uuid._0,
                      username: username._0,
                      email: email._0,
                      apiKey: apiKey._0,
                      role: role._0
                    }
                  };
          }
          var e = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = apiKey._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".apiKey" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$43(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$43(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$43(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/users/technical",
          requestOptions: {
            method: "GET"
          },
          key: ["ClientGetTechnicalUsers"]
        };
}

var GetAll$3 = {
  user_encode: user_encode,
  user_decode: user_decode,
  response_encode: response_encode$43,
  response_decode: response_decode$43,
  config: config$43
};

var Technical = {
  GetAll: GetAll$3
};

function user_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ]
                ]));
}

function user_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      uuid: uuid._0,
                      username: username._0,
                      email: email._0,
                      phoneNumber: phoneNumber._0,
                      role: role._0
                    }
                  };
          }
          var e = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$44(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode$1, v.users)
                  ]]));
}

function response_decode$44(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$44(clientId) {
  return {
          kyInstance: kyInstance$4,
          path: clientId + "/users/human",
          requestOptions: {
            method: "GET"
          },
          key: ["ClientGetHumanUsers"]
        };
}

var GetAll$4 = {
  user_encode: user_encode$1,
  user_decode: user_decode$1,
  response_encode: response_encode$44,
  response_decode: response_decode$44,
  config: config$44
};

var Human = {
  GetAll: GetAll$4
};

var Users = {
  Technical: Technical,
  Human: Human
};

var Clients = {
  kyInstance: kyInstance$4,
  PostPurchaseConfiguration: PostPurchaseConfiguration,
  Areas: Areas$1,
  Warehouses: Warehouses$1,
  GetByHubId: GetByHubId$1,
  GetAllNames: GetAllNames,
  GetAllPaginated: GetAllPaginated,
  GetDetail: GetDetail,
  GetStore: GetStore,
  GetLoadLimit: GetLoadLimit,
  PutBarcodePattern: PutBarcodePattern,
  Stores: Stores,
  Settings: Settings$1,
  Users: Users
};

var kyInstance$5 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/stores"
    });

function user_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ]
                ]));
}

function user_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      uuid: uuid._0,
                      username: username._0,
                      email: email._0,
                      phoneNumber: phoneNumber._0,
                      role: role._0
                    }
                  };
          }
          var e = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$45(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode$2, v.users)
                  ]]));
}

function response_decode$45(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$45(storeId) {
  return {
          kyInstance: kyInstance$5,
          path: storeId + "/users/human",
          requestOptions: {
            method: "GET"
          },
          key: ["storesGetHumanUsers"]
        };
}

var GetAll$5 = {
  user_encode: user_encode$2,
  user_decode: user_decode$2,
  response_encode: response_encode$45,
  response_decode: response_decode$45,
  config: config$45
};

var Human$1 = {
  GetAll: GetAll$5
};

var Users$1 = {
  Human: Human$1
};

function filters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.name)
                  ],
                  [
                    "clientId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function filters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var clientId = Toolkit__Decoders.$$Option.t_decode(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                clientId: clientId._0
              }
            };
    }
    var e = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function sort_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    ColiswebApi__Utils.SortOrder.t_encode(v.name)
                  ]]));
}

function sort_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = ColiswebApi__Utils.SortOrder.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$18(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$1(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$1(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$1(v.pagination)
                  ]
                ]));
}

function body_decode$18(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function queryParams_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ]]));
}

function queryParams_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              date: date._0
            }
          };
  }
  var e = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function activation_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function activation_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      var ruleType = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "ruleType"), t_decode$1), {
            TAG: "Ok",
            _0: "clientPricing"
          });
      if (ruleType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0,
                  ruleType: ruleType._0
                }
              };
      }
      var e = ruleType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function nbActiveStores_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "active",
                    false,
                    Spice.intToJson(v.active)
                  ],
                  [
                    "total",
                    false,
                    Spice.intToJson(v.total)
                  ]
                ]));
}

function nbActiveStores_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var active = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "active"), null));
  if (active.TAG === "Ok") {
    var total = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "total"), null));
    if (total.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                active: active._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: "Error",
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = active._0;
  return {
          TAG: "Error",
          _0: {
            path: ".active" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function store_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "clientName",
                    false,
                    Spice.stringToJson(v.clientName)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "storeName",
                    false,
                    Spice.stringToJson(v.storeName)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ],
                  [
                    "clientActivation",
                    false,
                    Spice.optionToJson(activation_encode$6, v.clientActivation)
                  ],
                  [
                    "storeActivation",
                    false,
                    Spice.optionToJson(activation_encode$6, v.storeActivation)
                  ]
                ]));
}

function store_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var clientName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientName"), null));
    if (clientName.TAG === "Ok") {
      var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
      if (storeId.TAG === "Ok") {
        var storeName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storeName"), null));
        if (storeName.TAG === "Ok") {
          var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
          if (updatedAt.TAG === "Ok") {
            var clientActivation = Spice.optionFromJson(activation_decode$6, Belt_Option.getWithDefault(Js_dict.get(v, "clientActivation"), null));
            if (clientActivation.TAG === "Ok") {
              var storeActivation = Spice.optionFromJson(activation_decode$6, Belt_Option.getWithDefault(Js_dict.get(v, "storeActivation"), null));
              if (storeActivation.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          clientId: clientId._0,
                          clientName: clientName._0,
                          storeId: storeId._0,
                          storeName: storeName._0,
                          updatedAt: updatedAt._0,
                          clientActivation: clientActivation._0,
                          storeActivation: storeActivation._0
                        }
                      };
              }
              var e = storeActivation._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".storeActivation" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = clientActivation._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".clientActivation" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = updatedAt._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".updatedAt" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = storeName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeName" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = storeId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeId" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = clientName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientName" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$46(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode, v.stores)
                  ]
                ]));
}

function response_decode$46(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var stores = Spice.arrayFromJson(store_decode, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
    if (stores.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                stores: stores._0
              }
            };
    }
    var e = stores._0;
    return {
            TAG: "Error",
            _0: {
              path: ".stores" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$46(param) {
  var params = param[1];
  var body = param[0];
  return {
          kyInstance: kyInstance$5,
          path: "all",
          requestOptions: {
            method: "POST",
            json: body_encode$18(body),
            searchParams: Belt_Option.map(params, (function (params) {
                    return Qs.stringify(queryParams_encode$1(params));
                  }))
          },
          key: [
            "ColiswebApi__V6_Client",
            "StoresGetAllPaginated",
            JSON.stringify(body_encode$18(body)),
            Belt_Option.mapWithDefault(params, "", (function (p) {
                    return JSON.stringify(queryParams_encode$1(p));
                  }))
          ]
        };
}

var GetAllPaginated$1 = {
  filters_encode: filters_encode$1,
  filters_decode: filters_decode$1,
  sort_encode: sort_encode$1,
  sort_decode: sort_decode$1,
  pagination_encode: pagination_encode$1,
  pagination_decode: pagination_decode$1,
  body_encode: body_encode$18,
  body_decode: body_decode$18,
  queryParams_encode: queryParams_encode$1,
  queryParams_decode: queryParams_decode$1,
  activation_encode: activation_encode$6,
  activation_decode: activation_decode$6,
  nbActiveStores_encode: nbActiveStores_encode$1,
  nbActiveStores_decode: nbActiveStores_decode$1,
  store_encode: store_encode,
  store_decode: store_decode,
  response_encode: response_encode$46,
  response_decode: response_decode$46,
  config: config$46
};

function activation_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "ruleType",
                    false,
                    t_encode$1(v.ruleType)
                  ]
                ]));
}

function activation_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      var ruleType = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "ruleType"), t_decode$1), {
            TAG: "Ok",
            _0: "clientPricing"
          });
      if (ruleType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0,
                  ruleType: ruleType._0
                }
              };
      }
      var e = ruleType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function nbActiveStores_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "active",
                    false,
                    Spice.intToJson(v.active)
                  ],
                  [
                    "total",
                    false,
                    Spice.intToJson(v.total)
                  ]
                ]));
}

function nbActiveStores_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var active = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "active"), null));
  if (active.TAG === "Ok") {
    var total = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "total"), null));
    if (total.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                active: active._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: "Error",
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = active._0;
  return {
          TAG: "Error",
          _0: {
            path: ".active" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function store_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "code",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.code)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function store_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var code = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
      if (code.TAG === "Ok") {
        var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
        if (name.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    clientId: clientId._0,
                    code: code._0,
                    name: name._0
                  }
                };
        }
        var e = name._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".name" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = code._0;
      return {
              TAG: "Error",
              _0: {
                path: ".code" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$47(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode$1, v.stores)
                  ]]));
}

function response_decode$47(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              stores: stores._0
            }
          };
  }
  var e = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$47(param) {
  return {
          kyInstance: kyInstance$5,
          path: "names",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "StoresGetAllNames"
          ]
        };
}

var GetAllNames$1 = {
  activation_encode: activation_encode$7,
  activation_decode: activation_decode$7,
  nbActiveStores_encode: nbActiveStores_encode$2,
  nbActiveStores_decode: nbActiveStores_decode$2,
  store_encode: store_encode$1,
  store_decode: store_decode$1,
  response_encode: response_encode$47,
  response_decode: response_decode$47,
  config: config$47
};

function body_encode$19(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "allowOnlinePaymentDelivery",
                    false,
                    Spice.boolToJson(v.allowOnlinePaymentDelivery)
                  ]]));
}

function body_decode$19(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allowOnlinePaymentDelivery = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowOnlinePaymentDelivery"), null));
  if (allowOnlinePaymentDelivery.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              allowOnlinePaymentDelivery: allowOnlinePaymentDelivery._0
            }
          };
  }
  var e = allowOnlinePaymentDelivery._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allowOnlinePaymentDelivery" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$48 = Spice.unitToJson;

var response_decode$48 = Spice.unitFromJson;

function config$48(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/settings/allowOnlinePaymentDelivery",
          requestOptions: {
            method: "PUT",
            json: body_encode$19(param[1])
          }
        };
}

var AllowOnlinePaymentDelivery = {
  body_encode: body_encode$19,
  body_decode: body_decode$19,
  response_encode: response_encode$48,
  response_decode: response_decode$48,
  config: config$48
};

function body_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "canAnswerSurvey",
                    false,
                    Spice.optionToJson(Spice.boolToJson, v.canAnswerSurvey)
                  ]]));
}

function body_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var canAnswerSurvey = Spice.optionFromJson(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "canAnswerSurvey"), null));
  if (canAnswerSurvey.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              canAnswerSurvey: canAnswerSurvey._0
            }
          };
  }
  var e = canAnswerSurvey._0;
  return {
          TAG: "Error",
          _0: {
            path: ".canAnswerSurvey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$49 = Spice.unitToJson;

var response_decode$49 = Spice.unitFromJson;

function config$49(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/settings/survey",
          requestOptions: {
            method: "PUT",
            json: body_encode$20(param[1])
          }
        };
}

var Update$4 = {
  body_encode: body_encode$20,
  body_decode: body_decode$20,
  response_encode: response_encode$49,
  response_decode: response_decode$49,
  config: config$49
};

var Survey = {
  Update: Update$4
};

var Settings$2 = {
  Survey: Survey
};

var Stores$1 = {
  kyInstance: kyInstance$5,
  Users: Users$1,
  GetAllPaginated: GetAllPaginated$1,
  GetAllNames: GetAllNames$1,
  AllowOnlinePaymentDelivery: AllowOnlinePaymentDelivery,
  Settings: Settings$2
};

var prefixBaseUrl$1 = ColiswebApi__Env.v6ApiUrl + "/client/public/clients";

var kyInstance$6 = Ky.default.create({
      prefixUrl: prefixBaseUrl$1,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "coordinates",
                    false,
                    Toolkit__Decoders.Coordinates.t_encode(v.coordinates)
                  ]
                ]));
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var coordinates = Toolkit__Decoders.Coordinates.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "coordinates"), null));
          if (coordinates.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      line1: line1._0,
                      city: city._0,
                      postalCode: postalCode._0,
                      country: country._0,
                      coordinates: coordinates._0
                    }
                  };
          }
          var e = coordinates._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".coordinates" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function store_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.storeCode)
                  ]
                ]));
}

function store_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
  if (storeId.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
      if (address.TAG === "Ok") {
        var storeCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
        if (storeCode.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    storeId: storeId._0,
                    name: name._0,
                    address: address._0,
                    storeCode: storeCode._0
                  }
                };
        }
        var e = storeCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeCode" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = address._0;
      return {
              TAG: "Error",
              _0: {
                path: ".address" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = storeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$50(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode$2, v.stores)
                  ]]));
}

function response_decode$50(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              stores: stores._0
            }
          };
  }
  var e = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$50(clientId) {
  return {
          kyInstance: kyInstance$6,
          path: clientId + "/stores/withOnlinePayment",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Client",
            "GetStoresByOnlinePayment",
            clientId
          ],
          mapCustomErrors: (function (error) {
              return Belt_Option.mapWithDefault(error.response, $$Promise.resolved({
                              TAG: "Unknown",
                              _0: error
                            }), (function (response) {
                            return $$Promise.resolved(response.status !== 404 ? ({
                                            TAG: "Unknown",
                                            _0: error
                                          }) : ({
                                            TAG: "Custom",
                                            _0: "ONLINE_PAYMENT_NOT_AVAILABLE"
                                          }));
                          }));
            })
        };
}

var GetStoresByOnlinePayment = {
  address_encode: address_encode,
  address_decode: address_decode,
  store_encode: store_encode$2,
  store_decode: store_decode$2,
  response_encode: response_encode$50,
  response_decode: response_decode$50,
  config: config$50
};

var Clients$1 = {
  GetStoresByOnlinePayment: GetStoresByOnlinePayment
};

var Public = {
  Clients: Clients$1
};

var keyBase = "V6_Client";

export {
  keyBase ,
  Shared ,
  Rules ,
  Activations ,
  TestPriceDefinition ,
  Hubs ,
  Warehouses ,
  Areas ,
  Clients ,
  Stores$1 as Stores,
  Public ,
}
/* kyInstance Not a pure module */
